import React, { Component } from 'react';
import Helmet from 'react-helmet';
import {
  CircularProgress,
  FormControl,
  Input,
  TextField,
  FormHelperText,
  Button,
  InputLabel
} from "@material-ui/core";
import Navbar from "../Navbar/Navbar";
import MapListRow from '../Listing/MapListRow';
import MappedCard from '../Listing/MappedCard';
import Footer from "../Footer/Footer";
import main from "../assets/primal/preorder/bethesda/confirmation.png";
import discord from "../assets/primal/branding/DISCORD.png";

export default class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      name: "",
      email: "",
      username: "",
      location: ""
    }
  }
  componentDidMount() {
    const urlSplit = this.props.match.url.split("/");
    // let lastChar = urlSplit[urlSplit.length - 1];
    this.fetchArtists();
  }
  async fetchArtists() {
    try {
      let result = await fetch('https://api.primaltcg.com/api/v1/guest/artists', {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });  
      result = await result.json();
      if (result && result.length > 0) {
        this.setState({ 
          artists: result,
          loading: false
        });
      } else {
        alert('There was a problem getting Primal TCG artists');
        this.props.history.push("/");
      }
    } catch (error) {
      alert('There was a problem getting Primal TCG artists');
      this.props.history.push("/");
    }
  }
  renderTextInput(
    id: string,
    label: any,
    value: string,
    type: string,
    width: any,
    inputProps: Object,
    disabled: boolean = false,
    error: boolean = false
  ): React$Element<FormControl> {
    return (
      <FormControl style={{ width: "50%" }}>
        <TextField
          error={error}
          variant="outlined"
          id={id}
          type={type}
          label={label}
          value={value || ""}
          disabled={disabled}
          InputProps={inputProps}
          autoComplete="nope"
          onChange={(inputValue: Object) => {
            let value = inputValue.target.value;
            if (type === "number") {
              value = Number(value);
            }
            this.updateState(id, value);
          }}
        />
        {id === "location" ? (<FormHelperText id="location-helper-text">Location is used to let other players in your proximity know where you are to know if people around you are playing Primal TCG. It is recommended to place your city.</FormHelperText>): <span />}
      </FormControl>
    );
  }
  updateState(id, value) {
    let email = "";
    if (id === "email") {
      email = String(value).toLowerCase();
      email = email.trim();
      value = email;
    }
    this.setState({ [id]: value });
  }
  async createAccount() {
    const { name, username, email, location } = this.state;
    if (name && username && email && location) {
      try {
        let details = {
          "name": name,
          "location": location,
          "userName": username,
          "email": String(email).toLowerCase(),
          "password": "Primal123"
        };
        let formBody = [];
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        console.log("FORM BODY", {form: formBody, details: details})
        let result = await fetch('https://api.primaltcg.com/api/v1/users/signup', {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Accept':       'application/json'
          },
          body: formBody,
        });
        result = await result.json();
        if (result) {
          this.setState({
            name: "",
            username: "",
            location: "",
            email: ""
          }, () => {
            alert(`Account Created! Login on the app with your email, ${email}`);
          });
        }
      } catch (error) {
        alert("There was an error creating your account. Make sure your email is correct.");
      }
    } else {
      alert("Complete the form.");
    }
  }
  render() {
    const { artists, loading} = this.state;
    /**
     * Name
     * email 
     * username
     * location
     */
    return (
      <div className="Artist">
        <Helmet>
          <title>Primal TCG Order Confirmation</title>
          <meta name="description" content="Primal TCG Battlers of Bethesda Pre-order confirmation. We have registered your pre-order! We will be sending you an email by the end of the day where you could pay for your pre-order. You will be receiving a pre-order exclusive promo for every booster box you ordered with your order! If you haven't already joined the Primal Discord server go ahead and join below." />
          <meta property="og:title" content="Primal TCG Order Confirmation" />
          <meta property="og:description" content="Primal TCG Battlers of Bethesda Pre-order confirmation. We have registered your pre-order! We will be sending you an email by the end of the day where you could pay for your pre-order. You will be receiving a pre-order exclusive promo for every booster box you ordered with your order! If you haven't already joined the Primal Discord server go ahead and join below." />
          <meta property="og:image" content="https://pbs.twimg.com/media/GTqkZEHWYAEgIR0?format=jpg&name=large" />
        </Helmet>
        <Navbar />
        <div className={loading ? "row bkg-red full-h" : "row bkg-red"}>
        <div style={{ padding: "10px"}} className="col-xs-12 col-sm-8 col-md-8 col-xl-8 offset-sm-2 offset-md-2 offset-xl-2 floating-section">
          <h1>Order Confirmation</h1>
          <div className="row">
          <div style={{ textAlign: "center"}} className='col-md-12'>
            <img src={main} className="mainImage" /> 
            <h1>Primal TCG Battlers of Bethesda Pre-Orders</h1>
          </div>
          </div>
          <h5><strong>We have registered your pre-order! We will be sending you an email right away where you could pay for your pre-order. <br /><br />You will be receiving a pre-order exclusive promo for every box purchased with your order! <br />If you haven't already joined the Primal Discord server go ahead and join below.</strong></h5>
          <br /> <h5><strong>Pre-Orders close August 25, 2024.</strong></h5>
          <br /><h5><strong>You will receive a payment request via email on by the end of the day.</strong></h5>
          <br /> <h5><strong>Your payment must be completed by August 31, 2024 if not your pre-order will be cancelled.</strong></h5>
        <div className='row'>
        <div style={{ textAlign: "center"}} className='col-md-12'>
        <img onClick={() => {
          window.open("https://discord.gg/pxTRcC32nZ");
        }} style={{cursor: "pointer"}} src={discord} className="mainImage" />
        </div>
        </div>
        </div>
      </div>
      <Footer />
      </div>
    );
  }
}
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import {
  Link,
  withRouter,
} from 'react-router-dom';
import './Android.css';
import apk from '../assets/primal/apk/PrimalTCG1.0.1.apk';
import install1 from '../assets/naruto/android/install1.png';
import install2 from '../assets/naruto/android/install2.png';
import bannerDesk1 from '../assets/primal/front/banners/bannerDesk1.png';
import bannerDesk2 from '../assets/primal/front/banners/bannerDesk2.png';
import bannerDesk3 from '../assets/primal/front/banners/bannerDesk3.png';
import bannerDesk4 from '../assets/primal/front/banners/bannerDesk4.png';
import bannerDesk5 from '../assets/primal/front/banners/bannerDesk5.png';
import bannerMobile1 from '../assets/primal/front/banners/bannerMobile1.png';
import bannerMobile2 from '../assets/primal/front/banners/bannerMobile2.png';
import bannerMobile3 from '../assets/primal/front/banners/bannerMobile3.png';
import primalLogo from '../assets/PrimalIcon.png';
import install3 from '../assets/naruto/android/install3.png';
import install4 from '../assets/naruto/android/install4.png';
import install5 from '../assets/naruto/android/install5.png';
import install6 from '../assets/naruto/android/install6.png';
import narutoLogo from '../assets/narutoLogo.png';

import contactbkg from '../assets/naruto/front/contactbkg.jpg';
// import withAuth from './components/WithAuth';

// mixpanel.init('6355b9138ef99a42321be6306756111e');
class Android extends Component {
  constructor() {
    super();
    this.state = {
      posts: [],
      result: null,
      query: '',
      showItems: 30,
      columnStyle: 'col-md-6',
      isAuth: false,
      cardanimate: 'anicards hide',
      uri: apk,
      firstName: '',
      lastName: '',
      email: '',
      message: '',
    };
  }

  componentWillMount() {

  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        cardanimate: 'anicards',
      });
    }, 500);
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod|macintosh/.test(userAgent);
    };
    if (isIos()) {
      this.setState({
        uri: 'https://apps.apple.com/us/app/card-collector-naruto-edition/id1460633152',
      });
    }
  }

  sendMessage() {
    const re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
    const mssgArr = this.state.message.split(' ');
    const verifiedEmail = re.test(this.state.email);
    if (
      this.state.message !== '' &&
      mssgArr.length > 2 &&
      this.state.firstName !== '' &&
      verifiedEmail
    ) {
      const details = {
        message: this.state.message,
        name: `${this.state.firstName} ${this.state.lastName}`,
        fromEmail: this.state.email,
      };
      let formBody = [];
      for (const property in details) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(details[property]);
        formBody.push(`${encodedKey}=${encodedValue}`);
      }
      formBody = formBody.join('&');
      fetch('https://api.narutoccg.com/api/v1.1/market/sendmail', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formBody,
      }).then((response) => response.json())
        .then((responseData) => {
          this.setState({
            message: '',
            email: '',
            firstName: '',
            lastName: '',
          });
          alert('We will email you back promptly');
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      alert('Please fill out the fields with appropriate values');
    }
  }
  render() {
    let desktopBanners = [bannerDesk1, bannerDesk2, bannerDesk3, bannerDesk4, bannerDesk5];
    let mobileBanners = [bannerMobile1, bannerMobile2, bannerMobile3];
    let banner = desktopBanners[Math.floor(Math.random() * desktopBanners.length)];
    let mobileBanner = mobileBanners[Math.floor(Math.random() * mobileBanners.length)];
    return (
      <div className="Android">
         <Helmet>
          <title>Primal TCG | Awakened Primordials</title>
          <meta name="description" content="Primal the Trading Card Game is a fantasy based game with new game mechanics compared to current trading card games. Kickstarter Release on April 2023. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:title" content="Primal TCG | Awakened Primordials" />
          <meta property="og:description" content="Primal the Trading Card Game is a fantasy based game with new game mechanics compared to current trading card games. Kickstarter Release on April 2023. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:image" content="https://pbs.twimg.com/media/GJ38D7mWwAAMXsa?format=jpg&name=medium" />
        </Helmet>
        <div className="row">
          <div className="col-md-12">
            <nav className="navbar navbar-expand-md bg-dark">
              {/* <div className="container"> */}
                <a className="navbar-brand" href="/"><img style={{width: '75px', borderRadius: '100%'}} src={primalLogo} /></a>
                <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbar-menu" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                  <span className="navbar-toggler-icon"></span>
                  <span className="navbar-toggler-icon"></span>
                </button>
                
              {/* </div> */}
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
          <div className="page-header" style={{ backgroundImage: `url(${window.innerWidth > 867 ? banner : mobileBanner })`, height: "100ch"}}>
              <div className="filter" />
              <div className="content-center">
                <div className="container">
                  <div className="motto">
                    <h1 className="title">Android Installation</h1>
                    <h2 className="subtitle">Follow these steps</h2>
                    <h3 className="description">Install the Android APK</h3>
                    <br />
                    <a href={apk} className="dlbtn btn btn-success btn-round"><i className="fa fa-android" aria-hidden="true" />Download Android APK</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 content-android">
            <div className="row">
              <img className="col-md-4 col-12" id="androidImg" src={install1} />
              <img className="col-md-4 col-12" id="androidImg" src={install2} />
              <img className="col-md-4 col-12" id="androidImg" src={install3} />
              <img className="col-md-4 col-12" id="androidImg" src={install4} />
              <img className="col-md-4 col-12" id="androidImg" src={install5} />
              <img className="col-md-4 col-12" id="androidImg" src={install6} />
            </div>
          </div>
        </div>
             </div>
    );
  }
}

export default Android;

import decode from 'jwt-decode';

export default class AuthService {
  // Init
  constructor(domain) {
    this.domain = domain || 'http://localhost:3000'; // API Server
    this.devFetch = this.devFetch.bind(this); // React binding
    this.prodFetch = this.prodFetch.bind(this); // React binding
    this.login = this.login.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }
  signup(name, userName, email, password, country, phone) {
    const data = new URLSearchParams();
    data.append('name', name);
    data.append('userName', userName);
    data.append('email', email);
    data.append('password', password);
    data.append('country', country);
    data.append('phone', phone);

    return this.fetch(`${this.domain}/api/v1/users/signup`, {
      mode: 'cors',
      method: 'POST',
      body: data,
    }).then(response => {
      this.setToken(response.token);
      return Promise.resolve(response);
    });
  }

  login(email, password) {
    // Get a token from api server using the fetch api
    const data = new URLSearchParams();
    data.append('email', email);
    data.append('password', password);
    return this.fetch(`${this.domain}/api/v1/users/login`, {
      mode: 'cors',
      method: 'POST',
      body: data,
    }).then(response => {
      this.setToken(response.token); // set token in localstorage
      return Promise.resolve(response);
    });
  }

  post(title, text, images, price, category, shippingCost) {
    const data = new FormData();
    data.append('title', title);
    data.append('text', text);
    for (let i = 0; i < images.length; i++) {
      data.append('images', images[i], images[i].name);
    }
    data.append('price', price);
    data.append('category', category);
    data.append('shippingCost', shippingCost);

    return this.fetch(`${this.domain}/api/v1/posts`, {
      mode: 'cors',
      method: 'POST',
      body: data,
    }).then(response => {
      return Promise.resolve(response);
    });
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken(); // GEtting token from localstorage
    return token; // handwaiving here
  }

  setToken(idToken) {
    // Saves user token to localStorage
    localStorage.setItem('id_token', idToken);
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem('id_token');
  }

  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('id_token');
  }

  getProfile() {
    // Using jwt-decode npm package to decode the token
    return decode(this.getToken());
  }
  devFetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Authorization': "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTljN2I2MDdjNWZhZjM4MWRkN2RiNTIiLCJ1c2VybmFtZSI6ImNsYXVkaW8iLCJwcm9maWxlUGljIjoidXBsb2Fkcy9JMDAwNC5wbmciLCJlbWFpbCI6ImNsYXVkaW9AcHJpbWFsdGNnLmNvbSIsImlhdCI6MTcwNDc1NDEyNn0.LzWltvqkvQIXj0T2ywnzgFrr9gbny_D62Z_PW5vSnEs"
    };

    // Setting Authorization header
    // Authorization: JWT xxxxxxx.xxxxxxxx.xxxxxx
    // if (this.loggedIn()) {
    //   headers['Authorization'] = this.getToken();
    // }

    return fetch(url, {
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then(response => response.json());
  }
  prodFetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmIwYTMyZmIwMzNkMzU4NjkwNGVhZTIiLCJ1c2VybmFtZSI6ImNsYXVkaW8iLCJwcm9maWxlUGljIjoidXBsb2Fkcy9JMDAwNC5wbmciLCJlbWFpbCI6ImNsYXVkaW9AcHJpbWFsdGNnLmNvbSIsImlhdCI6MTY4MDY1ODMwNn0.uAL0fgFMvC2q_VgKPSyQwYgCwUm3rcKXa65PBogSODA'
    };

    // Setting Authorization header
    // Authorization: JWT xxxxxxx.xxxxxxxx.xxxxxx
    // if (this.loggedIn()) {
    //   headers['Authorization'] = this.getToken();
    // }

    return fetch(url, {
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then(response => response.json());
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
      return response;
    }
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import {
  CircularProgress
} from "@material-ui/core";
import Navbar from "../Navbar/Navbar";
import MapListRow from '../Listing/MapListRow';
import Footer from "../Footer/Footer";


export default class Artists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      loading: true,
      artist: null
    }
  }
  componentDidMount() {
    const urlSplit = this.props.match.url.split("/");
    let lastChar = urlSplit[urlSplit.length - 1];
    this.fetchArtist(lastChar);
  }
  async fetchArtist(identifier) {
    try {
      let result = await fetch(`https://api.primaltcg.com/api/v1/guest/artist/${identifier}`, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });  
      result = await result.json();
      if (result && result._id) {
        let cards = this.get2dArray(result.illustrations);
        this.setState({ 
          artist: result,
          cards: cards,
          totalCards: result.illustrations.length,
          loading: false
        });
      } else {
        alert(`There was a problem searching for artist ${identifier}`);
        this.props.history.push("/");
      }
    } catch (error) {
      alert(`There was a problem searching for artist ${identifier}`);
      this.props.history.push("/");
    }
  }
  get2dArray(arr) {
    let collection = [];
    let temp = [];
    let collectionEnd = arr.length;
    let remainder = collectionEnd % 3;
    arr.map(card => {
      temp.push(card);
      if (temp.length === 3) {
        collection.push(temp);
        temp = [];
      }
    });
    if (remainder === 2) {
      collection.push([arr[collectionEnd - 2], arr[collectionEnd - 1]]);
    }
    if (remainder === 1) {
      collection.push([arr[collectionEnd - 1]]);
    }
    return collection;
  }

  render() {
    const { cards, artist, loading} = this.state;
    return (
      <div className="Artist">
        <Helmet>
          <title>Primal Trading Card Game Artist</title>
          <meta name="description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:title" content="Primal Trading Card Game (TCG) | Awakened Primordials 1st Ed Release April 2024" />
          <meta property="og:description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:image" content="https://pbs.twimg.com/media/GJ38D7mWwAAMXsa?format=jpg&name=medium" />
        </Helmet>
        <Navbar />
        <div className={loading || cards.length === 0 ? "row bkg-red full-h" : "row bkg-red"}>
        <div className="col-xs-12 col-sm-8 col-md-8 col-xl-8 offset-sm-2 offset-md-2 offset-xl-2 floating-section">
          {artist ? <h1>Illustration Artist: {artist.name}</h1> : <div />}
          {artist ? <h3>Portfolio/Contact: <a href={artist.link}>{artist.link}</a></h3> : <div />}
          {loading ?  <CircularProgress /> : <div />}
         
          {cards.length === 0 && loading === false ? (<h2 style={{ color: "#9D0208" }}>No Cards Match That Search. Try Again</h2>) : (<div />)}
          {cards.length > 0 ? (<h2 className="rarityTitle">Cards Illustrated ({this.state.totalCards})</h2>) : <div />}
          {cards.length > 0 ? cards.map((cardSet, index) => (
            <MapListRow
              key={index + "card"}
              cardRow={cardSet}
              history={this.props.history}
            />
            )) : <div />}
        </div>
      </div>
      <Footer />
      </div>
    );
  }
}
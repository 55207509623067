import React, { Component } from 'react';
import Helmet from 'react-helmet';

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEvent: null,

    } 
  }
  
  render() { 
    return (
      <div>
                <Helmet>
          <title>Primal TCG Play Network</title>
          <meta name="description" content="Unlock the ultimate Primal TCG experience. Track your rankings, discover nearby events, and connect with an ever-growing community of passionate players, all in one place." />
          <meta property="og:title" content="Join the Primal Play Network - Your Gateway to Primal TCG Dominance!" />
          <meta property="og:description" content="Unlock the ultimate Primal TCG experience. Track your rankings, discover nearby events, and connect with an ever-growing community of passionate players, all in one place." />
          <meta property="og:image" content="https://pbs.twimg.com/media/FxkDQAsWYAImo4-?format=jpg&name=4096x4096" />
        </Helmet>
        <iframe style={{
        width: "100vw",
        height: "100vh",
        boxSizing: "content-box",
        border: "0px"
      }} src="https://play.carde.io/primal"></iframe>
      </div>
    );
  }
}
 
export default Events;
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import {
  Link,
  withRouter,
} from 'react-router-dom';
import Compressor from 'compressorjs';
import AuthService from './AuthService';
// import hiw_listIt from './assets/hiw_listIt.png';
// import hiw_shareIt from './assets/hiw_shareIt.png';
// import hiw_earnCash from './assets/hiw_earnCash.png';
import naruto from './assets/naruto/PR06.png';
import fire from './assets/primal/symbol/FireIcon.png';
import necroSymbol from './assets/primal/symbol/NecroIcon.png';
import water from './assets/primal/symbol/SeaIcon.png';
import plasma from './assets/primal/symbol/PlasmaIcon.png';
import terra from './assets/primal/symbol/TerraIcon.png';
import air from './assets/primal/symbol/AirIcon.png';
import strategy from './assets/primal/symbol/StrategyIcon.png';
import field from './assets/primal/symbol/FieldIcon.png';
// import tool from './assets/naruto/type/toolType.png';
import ability from './assets/primal/symbol/AbilityIcon.png';
import character from './assets/primal/symbol/CharacterIcon.png';
import one from './assets/naruto/entrance/one.png';
import two from './assets/naruto/entrance/two.png';
import three from './assets/naruto/entrance/three.png';
import four from './assets/naruto/entrance/four.png';
import five from './assets/naruto/entrance/five.png';
import six from './assets/naruto/entrance/six.png';
import seven from './assets/naruto/entrance/seven.png';
import eight from './assets/naruto/entrance/eight.png';
import nine from './assets/naruto/entrance/nine.png';
import zero from './assets/naruto/entrance/zero.png';
import colorless from './assets/naruto/symbol/colorless.png';
import {
  FormControl,
  FormControlLabel,
  TextField,
  CircularProgress,
  Checkbox,
  Button
} from "@material-ui/core";
import MapListRow from './Listing/MapListRow';

const Auth = new AuthService();

class UpdateNinjaForm extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      searchName: "",
      searchCardEff: "",
      searchChar: "",
      searchedCards: [],
      cards: [],
      selectedCard: null,
      image: naruto,
      symbol: [],
      fireStyle: 0.3,
      waterStyle: 0.3,
      terraStyle: 0.3,
      airStyle: 0.3,
      plasmaStyle: 0.3,
      necroStyle: 0.3,
      cardType: 'Character',
      characterStyle: 1,
      strategyStyle: 0.3,
      abilityStyle: 0.3,
      fieldStyle: 0.3,
      toolStyle: 0.3,
      entranceCost: '',
      zeroStyle: 0.3,
      oneStyle: 0.3,
      twoStyle: 0.3,
      threeStyle: 0.3,
      fourStyle: 0.3,
      fiveStyle: 0.3,
      sixStyle: 0.3,
      sevenStyle: 0.3,
      eightStyle: 0.3,
      nineStyle: 0.3,
      rarity: '',
      price: '',
      shippingCost: '',
      loadingIcon: 'none',
      productPost: '2px solid black',
      socialPost: '',
      product: true,
      social: false,
      abilityType:'',
      env: 'Live'
    };
    this.imagePreview = this.imagePreview.bind(this);
    this.setSymbol = this.setSymbol.bind(this);
    this.setType = this.setType.bind(this);
    this.setEntrance = this.setEntrance.bind(this);
    this.handleRarity = this.handleRarity.bind(this);
    this.handleEnv = this.handleEnv.bind(this);
    this.handleAbility = this.handleAbility.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetState = this.resetState.bind(this);
    this.selectCardToUpdate = this.selectCardToUpdate.bind(this);
  }
  resetState() {
    const button = document.getElementById('submitPost');
    if (button) {
      button.setAttribute('style', 'display: block;');
    }

    this.setState({
      token: null,
      searchName: "",
      searchCardEff: "",
      searchChar: "",
      searchedCards: [],
      cards: [],
      selectedCard: null,
      image: naruto,
      symbol: [],
      fireStyle: 0.3,
      waterStyle: 0.3,
      terraStyle: 0.3,
      airStyle: 0.3,
      plasmaStyle: 0.3,
      necroStyle: 0.3,
      cardType: 'Character',
      characterStyle: 1,
      strategyStyle: 0.3,
      abilityStyle: 0.3,
      fieldStyle: 0.3,
      toolStyle: 0.3,
      entranceCost: '',
      zeroStyle: 0.3,
      oneStyle: 0.3,
      twoStyle: 0.3,
      threeStyle: 0.3,
      fourStyle: 0.3,
      fiveStyle: 0.3,
      sixStyle: 0.3,
      sevenStyle: 0.3,
      eightStyle: 0.3,
      nineStyle: 0.3,
      rarity: '',
      price: '',
      shippingCost: '',
      loadingIcon: 'none',
      productPost: '2px solid black',
      socialPost: '',
      product: true,
      social: false,
      abilityType:'',
      env: 'Live'
    });
  }
  submitSearch() {
    let name = this.state.searchName;
    name = name.trim();
    // let combatAttr = this.state.combatQuery;
    // let setList = this.state.cardSetQuery;
    let cardChar = this.state.searchChar; 
    cardChar = cardChar.trim();
    let printedEffect = this.state.searchCardEff;
    printedEffect = printedEffect.trim();
    // let endpoint = 'https://api.narutoccg.com/api/v1/posts?'
    let endpoint = `${this.state.env === "Dev" ? "https://dev.primaltcg.com/" : "https://api.primaltcg.com/"}api/v1/posts/v2/search?`
    if (name !== 'Search by Card Name (Micromon Krakaan)' || '') {
      // not just filters but actually searched a card
      endpoint += `search=${name}&`
    }  
    if (cardChar !== 'Search by Characteristics (Transformation Female)' || ''){
      endpoint += `characteristic=${cardChar}&`
    }
    if (printedEffect !== 'Search by Card Effect' || '') {
      endpoint += `cardEffect=${printedEffect}&`;
    }
    if (this.state.env === "Dev") {
      Auth.devFetch(endpoint, {
        mode: 'cors',
        method: 'GET'
      }).then(async res => {
        let posts = res.posts // array
        console.log("CARDS SEARCHED", posts)
        this.setState({
          searchedCards: posts
        }, () => {
          this.formatSearchResults(this.state.searchedCards)
        });
      })
        .catch(err => {
          console.log(err);
        })
    } else {
      Auth.prodFetch(endpoint, {
        mode: 'cors',
        method: 'GET'
      }).then(async res => {
        let posts = res.posts // array
        console.log("CARDS SEARCHED", posts)
        this.setState({
          searchedCards: posts
        }, () => {
          this.formatSearchResults(this.state.searchedCards)
        });
      })
        .catch(err => {
          console.log(err);
        })
    }
    
  }
  imagePreview(event) {
    const image = event.target.files[0];
    // console.log(image);
    const self = this;
    // eslint-disable-next-line no-new
    new Compressor(image, {
      quality: 0.6,
      success(result) {
      console.log(result);
        if (result instanceof File) {
          self.setState({ IMAGE: result }, () => {
            console.log(self.state.IMAGE);
            const reader = new FileReader();
            reader.onload = (e) => {
              self.setState({
                image: e.target.result,
              });
            };
            reader.readAsDataURL(self.state.IMAGE);
          });
        } else { // blob life
          const resultFile = new File([result], result.name, { type: result.type, lastModified: Date.now() });
          self.setState({ IMAGE: resultFile }, () => {
            console.log(self.state.IMAGE);
            const reader = new FileReader();
            reader.onload = (e) => {
              self.setState({
                image: e.target.result,
              });
            };
            reader.readAsDataURL(self.state.IMAGE);
          });
        }
      },
    });
  }
  setSymbol(e) {
    // console.log(e.target.id); gets the target id
    const symbol = e.target.id;
    const current = this.state.symbol;
    if (symbol === 'fireSymbol') {
      if (current.includes('Fire')) {
        // make opacity less than 1 because it isn't a choice
        const index = current.indexOf('Fire');
        if (index > -1) {
          current.splice(index, 1);
        }
        this.setState({ fireStyle: 0.3, symbol: current });
      } else {
        current.push('Fire');
        this.setState({ fireStyle: 1, symbol: current });
      }
    }
    if (symbol === 'waterSymbol') {
      if (current.includes('Water')) {
        // make opacity less than 1 because it isn't a choice
        const index = current.indexOf('Water');
        if (index > -1) {
          current.splice(index, 1);
        }
        const index2 = current.indexOf('Sea');
        if (index2 > -1) {
          current.splice(index2, 1);
        }
        this.setState({ waterStyle: 0.3, symbol: current });
      } else {
        current.push('Water');
        current.push('Sea');
        this.setState({ waterStyle: 1, symbol: current });
      }
    }
    if (symbol === 'terraSymbol') {
      if (current.includes('Terra')) {
        // make opacity less than 1 because it isn't a choice
        const index = current.indexOf('Terra');
        if (index > -1) {
          current.splice(index, 1);
        }
        this.setState({ terraStyle: 0.3, symbol: current });
      } else {
        current.push('Terra');
        this.setState({ terraStyle: 1, symbol: current });
      }
    }
    if (symbol === 'airSymbol') {
      if (current.includes('Air')) {
        // make opacity less than 1 because it isn't a choice
        const index = current.indexOf('Air');
        if (index > -1) {
          current.splice(index, 1);
        }
        this.setState({ airStyle: 0.3, symbol: current });
      } else {
        current.push('Air');
        this.setState({ airStyle: 1, symbol: current });
      }
    }
    if (symbol === 'plasmaSymbol') {
      if (current.includes('Plasma')) {
        // make opacity less than 1 because it isn't a choice
        const index = current.indexOf('Plasma');
        if (index > -1) {
          current.splice(index, 1);
        }
        this.setState({ plasmaStyle: 0.3, symbol: current });
      } else {
        current.push('Plasma');
        this.setState({ plasmaStyle: 1, symbol: current });
      }
    }
    if (symbol === 'necroSymbol') {
      if (current.includes('Necro')) {
        // make opacity less than 1 because it isn't a choice
        const index = current.indexOf('Necro');
        if (index > -1) {
          current.splice(index, 1);
        }
        this.setState({ necroStyle: 0.3, symbol: current });
      } else {
        current.push('Necro');
        this.setState({ necroStyle: 1, symbol: current });
      }
    }
  }
  setType(e) {
    const type = e.target.id;
    if (type === 'characterType') {
      this.setState({
        cardType: 'Character',
        characterStyle: 1,
        strategyStyle: 0.3,
        abilityStyle: 0.3,
        fieldStyle: 0.3,
        toolStyle: 0.3,
      });
    }
    if (type === 'strategyType') {
      this.setState({
        cardType: 'Strategy',
        characterStyle: 0.3,
        strategyStyle: 1,
        abilityStyle: 0.3,
        fieldStyle: 0.3,
        toolStyle: 0.3,
      });
    }
    if (type === 'abilityType') {
      this.setState({
        cardType: 'Ability',
        characterStyle: 0.3,
        strategyStyle: 0.3,
        abilityStyle: 1,
        fieldStyle: 0.3,
        toolStyle: 0.3,
      });
    }
    if (type === 'fieldType') {
      this.setState({
        cardType: 'Field',
        characterStyle: 0.3,
        strategyStyle: 0.3,
        abilityStyle: 0.3,
        fieldStyle: 1,
        toolStyle: 0.3,
      });
    }
    // if (type === 'toolType') {
    //   this.setState({
    //     cardType: 'Tool',
    //     characterStyle: 0.3,
    //     strategyStyle: 0.3,
    //     abilityStyle: 0.3,
    //     fieldStyle: 0.3,
    //     toolStyle: 1,
    //   });
    // }
  }
  setEntrance(e) {
    const entrance = e.target.id;
    if (entrance === 'zeroType') {
      this.setState({
        entranceCost: '0',
        zeroStyle: 1,
        oneStyle: 0.3,
        twoStyle: 0.3,
        threeStyle: 0.3,
        fourStyle: 0.3,
        fiveStyle: 0.3,
        sixStyle: 0.3,
        sevenStyle: 0.3,
        eightStyle: 0.3,
        nineStyle: 0.3,
      });
    }
    if (entrance === 'oneType') {
      this.setState({
        entranceCost: '1',
        zeroStyle: 0.3,
        oneStyle: 1,
        twoStyle: 0.3,
        threeStyle: 0.3,
        fourStyle: 0.3,
        fiveStyle: 0.3,
        sixStyle: 0.3,
        sevenStyle: 0.3,
        eightStyle: 0.3,
        nineStyle: 0.3,
      });
    }
    if (entrance === 'twoType') {
      this.setState({
        entranceCost: '2',
        zeroStyle: 0.3,
        oneStyle: 0.3,
        twoStyle: 1,
        threeStyle: 0.3,
        fourStyle: 0.3,
        fiveStyle: 0.3,
        sixStyle: 0.3,
        sevenStyle: 0.3,
        eightStyle: 0.3,
        nineStyle: 0.3,
      });
    }
    if (entrance === 'threeType') {
      this.setState({
        entranceCost: '3',
        zeroStyle: 0.3,
        oneStyle: 0.3,
        twoStyle: 0.3,
        threeStyle: 1,
        fourStyle: 0.3,
        fiveStyle: 0.3,
        sixStyle: 0.3,
        sevenStyle: 0.3,
        eightStyle: 0.3,
        nineStyle: 0.3,
      });
    }
    if (entrance === 'fourType') {
      this.setState({
        entranceCost: '4',
        zeroStyle: 0.3,
        oneStyle: 0.3,
        twoStyle: 0.3,
        threeStyle: 0.3,
        fourStyle: 1,
        fiveStyle: 0.3,
        sixStyle: 0.3,
        sevenStyle: 0.3,
        eightStyle: 0.3,
        nineStyle: 0.3,
      });
    }
    if (entrance === 'fiveType') {
      this.setState({
        entranceCost: '5',
        zeroStyle: 0.3,
        oneStyle: 0.3,
        twoStyle: 0.3,
        threeStyle: 0.3,
        fourStyle: 0.3,
        fiveStyle: 1,
        sixStyle: 0.3,
        sevenStyle: 0.3,
        eightStyle: 0.3,
        nineStyle: 0.3,
      });
    }
    if (entrance === 'sixType') {
      this.setState({
        entranceCost: '6',
        zeroStyle: 0.3,
        oneStyle: 0.3,
        twoStyle: 0.3,
        threeStyle: 0.3,
        fourStyle: 0.3,
        fiveStyle: 0.3,
        sixStyle: 1,
        sevenStyle: 0.3,
        eightStyle: 0.3,
        nineStyle: 0.3,
      });
    }
    if (entrance === 'sevenType') {
      this.setState({
        entranceCost: '7',
        zeroStyle: 0.3,
        oneStyle: 0.3,
        twoStyle: 0.3,
        threeStyle: 0.3,
        fourStyle: 0.3,
        fiveStyle: 0.3,
        sixStyle: 0.3,
        sevenStyle: 1,
        eightStyle: 0.3,
        nineStyle: 0.3,
      });
    }
    if (entrance === 'eightType') {
      this.setState({
        entranceCost: '8',
        zeroStyle: 0.3,
        oneStyle: 0.3,
        twoStyle: 0.3,
        threeStyle: 0.3,
        fourStyle: 0.3,
        fiveStyle: 0.3,
        sixStyle: 0.3,
        sevenStyle: 0.3,
        eightStyle: 1,
        nineStyle: 0.3,
      });
    }
    if (entrance === 'nineType') {
      this.setState({
        entranceCost: '9',
        zeroStyle: 0.3,
        oneStyle: 0.3,
        twoStyle: 0.3,
        threeStyle: 0.3,
        fourStyle: 0.3,
        fiveStyle: 0.3,
        sixStyle: 0.3,
        sevenStyle: 0.3,
        eightStyle: 0.3,
        nineStyle: 1,
      });
    }
  }
  handleEnv = (changeEvent) => {
    this.setState({
      env: changeEvent.target.value,
    });
  }
  handleRarity = (changeEvent) => {
    this.setState({
      rarity: changeEvent.target.value,
    });
  }
  handleAbility = (changeEvent) => {
    this.setState({
      abilityType: changeEvent.target.value,
    }, () => console.log(this.state));
  }
  handleSubmit() {
    let cardName = document.getElementById('cardName').value;

    let image = this.state.IMAGE;
    let symbol = this.state.symbol;

    let type = this.state.cardType;

    let entranceCost = this.state.entranceCost;

    let rarity = this.state.rarity;


    let cardSetDropDown = document.getElementById('cardSet');
    let cardSet = cardSetDropDown.options[cardSetDropDown.selectedIndex].value;

    let characterEffect = document.getElementById('description').value;
    characterEffect = characterEffect.replace(/\n\r?/g, '\r\n');

    const data = new FormData();
    if (type === "Field") {
      data.append('name', cardName);
      data.append('text', characterEffect);
      data.append('image', image, image.name);
      data.append('rarity', rarity);
      // data.append('cardNumber', cardNumber);
      data.append('cardType', type);

      data.append('cardSet', cardSet);
      for (let i = 0; i < symbol.length; i++) {
        data.append('element[]', symbol[i]);
      }
    }
    if (type === 'Character') {
      let combatDropDown = document.getElementById('combatAttr');
      let combatAttribute = document.getElementById('characterAttribute').value;
      let characterCharacteristics = document.getElementById('characterCharacteristics').value;
      let charArr = characterCharacteristics.split(',');

      let healthyStat = document.getElementById('healthyStat').value;

      let injuredStat = document.getElementById('injuredStat').value;
      let handCost = document.getElementById('handCost').value;
      data.append('name', cardName);
      data.append('text', characterEffect);
      data.append('image', image, image.name);
      data.append('rarity', rarity);
      // data.append('cardNumber', cardNumber);
      data.append('cardType', type);
      for (let i = 0; i < charArr.length; i++) {
        data.append('characteristic[]', charArr[i]);
      }
      data.append('handCost', handCost);
      data.append('turnCount', entranceCost);
      data.append('skill', combatAttribute);
      data.append('healthy', healthyStat);
      data.append('playable', true);
      data.append('errata', false);
      data.append('injured', injuredStat);
      data.append('cardSet', cardSet);
      for (let i = 0; i < symbol.length; i++) {
        data.append('element[]', symbol[i]);
      }
    }
    if (type === 'Strategy' || type === 'Client' || type === 'Tool') {
      let handCost = document.getElementById('handCost').value;

      data.append('name', cardName);
      data.append('text', characterEffect);
      data.append('image', image, image.name);
      data.append('rarity', rarity);
      // data.append('cardNumber', cardNumber);
      if (type === 'Tool') {
        data.append('cardType', 'Character');
        const toolChar = ['REINFORCEMENT'];
        for (let i = 0; i < toolChar.length; i++) {
          data.append('characteristic[]', toolChar[i]);
        }
      } else {
        data.append('cardType', type);
      }
      data.append('handCost', handCost);
      data.append('turnCount', entranceCost);
      data.append('cardSet', cardSet);
      for (let i = 0; i < symbol.length; i++) {
        data.append('element[]', symbol[i]);
      }
    }
    if (type === 'Ability') {

      let fireCost = document.getElementById('fireCost').value;
      let waterCost = document.getElementById('waterCost').value;
      let terraCost = document.getElementById('terraCost').value;
      let airCost = document.getElementById('airCost').value;
      let plasmaCost = document.getElementById('plasmaCost').value;
      let necroCost = document.getElementById('necroCost').value;
      let anyCost = document.getElementById('anyCost').value;
      const abilityCostArr = [];
      if (Number(fireCost) > 0) {
        for (let i = 0; i < fireCost; i++) {
          abilityCostArr.push('Fire');
        }
      }
      if (Number(waterCost) > 0) {
        for (let i = 0; i < waterCost; i++) {
          abilityCostArr.push('Water');
        }
      }
      if (Number(terraCost) > 0) {
        for (let i = 0; i < terraCost; i++) {
          abilityCostArr.push('Terra');
        }
      }
      if (Number(airCost) > 0) {
        for (let i = 0; i < airCost; i++) {
          abilityCostArr.push('Air');
        }
      }
      if (Number(plasmaCost) > 0) {
        for (let i = 0; i < plasmaCost; i++) {
          abilityCostArr.push('Plasma');
        }
      }
      if (Number(necroCost) > 0) {
        for (let i = 0; i < necroCost; i++) {
          abilityCostArr.push('Necro');
        }
      }
      if (anyCost) {
        abilityCostArr.push(anyCost);
      }
      data.append('name', cardName);
      data.append('text', characterEffect);
      data.append('image', image, image.name);
      data.append('rarity', rarity);
      // data.append('cardNumber', cardNumber);
      data.append('cardSet', cardSet);
      for (let i = 0; i < symbol.length; i++) {
        data.append('element[]', symbol[i]);
      }
      for (let i = 0; i < abilityCostArr.length; i++) {
        data.append('abilityCost[]', abilityCostArr[i]);
      }
      if (this.state.abilityType === 'Forbidden') {
        data.append('turnCount', entranceCost);
        data.append('cardType', 'Character');
        const toolChar = ['REINFORCEMENT'];
        for (let i = 0; i < toolChar.length; i++) {
          data.append('characteristic[]', toolChar[i]);
        }
      } else {
        data.append('cardType', type);
      }
    }

    if (cardName && characterEffect && this.state.IMAGE && symbol && type) {
      const button = document.getElementById('submitPost');
      button.setAttribute('style', 'display: none;');
      if (this.state.env === "Dev") {
        Auth.devFetch(`https://dev.primaltcg.com/api/v1/posts/${this.state.selectedCard.cardNumber}`, {
        mode: 'cors',
        method: 'PATCH',
        body: data,
      }).then(res => {
        this.resetState();
        alert('Thank you for submitting To DEV! Want to upload another?');

      }).catch(err => {
        console.log(err);
      });
      } else {
        Auth.prodFetch(`https://api.primaltcg.com/api/v1/posts/${this.state.selectedCard.cardNumber}`, {
          mode: 'cors',
          method: 'PATCH',
          body: data,
        }).then(res => {
          this.resetState();
          alert('Thank you for submitting TO LIVE! Want to upload another?');
  
        }).catch(err => {
          console.log(err);
        });
      }
    }
  }
  selectCardToUpdate(card) {
    this.setState({
      selectedCard: card
    }, () => {
      console.log("SELECTED CARD", this.state)
    });
  }
  renderTextInput(
    id,
    label,
    value,
    width,
    inputProps,
    disabled = false,
    error = false
  ) {
    return (
      <FormControl style={{ width: width }}>
        <TextField
          error={error}
          variant="outlined"
          id={id}
          label={label}
          value={value || ""}
          disabled={disabled}
          InputProps={inputProps}
          autoComplete="nope"
          onChange={(inputValue) => {
            this.setState({ [id]: inputValue.target.value });
          }}
        />
      </FormControl>
    );
  }
  renderSearchForm() {
    const { searchName, searchChar, searchCardEff} = this.state;
    return (    
      <div style={{ width: "100vw"}} className="characterForm row">
<div className="col-xs-12 col-md-12">
<div className="rarityContainer col-xs-12 col-sm-6 col-md-6">
        <h4 id="characterFormHead">APP MODE</h4>
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="env" value="Dev" checked={this.state.env === 'Dev'}
            onChange={this.handleEnv} /> <p>Dev</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="env" value="Live"checked={this.state.env === 'Live'}
            onChange={this.handleEnv} />  <p>Live</p></div><br />
        </div>
    <h1 className="mb-2vh">Search Card To Update</h1>
    <div className="container mb-2vh">
    {this.renderTextInput("searchName", "Search by Card Name (Micromon Krakaan)", searchName, "100%")}
    </div>
    <div className="container mb-2vh">
    {this.renderTextInput("searchChar", "Search by Characteristics (Transformation Female)", searchChar, "100%")}
    </div>
    <div className="container mb-2vh">
    {this.renderTextInput("searchCardEff", "Search by Card Effect", searchCardEff, "100%")}
    </div>
    <Button
                variant="contained"
                color="primary"
                styles={{
                  cursor: "pointer",
                  width: "100%"
                }}
                onClick={(e) => {
                  this.submitSearch(e)
                }}
              >
                  <div style={{fontSize: "26px"}}>
                    Search Card
                  </div>
              </Button>
  </div>
      </div>    
    )
  }
  renderCardUpdateForm() {
    return (
      <div style={{
        height: "auto",
        padding: "0px",
      }} className="characterForm row">
        <div className='col-md-12'>
        <Button
                variant="contained"
                color="primary"
                styles={{
                  cursor: "pointer",
                  width: "400px",
                }}
                onClick={(e) => {
                  this.resetState();
                }}
                
              >
                  <div style={{fontSize: "26px"}}>
                    Reset
                  </div>
              </Button>
        </div>
        <div className='col-md-6'>
        <h4>MAKE SURE IMAGE FILE SIZE IS LESS THAN 1MB</h4>
        <div className="formImageContainer col-md-12">
          {this.state.IMAGE ? <img src={this.state.image} /> : <img src={`${this.state.env === "Dev" ? "https://dev.primaltcg.com/" : "https://api.primaltcg.com/"}` + this.state.selectedCard.image} />}
        </div>
        <div className="col-md-12">
          <a id="submitCardBtn" className="btn">
            <label htmlFor="cardImg"><h4 id="characterFormHead">Upload Card Image</h4></label>
          </a>
          <input
            className="postfile"
            type="file"
            name="cardImg"
            id="cardImg"
            onChange={this.imagePreview}
          />
        </div>
        </div>
        <div className='col-md-6'>
        <div className="row">
        <div className="col-md-4 col-sm-4 col-xs-4">
          <h4 id="characterFormHead">Card Name:</h4>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-6">
          <input id="cardName" className="form-control cardName" placeholder="Type the Card's name" />
        </div>
        </div>
        <div className="formHead col-md-12 col-xs-12">
          <h4 id="characterFormHead">Card Symbol</h4>
        </div>

        <div className="symbolContainer row">
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="fireSymbol" style={{ opacity: this.state.fireStyle }} onClick={this.setSymbol} src={fire} />
            </a>
          </div>
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="waterSymbol" style={{ opacity: this.state.waterStyle }} onClick={this.setSymbol} src={water} />
            </a>
          </div>
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="terraSymbol" style={{ opacity: this.state.terraStyle }} onClick={this.setSymbol} src={terra} />
            </a>
          </div>
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="plasmaSymbol" style={{ opacity: this.state.plasmaStyle }} onClick={this.setSymbol} src={plasma} />
            </a>
          </div>
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="airSymbol" style={{ opacity: this.state.airStyle }} onClick={this.setSymbol} src={air} />
            </a>
          </div>
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="necroSymbol" style={{ opacity: this.state.necroStyle }} onClick={this.setSymbol} src={necroSymbol} />
            </a>
          </div>
        </div>
        <div className="symbolContainer row">
          <div className="typeHead col-xs-4">
            <h4 id="characterFormHead">Card Type:</h4>
          </div>
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="characterType" style={{ opacity: this.state.characterStyle }} onClick={this.setType} src={character} />
            </a>
          </div>
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="abilityType" style={{ opacity: this.state.abilityStyle }} onClick={this.setType} src={ability} />
            </a>
          </div>
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="strategyType" style={{ opacity: this.state.strategyStyle }} onClick={this.setType} src={strategy} />
            </a>
          </div>
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="fieldType" style={{ opacity: this.state.fieldStyle }} onClick={this.setType} src={field} />
            </a>
          </div>
          {/* <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="toolType" style={{ opacity: this.state.toolStyle }} onClick={this.setType} src={tool} />
            </a>
          </div> */}
        </div>
        {
          (this.state.abilityType !== 'Ability' && this.state.abilityType !== '') || this.state.cardType !== 'Ability' && this.state.cardType !== "Field"
            ? (<div>
              <div className="entranceHead col-xs-12 col-sm-12 col-md-12">
                <h4 id="characterFormHead">Entrance Cost</h4>
              </div>
              <div className="entranceContainer col-xs-12 col-sm-12 col-md-12">
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="zeroType" style={{ opacity: this.state.zeroStyle }} onClick={this.setEntrance} src={zero} />
                  </a>
                </div>
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="oneType" style={{ opacity: this.state.oneStyle }} onClick={this.setEntrance} src={one} />
                  </a>
                </div>
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="twoType" style={{ opacity: this.state.twoStyle }} onClick={this.setEntrance} src={two} />
                  </a>
                </div>
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="threeType" style={{ opacity: this.state.threeStyle }} onClick={this.setEntrance} src={three} />
                  </a>
                </div>
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="fourType" style={{ opacity: this.state.fourStyle }} onClick={this.setEntrance} src={four} />
                  </a>
                </div>
              </div>
              <div className="entranceContainer col-xs-12 col-sm-12 col-md-12">
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="fiveType" style={{ opacity: this.state.fiveStyle }} onClick={this.setEntrance} src={five} />
                  </a>
                </div>
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="sixType" style={{ opacity: this.state.sixStyle }} onClick={this.setEntrance} src={six} />
                  </a>
                </div>
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="sevenType" style={{ opacity: this.state.sevenStyle }} onClick={this.setEntrance} src={seven} />
                  </a>
                </div>
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="eightType" style={{ opacity: this.state.eightStyle }} onClick={this.setEntrance} src={eight} />
                  </a>
                </div>
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="nineType" style={{ opacity: this.state.nineStyle }} onClick={this.setEntrance} src={nine} />
                  </a>
                </div>
              </div>

            </div>)
            : <div />
        }
        <div className="row">
        <div className="rarityContainer col-xs-12 col-sm-6 col-md-6">
          <h4 id="characterFormHead">Card Rarity</h4>
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Common" checked={this.state.rarity === 'Common'}
            onChange={this.handleRarity} /> <p>Common</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Uncommon"checked={this.state.rarity === 'Uncommon'}
            onChange={this.handleRarity} />  <p>Uncommon</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Rare" checked={this.state.rarity === 'Rare'}
            onChange={this.handleRarity} />  <p>Rare</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Super Rare" checked={this.state.rarity === 'Super Rare'}
            onChange={this.handleRarity} />  <p>Super Rare</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Promo" checked={this.state.rarity === 'Promo'}
            onChange={this.handleRarity} />  <p>Promo</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Parallel" checked={this.state.rarity === 'Parallel'}
            onChange={this.handleRarity} />  <p>Parallel</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="STSR" checked={this.state.rarity === 'STSR'}
            onChange={this.handleRarity} />  <p>Starter Super Rare</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Starter" checked={this.state.rarity === 'Starter'}
            onChange={this.handleRarity} />  <p>Starter</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Black & White Gold" checked={this.state.rarity === 'Black & White Gold'}
            onChange={this.handleRarity} />  <p>Black & White Gold</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Secret Rare" checked={this.state.rarity === 'Secret Rare'}
            onChange={this.handleRarity} />  <p>Secret Rare</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Legendary Rare" checked={this.state.rarity === 'Legendary Rare'}
            onChange={this.handleRarity} />  <p>Legendary Rare</p></div><br />
        </div>
        <div className="otherContainer col-xs-12 col-sm-6 col-md-6">
          {
            this.state.cardType === 'Character'
              ? (<div className="row">
                <div className="col-md-6 col-xs-6">
                  <h4 className="combatHead">Attribute:</h4>
                </div>
                <div className="col-md-6 col-xs-6">
                <input id="characterAttribute" className="form-control cardName" placeholder="Type the Character's Attribute" />

                </div>
              </div>)
              : <div />
          }
          <div className="row">
            <div className="col-md-6 col-xs-6">
              <h4 className="cardSetHead">Card Set:</h4>
            </div>
            <div className="col-md-6 col-xs-6">
              <select id="cardSet">
                <option value="Awakened Primordials">Awakened Primordials</option>
                <option value="Battlers of Bethesda">Battlers of Bethesda</option>
                <option value="Sacred Shards">Sacred Shards</option>
                <option value="Set 4">Set 4</option>
                <option value="Promo">Promo</option>
              </select>
            </div>
          </div>
          {
            this.state.cardType === 'Character'
              ? (
                <div>
                <div className="row">
                  <div className="col-xs-6 col-md-6">
                    <h4 className="cardSetHead">Characteristics (SEPARATE WITH COMMAS):</h4>
                  </div>
                  <div className="col-xs-6 col-md-6">
                    <input id="characterCharacteristics" className="form-control cardName" placeholder="Male, Cayne Pirate, Weapon" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-4 col-md-4">
                    <h4 className="cardSetHead">Healthy:</h4>
                  </div>
                  <div className="col-xs-6 col-md-6">
                    <input id="healthyStat" className="form-control cardName" placeholder="0/0" />
                  </div>
                  <div className="col-xs-3 col-md-3">
                    <h4 className="cardSetHead">Injured:</h4>
                  </div>
                  <div className="col-xs-6 col-md-6">
                    <input id="injuredStat" className="form-control cardName" placeholder="0/0" />
                  </div>
                </div></div>
              )
              : <div />
          }
          <div className="row">
          {this.state.cardType === 'Ability'
                ? (<div className="abilityContainer col-xs-12 col-sm-12 col-md-12">
                <h5 id="characterFormHead">Ability Type</h5>
                <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="abilityType" value="Ability" checked={this.state.abilityType === 'Ability'}
                  onChange={this.handleAbility} /> <p>Regular</p></div><br />
                <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="abilityType" value="Forbidden"checked={this.state.abilityType === 'Forbidden'}
                  onChange={this.handleAbility} />  <p>Forbidden</p></div><br />
              </div>)
                : (<div />)
              }
            <div className="col-xs-4 col-md-8">
              <h4 className="cardSetHead">Card Number:</h4>
            </div>
            <div className="col-xs-6 col-md-6">
              <FormControl style={{ width: "100%" }}>
        <TextField
          variant="outlined"
          label={"Card Number"}
          value={this.state.selectedCard.cardNumber}
          disabled={true}
        />
      </FormControl>
            </div>
            {
              this.state.cardType !== 'Ability' && this.state.cardType !== 'Field'
                ? (<div className="row">
                  <div className="col-xs-6 col-md-6">
                    <h4 className="cardSetHead">Hand Cost:</h4>
                  </div>
                  <div className="col-xs-3 col-md-3">
                    <input id="handCost" className="form-control cardName" placeholder="1" />
                  </div>
                </div>)
                : (<div />)
            }
            {
              this.state.cardType ==='Ability'
                ? (
                  <div className="col-xs-12">
                    <h4 id="characterFormHead">Ability Cost</h4>
                    <div className="abilityCostContainer col-xs-12">
                      <div className="col-xs-6 col-md-6">
                        <img className="abilitySymbol" src={fire} />
                      </div>
                      <div className="col-xs-3 col-md-3">
                        <input id="fireCost" className="form-control cardName" placeholder="0" />
                      </div>
                    </div>
                    <div className="abilityCostContainer col-xs-12">
                      <div className="col-xs-6 col-md-6">
                        <img className="abilitySymbol" src={water} />
                      </div>
                      <div className="col-xs-3 col-md-3">
                        <input id="waterCost" className="form-control cardName" placeholder="0" />
                      </div>
                    </div>
                    <div className="abilityCostContainer col-xs-12">
                      <div className="col-xs-6 col-md-6">
                        <img className="abilitySymbol" src={terra} />
                      </div>
                      <div className="col-xs-3 col-md-3">
                        <input id="terraCost" className="form-control cardName" placeholder="0" />
                      </div>
                    </div>
                    <div className="abilityCostContainer col-xs-12">
                      <div className="col-xs-6 col-md-6">
                        <img className="abilitySymbol" src={air} />
                      </div>
                      <div className="col-xs-3 col-md-3">
                        <input id="airCost" className="form-control cardName" placeholder="0" />
                      </div>
                    </div>
                    <div className="abilityCostContainer col-xs-12">
                      <div className="col-xs-6 col-md-6">
                        <img className="abilitySymbol" src={plasma} />
                      </div>
                      <div className="col-xs-3 col-md-3">
                        <input id="plasmaCost" className="form-control cardName" placeholder="0" />
                      </div>
                    </div>
                    <div className="abilityCostContainer col-xs-12">
                      <div className="col-xs-6 col-md-6">
                        <img className="abilitySymbol" src={necroSymbol} />
                      </div>
                      <div className="col-xs-3 col-md-3">
                        <input id="necroCost" className="form-control cardName" placeholder="0" />
                      </div>
                    </div>
                    <div className="abilityCostContainer col-xs-12">
                      <div className="col-xs-6 col-md-6">
                        <img className="abilitySymbol" src={colorless} />
                      </div>
                      <div className="col-xs-3 col-md-3">
                        <input id="anyCost" className="form-control cardName" placeholder="0" />
                      </div>
                    </div>

                  </div>
                )
                : <div />
            }
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-12">
          <div className="PostInputs">
            <label>Card Effect:</label>
            <textarea
              id="description"
              className="form-control" rows="6" placeholder="Card Effect"
            />
          </div>
        </div>
        <div className="row">
        <div className="rarityContainer col-xs-12 col-sm-6 col-md-6">
        <h4 id="characterFormHead">APP MODE {this.state.env}</h4>
        </div>
        </div>
        <div className="col-xs-12">
          <a className="btn" onClick={this.handleSubmit} id="submitPost">Post</a>
        </div>
        </div>
      </div>
    );
  }
  renderCardSelection() {
    const { cards,SuperRare,LegendaryRare, Rare, Uncommon, Common, loading, Starter, Promo, SecretRare } = this.state;
    return (
      <div style={{ width: "100vw" }} className="SearchResults">
        <Button
                variant="contained"
                color="primary"
                styles={{
                  cursor: "pointer",
                  width: "100%"
                }}
                onClick={(e) => {
                  this.resetState();
                }}
              >
                  <div style={{fontSize: "26px"}}>
                    Reset
                  </div>
              </Button>
        <div className={loading || cards.length === 0 ? "row bkg-red full-h" : "row bkg-red"}>
        <div className="col-xs-12 col-sm-8 col-md-8 col-xl-8 offset-sm-2 offset-md-2 offset-xl-2 floating-section">
          <h1>Update Card Search Results</h1>
         
          {cards.length === 0 && loading === false ? (<h2 style={{ color: "#9D0208" }}>No Cards Match That Search. Try Again</h2>) : (<div />)}
          {SecretRare.length > 0 ? (<h2 className="rarityTitle">Secret Rare ({this.state.secretRareCount})</h2>) : <div />}
          {SecretRare.length > 0 ? SecretRare.map((cardSet, index) => (
            <MapListRow
              key={index + "SCR"}
              cardRow={cardSet}
              history={this.props.history}
              view={"Dev"}
              env={this.state.env}
              handleClick={(card) => this.selectCardToUpdate(card)}
            />
            )) : <div />}
          {LegendaryRare.length > 0 ? (<h2 className="rarityTitle">Legendary Rare ({this.state.legendaryRareCount})</h2>) : <div />}
          {LegendaryRare.length > 0 ? LegendaryRare.map((cardSet, index) => (
            <MapListRow
              key={index + "LR"}
              cardRow={cardSet}
              history={this.props.history}
              view={"Dev"}
              env={this.state.env}
              handleClick={(card) => this.selectCardToUpdate(card)}
            />
            )) : <div />}
          {SuperRare.length > 0 ? (<h2 className="rarityTitle">Super Rare ({this.state.superRareCount})</h2>) : <div />}
          {SuperRare.length > 0 ? SuperRare.map((cardSet, index) => (
            <MapListRow
              key={index + "SR"}
              cardRow={cardSet}
              history={this.props.history}
              view={"Dev"}
              env={this.state.env}
              handleClick={(card) => this.selectCardToUpdate(card)}
            />
            )) : <div />}
          {Rare.length > 0 ? (<h2 className="rarityTitle">Rare ({this.state.rareCount})</h2>) : <div />}
          {Rare.length > 0 ? Rare.map((cardSet, index) => (
            <MapListRow
              key={index + "R"}
              cardRow={cardSet}
              history={this.props.history}
              view={"Dev"}
              env={this.state.env}
              handleClick={(card) => this.selectCardToUpdate(card)}
            />
            )) : <div />}
          {Uncommon.length > 0 ? (<h2 className="rarityTitle">Uncommon ({this.state.uncommonCount})</h2>) : <div />}
          {Uncommon.length > 0 ? Uncommon.map((cardSet, index) => (
            <MapListRow
              key={index + "U"}
              cardRow={cardSet}
              history={this.props.history}
              view={"Dev"}
              env={this.state.env}
              handleClick={(card) => this.selectCardToUpdate(card)}
            />
            )) : <div />}
          {Common.length > 0 ? (<h2 className="rarityTitle">Common ({this.state.commonCount})</h2>) : <div />}
          {Common.length > 0 ? Common.map((cardSet, index) => (
            <MapListRow
              key={index + "C"}
              cardRow={cardSet}
              history={this.props.history}
              view={"Dev"}
              env={this.state.env}
              handleClick={(card) => this.selectCardToUpdate(card)}
            />
            )) : <div />}
            {Promo.length > 0 ? (<h2 className="rarityTitle">Promo ({this.state.promoCount})</h2>) : <div />}
          {Promo.length > 0 ? Promo.map((cardSet, index) => (
            <MapListRow
              key={index + "PR"}
              cardRow={cardSet}
              history={this.props.history}
              view={"Dev"}
              env={this.state.env}
              handleClick={(card) => this.selectCardToUpdate(card)}
            />
            )) : <div />}
            {Starter.length > 0 ? (<h2 className="rarityTitle">Starter ({this.state.starterCount})</h2>) : <div />}
          {Starter.length > 0 ? Starter.map((cardSet, index) => (
            <MapListRow
              key={index + "STR"}
              cardRow={cardSet}
              history={this.props.history}
              view={"Dev"}
              env={this.state.env}
              handleClick={(card) => this.selectCardToUpdate(card)}
            />
            )) : <div />}
        </div>
      </div>
      </div>
    );
  }

  get2dArray(arr) {
    let collection = [];
    let temp = [];
    let collectionEnd = arr.length;
    let remainder = collectionEnd % 3;
    arr.map(card => {
      temp.push(card);
      if (temp.length === 3) {
        collection.push(temp);
        temp = [];
      }
    });
    if (remainder === 2) {
      collection.push([arr[collectionEnd - 2], arr[collectionEnd - 1]]);
    }
    if (remainder === 1) {
      collection.push([arr[collectionEnd - 1]]);
    }
    return collection;
  }
  formatSearchResults(cards) {
    let superRare = [];
    let legendaryRare = [];
    let secretRare = [];
    let rare = [];
    let uncommon = [];
    let common = [];
    let promo = [];
    // let bng = [];
    // let parallel = [];
    let starter = [];
    let devToken = localStorage.getItem("DEV");
    cards.map(card => {
        if (card.rarity.includes("Legendary")) {
          legendaryRare.push(card);
        }
        if (card.rarity.includes("Secret")) {
          secretRare.push(card);
        }
        if (card.rarity === 'Super Rare') {
          superRare.push(card);
        }
        // if (card.rarity === 'STSR') {
        //   superRare.push(card);
        // }
        if (card.rarity === 'Rare') {
          rare.push(card);
        }
        if (card.rarity === 'Uncommon') {
          uncommon.push(card);
        }
        if (card.rarity === 'Promo') {
          promo.push(card);
        }
        if (card.rarity === 'Common') {
          common.push(card);
        }
        // if (card.rarity === 'Parallel') {
        //   parallel.push(card);
        // }
        // if (card.rarity === 'B&G Super Rare') {
        //   bng.push(card);
        // }
        if (card.rarity === 'Starter') {
          starter.push(card);
        }
    });
    let LR = this.get2dArray(legendaryRare);
    let SCR = this.get2dArray(secretRare);
    let SR = this.get2dArray(superRare);
    let R = this.get2dArray(rare);
    let U = this.get2dArray(uncommon);
    let P = this.get2dArray(promo);
    let C = this.get2dArray(common);
    let Start = this.get2dArray(starter);
    this.setState(
      {
        cards: cards,
        SuperRare: SR,
        SecretRare: SCR,
        LegendaryRare: LR,
        Rare: R,
        Uncommon: U,
        Promo: P,
        Common: C,
        Starter: Start,
        secretRareCount: secretRare.length,
        legendaryRareCount: legendaryRare.length,
        superRareCount: superRare.length,
        rareCount: rare.length,
        uncommonCount: uncommon.length,
        commonCount: common.length,
        promoCount: promo.length,
        starterCount: starter.length,
        loading: false
      },
      () => {
        console.log("UPDATED STATE", this.state)
      },
    );
  }
  render() {
    if (this.state.selectedCard) {
      return this.renderCardUpdateForm();
    }
    if (this.state.searchedCards.length > 0 && this.state.cards.length > 0) {
      return this.renderCardSelection();
    }
    return (this.renderSearchForm())
  }
}
export default UpdateNinjaForm;


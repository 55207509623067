import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Papa from "papaparse";
import main from "../assets/primal/preorder/main.png"
import DemoDecks from "../assets/primal/preorder/DemoDecks.png"
import box from "../assets/primal/preorder/firstEd02.png"
import contactbkg from '../assets/naruto/front/contactbkg.jpg';
import decks from "../assets/primal/preorder/decks.png"
import _case from "../assets/primal/preorder/case.png"
import master from "../assets/primal/preorder/master.png"
import decksFirstEd from "../assets/primal/preorder/decksFirstEd.png"
import firstEdBooster from "../assets/primal/preorder/firstEdBooster.png"
import starterCase from "../assets/primal/preorder/starterCase.png"
import Navbar from "../Navbar/Navbar";
import { Tabs, Tab, AppBar, FormControl, TextField, Box, Typography, Button, CircularProgress } from "@material-ui/core";
import CheckOrder from './CheckOrder';
import Footer from "../Footer/Footer";

var submitUrl = "https://script.google.com/macros/s/AKfycbwYRlknINN3UrHUpfoyxVQsnNFioOaLpdxOK0CuNXdrGscoLLY8kMth56jHHJxSrmHH/exec";

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class BusinessPreorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEvent: null,
      data: null,
      businesses: [],
      individuals: [],
      boxesOrdered: null,
      decksOrdered: null,
      type: 1,
      name: "",
      email: "",
      phone: "",
      address: "",
      boxes: 0,
      starters: 0,
      box_cases: 0,
      master_cases: 0,
      starter_cases: 0,
      loading: true,
      open: false
    } 
    this.formRef = React.createRef(null);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
  }
  componentDidMount() {
    this.getData();
  }
  getData() {
    let that = this;
    Papa.parse('https://docs.google.com/spreadsheets/d/1Lc-s1wjkWpGmkZkuRR5IhLJaSPxLISEyMf0dVxG7YEM/pub?output=csv', {
          download: true,
          header: true,
          complete: function(results) {
            var data = results.data
            let businesses = [];
            let individuals = [];
            let boxesOrdered = 0;
            let decksOrdered = 0;
            data.forEach(row => { 
              if (row.type.includes("business")) {
                businesses.push(row);
              }
              if (row.type.includes("individual")) {
                individuals.push(row);
              }
              let boxes = parseInt(row.boxes);
              let boxCases = parseInt(row.box_cases);
              let masterCases = parseInt(row.master_cases);
              if (isNaN(boxes)) {
                boxes = 0;
              }
              if (isNaN(boxCases)) {
                boxCases = 0;
              }
              if (isNaN(masterCases)) {
                masterCases = 0;
              }
              boxes+= boxCases*10;
              boxes+= masterCases*30;
              boxesOrdered+= boxes;

              let starters = parseInt(row.starters);
              let starterCases = parseInt(row.starter_cases);
              if (isNaN(starters)) {
                starters = 0;
              }
              if (isNaN(starterCases)) {
                starterCases = 0;
              }
              console.log("STARTERS FOR ROW", {row: row, starters: starters});
              starters = starters * 2;
              starters += starterCases * 24;
              decksOrdered += starters;
            });
            that.setState({
              data: data,
              businesses: businesses,
              individuals: individuals,
              boxesOrdered: boxesOrdered,
              decksOrdered: decksOrdered,
              loading: false
            }, () => {
              console.log("DATA", data);
            })
          }
        });
  }
  toggleOpen() {
    this.setState({
      open: !this.state.open
    });
  }
    /**
   * Renders TextInput with FromControl
   *
   * @param {string} id
   * @param {string} label
   * @param {string} value
   * @param {any} width Can be int or string, controls the width of the form control
   * @returns {React$Element<FormControl>}
   * @memberof NewShipmentComponent
   */
    renderTextInput(
      id: string,
      label: any,
      value: string,
      width: any,
      type: any,
      inputProps: Object,
      disabled: boolean = false,
      error: boolean = false
    ): React$Element<FormControl> {
      return (
        <FormControl style={{ width: width }}>
          <TextField
            error={error}
            variant="outlined"
            id={id}
            type={type}
            label={label}
            value={value || ""}
            disabled={disabled || this.state.disabled}
            InputProps={inputProps}
            autoComplete="nope"
            onChange={(inputValue: Object) => {
              this.setState({
                [id]: inputValue.target.value
              })
            }}
          />
        </FormControl>
      );
    }
  handleSubmit(e) {
    const { type, name, email, phone, address, boxes, starters, box_cases, starter_cases, master_cases } = this.state; 
    e.preventDefault()
    let formData = new FormData();
    formData.append("type", type === 0 ? "individual" : "business");
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("address", address);
    formData.append("boxes", boxes);
    formData.append("starters", starters);
    formData.append("box_cases", box_cases);
    formData.append("starter_cases", starter_cases);
    formData.append("master_cases", master_cases);
    fetch(submitUrl, {method: 'POST', body: formData})
    .then(async res => {
        let result = await res.json();
        console.log("SUCCESSFULLY SUBMITTED", result);
        this.setState({
          name: "",
          email: "",
          phone: "",
          address: "",
          boxes: 0,
          starters: 0,
          box_cases: 0,
          master_cases: 0,
          starter_cases: 0
        }, () => {
          this.props.history.push("/preorder-confirmation");
          alert("Your Pre-Order has been submitted!")
        })
    })
    .catch(err => console.log(err))
}
  setUserType(value) {
    this.setState({
      type: value
    });
  }
  userForm() {
    return (
    <div className='col-md-6 col-sm-6 col-xs-12 user-form'>
      <div style={{ padding: "5%"}} className="row">
      <AppBar position="static" color="default">
              <Tabs
                value={this.state.type}
                onChange={(e, value) => this.setUserType(value)}
                indicatorColor="primary"
                textColor="primary"
                centered={true}
                variant="fullWidth"
              >
                <Tab
                  label={"Individual"}
                  disabled={false}
                  id="individual"
                />
                <Tab
                  label={"Business"}
                  disabled={false}
                  id="business"
                />
              </Tabs>
            </AppBar>
            <TabPanel className="col-xs-12" value={this.state.type} index={0}>
        {this.renderIndividualForm()}
      </TabPanel>
      <TabPanel className="col-xs-12" value={this.state.type} index={1}>
      {this.renderBusinessForm()}
      </TabPanel>
      </div>
      
  </div>)
  }
  renderIndividualForm() {
    return (
      <div>
        <h2>Contact Information</h2>
        <p>Fill out all of the information to pre-order.</p>
        <div style={{ marginTop: "5px"}} className='row'>
          <div className='col-sm-6 col-md-6 col-lg-6 inputRow'>
          {this.renderTextInput("name","Full Name",this.state.name, "100%")} 
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 inputRow'>
          {this.renderTextInput("email","Email",this.state.email, "100%")} 
          </div>
        </div>
        <div className='row'>
        <div className='col-sm-6 col-md-6 col-lg-6 inputRow'>
        <FormControl style={{ width: "100%" }}>
          <TextField
            variant="outlined"
            label={"Address"}
            value={this.state.address || ""}
            autoComplete="nope"
            multiline
            rows={4}
            onChange={(inputValue: Object) => {
              this.setState({
                address: inputValue.target.value
              })
            }}
          />
        </FormControl>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 inputRow'>
          {this.renderTextInput("phone","Phone Number",this.state.phone, "100%")} 
          </div>
        </div>
        <h2>Pre-Order Product</h2>
        <p><strong>Enter desired quantities per product.</strong></p>
        <h5><strong>Pre-Orders close November 17, 2023.</strong></h5>
        <h5><strong>You will receive a payment request via email on November 17, 2023.</strong></h5>
        <h5><strong>Your payment must be completed by November 30, 2023 if not your pre-order will be cancelled.</strong></h5>
        <div style={{ marginBottom: "15px", marginTop: "5px"}} className='row'>
          <div className='pop-section col-xs-12 col-sm-5 col-md-5 col-lg-5 offset-md-1'>
            <img src={box} style={{width: "100%"}} />
            <h3 className='priceBoxes'>$110.00</h3>
          <ul>
          <li><p className='bold'>1 Booster Box</p></li>
          <li><p className='bold'>24 Booster Packs, 10 Cards per Pack, 1 Foil Card per Pack</p></li>
          </ul>
          {this.renderTextInput("boxes","1st Ed Booster Boxes ($110.00)",this.state.boxes, "100%", "number")} 
          </div>
          <div className='pop-section col-xs-12 col-sm-5 col-md-5 col-lg-5 offset-md-1'>
          <img src={decks} style={{width: "100%"}} />
          <h3 className='priceBoxes'>$30.00</h3>
          <ul>
          <li><p className='bold'>2 Starter Decks</p></li>
          <li><p className='bold'>1 Exclusive Super Rare Per Deck</p></li>
          </ul>
          {this.renderTextInput("starters","Set of 2 Starter Decks ($30.00)",this.state.starters, "100%", "number")} 
          </div>
          <div className='col-md-12'>
          <Button
                variant="contained"
                color="primary"
                styles={{
                  cursor: "pointer",
                  width: "100%"
                }}
                fullWidth={true}
                onClick={(e) => {
                  this.handleSubmit(e)
                }}
              >
                  <div style={{fontSize: "26px"}}>
                    Submit Pre-Order
                  </div>
              </Button>
        </div>
        </div>
      </div>
    );
  }
  renderBusinessForm() {
    return (
      <div>
        <h2>Contact Information</h2>
        <p>Fill out all of the information to pre-order.</p>
        <div style={{marginTop: "5px"}} className='row'>
          <div className='col-sm-6 col-md-6 col-lg-6 inputRow'>
          {this.renderTextInput("name","Full Name",this.state.name, "100%")} 
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 inputRow'>
          {this.renderTextInput("email","Email",this.state.email, "100%")} 
          </div>
        </div>
        <div style={{ marginBottom: "15px"}} className='row'>
        <div className='col-sm-6 col-md-6 col-lg-6 inputRow'>
        <FormControl style={{ width: "100%" }}>
          <TextField
            variant="outlined"
            label={"Address"}
            value={this.state.address || ""}
            autoComplete="nope"
            multiline
            rows={4}
            onChange={(inputValue: Object) => {
              this.setState({
                address: inputValue.target.value
              })
            }}
          />
        </FormControl>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 inputRow'>
          {this.renderTextInput("phone","Phone Number",this.state.phone, "100%")} 
          </div>
        </div>
        <h2>Pre-Order Product</h2>
        <p><strong>Enter desired quantities per product.</strong></p>
        <h5><strong>Pre-Orders close November 17, 2023.</strong></h5>
        <h5><strong>You will receive a payment request via email on November 17, 2023.</strong></h5>
        <h5><strong>Your payment must be completed by November 30, 2023 if not your pre-order will be cancelled.</strong></h5>
        <br />
        <br />
        <h5>If you are interested in becoming a direct partner with us to have lower prices per cases and exclusive promos, email us at contact@primaltcg.com to request to apply as a partner.</h5>
        <div style={{ marginBottom: "15px", marginTop: "5px"}} className='row'>
        <div className='pop-section col-xs-12 col-sm-5 col-md-5 col-lg-5 offset-md-1'>
          <img src={_case} style={{width: "100%"}} />
          <h3 className='priceBoxes'>$800.00</h3>
          <ul>
          <li><p className='bold'>10 Booster Boxes</p></li>
          <li><p className='bold'>MSRP $110.00</p></li>
          <li><p className='bold'>240 Booster Packs, 10 Cards per Pack, 1 Foil Card per Pack</p></li>
          <li><p className='bold'>3 Guaranteed Legendary Rares</p></li>
          </ul>
          {this.renderTextInput("box_cases","1st Ed Booster Cases (10 Box)",this.state.box_cases, "100%", "number")} 
          </div>
          <div className='pop-section col-xs-12 col-sm-5 col-md-5 col-lg-5 offset-md-1'>
          <img src={starterCase} style={{width: "100%"}} />
          <h3 className='priceBoxes'>$190.00</h3>
          <ul>
          <li><p className='bold'>24 Individual Starter Decks</p></li>
          <li><p className='bold'>MSRP $15.00</p></li>
          <li><p className='bold'>1 Super Rare Holo Per Deck</p></li>
          <li><p className='bold'>Cards are exclusive to Starter Decks</p></li>
          </ul>
            {this.renderTextInput("starter_cases","1st Ed Starter Decks Cases (24 Decks)",this.state.starter_cases, "100%", "number")} 
          </div>
        </div>
        <div style={{ marginBottom: "15px", marginTop: "5px"}} className='row'>
        <div className='pop-section col-xs-12 col-sm-8 col-md-8 col-lg-8 offset-md-2'>
          <img src={master} style={{width: "100%"}} />
          <h3 className='priceBoxes'>$2,100.00</h3>
          <ul>
          <li><p className='bold'>30 Booster Boxes</p></li>
          <li><p className='bold'>MSRP $110.00</p></li>
          <li><p className='bold'>720 Booster Packs, 10 Cards per Pack, 1 Foil Card per Pack</p></li>
          <li><p className='bold'>4 Guaranteed Legendary Rares</p></li>
          </ul>
          {this.renderTextInput("master_cases","1st Ed Booster Master Cases (30 Box)",this.state.master_cases, "100%", "number")} 
          </div>
        
          <div className='col-md-12'>
          <Button
                variant="contained"
                color="primary"
                styles={{
                  cursor: "pointer",
                  width: "100%"
                }}
                fullWidth={true}
                onClick={(e) => {
                  this.handleSubmit(e)
                }}
              >
                  <div style={{fontSize: "26px"}}>
                    Submit Pre-Order
                  </div>
              </Button>
        </div>

        </div>
      </div>
    );
  }
  renderCountingRow() {
    return(
      <div style={{
        display: "flex",
        justifyContent: "space-around",
        marginTop: "20px",
        marginLeft: "0px",
        marginRight: "0px",
        paddingBottom: "25px"
      }} className="bkg-red full-w row">
          <div className='col-md-2 text-white text-centered'>
            <h2 style={{ fontWeight: "bold"}}>{this.state.businesses.length}</h2>
            <h4>Business Pre-Orders</h4>
          </div>
          <div className='col-md-2 text-white text-centered'>
          <h2 style={{ fontWeight: "bold"}}>{this.state.individuals.length}</h2>
            <h4>Individual Pre-Orders</h4>
          </div>
          <div className='col-md-2 text-white text-centered'>
          <h2 style={{ fontWeight: "bold"}}>{this.state.boxesOrdered}</h2>
            <h4>Booster Boxes Pre-Ordered</h4>
          </div>
          <div className='col-md-2 text-white text-centered'>
          <h2 style={{ fontWeight: "bold"}}>{this.state.decksOrdered}</h2>
            <h4>Starter Decks Pre-Ordered</h4>
          </div>
        </div>
    );
  }
  productDetails() {
    return (
      <div className='col-md-6 col-sm-6 col-xs-12'>
        <h1 style={{ marginLeft: "15px"}}>Product Details</h1>
        <img style={{ width: "100%" }} src={firstEdBooster} />
        <h2 style={{ marginLeft: "15px"}} className='bold'>Primal TCG 1st Ed. Booster Box</h2>
        <h3 className='priceHead'>MSRP $110.00</h3>
        <ul>
          <li><h4 className='bold'>165 Card Base Set</h4></li>
          <li><h4 className='bold'>24 Booster Packs, 10 Cards per Pack, 1 Foil Card per Pack</h4></li>
          <li><h4 className='bold'>14 Different Full Art Super Rare Box Topper Exclusives!</h4></li>
          <li><h4 className='bold'>4 Different Legendary Rare Cards!</h4></li>
          <ul>
            <li><h4>1 out of 240 Packs (1 Average in Case)</h4></li>
            <li><h4>1 out of 240 Packs (1 Average in Case)</h4></li>
            <li><h4>1 out of 240 Packs (1 Average in Case)</h4></li>
            <li><h4>1 out of 720 Packs (1 Average in Master Case)</h4></li>
          </ul>
          <li><h4><strong className='bold'>Case Size:</strong> 10 Booster Boxes</h4></li>
          <li><h4><strong className='bold'>Master Case Size:</strong>  3 Cases, 30 Booster Boxes</h4></li>
          <li><h4><strong className='bold'>Draftable:</strong> Every Card is a Resource(Essence)</h4></li>
          <li><h4 className='bold'>Art Styles</h4></li>
          <ul>
            <li><h4>Anime Art</h4></li>
            <li><h4>Fantasy Art</h4></li>
          </ul>
        </ul>
        <hr style={{
          borderBottom: "2px solid #efefef"
        }} />
        <img style={{ width: "100%" }} src={decksFirstEd} />
        <h2 style={{ marginLeft: "15px"}} className='bold'>Primal TCG 1st Ed. Starter Decks</h2>
        <h3 className='priceHead'>MSRP $30.00</h3>
        <ul className="last">
          <li><h4 className='bold'>Decks come in Sets of 2.</h4></li>
          <li><h4><strong className='bold'>Starter Deck Case:</strong> Brings 24 Decks, 12 of each.</h4></li>
          <li><h4 className='bold'>Each Starter Deck Contains:</h4></li>
          <ul>
            <li><h4>Exclusive Cards That Do Not Appear in the Booster Box.</h4></li>
            <li><h4>50 Main Deck Cards + 1 Starter Deck Exclusive Field Card.</h4></li>
            <li><h4>1 Super Rare Foil per Deck.</h4></li>
          </ul>
        </ul>
      </div>
    )
  }
  render() { 
    return (
      <div>
        {this.state.loading ? (
        <div className="loader-overlay">
              <CircularProgress />
            </div>) : <span />}
                <Helmet>
          <title>Primal TCG 1st Edition Business Pre-orders</title>
          <meta name="description" content="Primal TCG 1st Edition Business Pre-orders. Vendors get your exclusive deals now! Contact us for partner pricing. Releases April 2024." />
          <meta property="og:title" content="Primal TCG 1st Edition Business Pre-orders" />
          <meta property="og:description" content="Primal TCG 1st Edition Business Pre-orders. Vendors get your exclusive deals now! Contact us for partner pricing. Releases April 2024." />
          <meta property="og:image" content="https://pbs.twimg.com/media/Fz_-F5HWcAASMem?format=jpg&name=4096x4096" />
        </Helmet>
        <Navbar />
        <div className='row'>
        <CheckOrder
          open={this.state.open}
          toggleOpen={this.toggleOpen}
          data={this.state.data}
        />
          <div style={{ textAlign: "center"}} className='col-md-12'>
            <img src={main} className="mainImage" /> 
            <h1>Primal TCG 1st Edition Pre-Orders</h1>
          </div>
        </div>
        <div className='container'>
        <h4>Enter your contact info and the product you want to pre-order to be notified 
          for it's availability. <br/> Pre-orders will be receiving a Pre-order Exclusive promo.</h4>
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "15px"}}>
        <Button
                variant="contained"
                color="primary"
                styles={{
                  cursor: "pointer",
                  width: "70%",
                }}
                onClick={(e) => {
                  this.toggleOpen();
                }}
              >
                  <div style={{fontSize: "26px"}}>
                    Check My Pre-Order Status
                  </div>
              </Button>
        </div>
        {this.state.data && this.renderCountingRow()}
      <div style={{ borderBottom: "4px solid #efefef"}} className='row'>
        {this.userForm()}
        {this.productDetails()}
      </div>
      <div className="row bkg-red">
      <div style={{ textAlign: "center"}} className='col-md-12'>
            <img style={{ cursor: "pointer"}} onClick={() => {
              window.open("https://forms.gle/mDoptTcPEVmgeR2dA")
            }} src={DemoDecks} className="full-w" /> 
            <h1 onClick={() => {
              window.open("https://forms.gle/mDoptTcPEVmgeR2dA")
            }} style={{ fontWeight: "bold", backgroundColor: "#9d0108", color: "white", textDecoration: "underline", cursor: "pointer" }}>Businesses Apply For Demo Decks Today!</h1>
          </div>
      </div>
      <div className="cd-section section-white" id="contact-us">
    	<div className="contactus-1 section-image" style={{ backgroundImage: `url(${contactbkg})`}}>
    		<div className="container">
    			<div className="row">
                <div className="col-md-10 ml-auto mr-auto">
                  <div className="card card-contact no-transition">
                    <h3 className="card-title text-center">Contact Us</h3>
                    <div className="row">
                      <div className="col-md-5 ml-auto">
                        <div className="card-body">
                          <div className="info info-horizontal">
                            <div className="icon icon-info">
                              <i className="nc-icon nc-pin-3" aria-hidden="true"></i>
                            </div>
                            <div className="description">
                              <h4 className="info-title">Find us at the office</h4>
                              <p> Dattebayo Labs LLC<br />
                             4296 SW 9th Ter,<br />
                                  Miami, FL 33134
                              </p>
                            </div>
                          </div>
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="nc-icon nc-badge" aria-hidden="true"></i>
                            </div>
                            <div className="description">
                              <h4 className="info-title">Give us a ring</h4>
                              <p> Dattebayo Labs LLC<br/>
                                  +1 786 663 0320<br/>
                                  Mon - Fri, 6:00 PM-10:00 PM EST
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 mr-auto">
                        <div id="contact-form">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group label-floating">
                                  <label className="control-label">First name</label>
                                  <input onChange={(firstName) => this.setState({ firstName: firstName.target.value })} type="text" name="name" className="form-control" placeholder="First Name" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group label-floating">
                                  <label className="control-label">Last name</label>
                                  <input onChange={(lastName) => this.setState({ lastName: lastName.target.value })} type="text" name="name" className="form-control" placeholder="Last Name" />
                                </div>
                              </div>
                            </div>
                            <div className="form-group label-floating">
                              <label className="control-label">Email address</label>
                              <input onChange={(email) => this.setState({ email: email.target.value })} type="email" name="email" className="form-control" placeholder="Email"/>
                            </div>
                            <div className="form-group label-floating">
                              <label className="control-label">Your message</label>
                              <textarea onChange={(message) => this.setState({ message: message.target.value })} name="message" className="form-control" id="message" rows="6" placeholder="Message"></textarea>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="checkbox">
                                  <input id="checkbox1" type="checkbox" />
                                  <label htmlFor="checkbox1">
                                                            I'm not a robot !
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <button type="submit" onClick={() => this.sendMessage()}className="btn btn-primary pull-right">Send Message</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    			</div>
    		</div>
    	</div>
        </div>
        <Footer />
      </div>
    );
  }
}
 
export default BusinessPreorder;
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import {
  Link,
  withRouter,
} from 'react-router-dom';
import config from '../../config.json';
import AuthService from '../AuthService'; 
import apk from '../assets/primal/apk/PrimalTCG1.0.0.apk';
import install1 from '../assets/naruto/android/install1.png';
import install2 from '../assets/naruto/android/install2.png';
import bannerDesk1 from '../assets/primal/front/banners/bannerDesk1.png';
import bannerDesk2 from '../assets/primal/front/banners/bannerDesk2.png';
import bannerDesk3 from '../assets/primal/front/banners/bannerDesk3.png';
import bannerDesk4 from '../assets/primal/front/banners/bannerDesk4.png';
import bannerDesk5 from '../assets/primal/front/banners/bannerDesk5.png';
import bannerMobile1 from '../assets/primal/front/banners/bannerMobile1.png';
import bannerMobile2 from '../assets/primal/front/banners/bannerMobile2.png';
import bannerMobile3 from '../assets/primal/front/banners/bannerMobile3.png';
import primalLogo from '../assets/PrimalIcon.png';
import C0044 from '../assets/primal/front/cards/C0044.png';
import card1 from '../assets/naruto/front/card1.png';
import card2 from '../assets/naruto/front/card2.png';
import card3 from '../assets/naruto/front/card3.png';
import card4 from '../assets/naruto/front/card4.png';
import card5 from '../assets/naruto/front/card5.png';
import card6 from '../assets/naruto/front/card6.png';
import card7 from '../assets/naruto/front/card7.png';
import card8 from '../assets/naruto/front/card8.png';
import card9 from '../assets/naruto/front/card9.png';
import footer from '../assets/primal/front/PrimalFooter.png';

import install3 from '../assets/naruto/android/install3.png';
import install4 from '../assets/naruto/android/install4.png';
import install5 from '../assets/naruto/android/install5.png';
import install6 from '../assets/naruto/android/install6.png';
import narutoLogo from '../assets/narutoLogo.png';

import contactbkg from '../assets/naruto/front/contactbkg.jpg';
// import withAuth from './components/WithAuth';

// mixpanel.init('6355b9138ef99a42321be6306756111e');
class About extends Component {
  constructor() {
    super();
    this.state = {
      posts: [],
      result: null,
      query: '',
      showItems: 30,
      columnStyle: 'col-md-6',
      isAuth: false,
      cardanimate: 'anicards hide',
      uri: apk,
      firstName: '',
      lastName: '',
      email: '',
      message: '',
    };
    this.Auth = new AuthService();
  }

  componentWillMount() {

  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        cardanimate: 'anicards',
      });
    }, 500);
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod|macintosh/.test(userAgent);
    };
    if (isIos()) {
      this.setState({
        uri: 'https://apps.apple.com/us/app/card-collector-naruto-edition/id1460633152',
      });
    }
  }

  sendMessage() {
    let re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
    let mssgArr = this.state.message.split(" ");
    let verifiedEmail = re.test(this.state.email);
    if (
      this.state.message !== '' &&
      mssgArr.length > 2 &&
      this.state.firstName !== '' &&
      verifiedEmail
    ) {
      let details = {
        'message': this.state.message,
        'name': this.state.firstName + ' ' + this.state.lastName,
        'fromEmail': this.state.email,
      };
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch('https://api.narutoccg.com/api/v1.1/market/sendmail', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formBody,
      }).then((response) => response.json())
        .then((responseData) => {
          this.setState({
            message: '',
            email: '',
            firstName: '',
            lastName: '',
          });
          alert("We will email you back promptly");
        })
        .catch(err => {
          console.log(err);
        });
    }
    else {
      alert('Please fill out the fields with appropriate values');
    }
  }
  render() {
    let desktopBanners = [bannerDesk1, bannerDesk2, bannerDesk3, bannerDesk4, bannerDesk5];
    let mobileBanners = [bannerMobile1, bannerMobile2, bannerMobile3];
    let banner = desktopBanners[Math.floor(Math.random() * desktopBanners.length)];
    let mobileBanner = mobileBanners[Math.floor(Math.random() * mobileBanners.length)];
    return (
      <div className="Android">
         <Helmet>
          <title>Dattebayo Labs LLC | About</title>
          <meta name="description" content="Dattebayo Labs LLC is a game development company that focuses on software products and printed trading card games. We are currently working on Primal TCG to be the next best trading card game that can compete with Magic the Gathering and even Yu-Gi-Oh!" />
          <meta property="og:title" content="Dattebayo Labs LLC | About" />
          <meta property="og:description" content="Dattebayo Labs LLC is a game development company that focuses on software products and printed trading card games. We are currently working on Primal TCG to be the next best trading card game that can compete with Magic the Gathering and even Yu-Gi-Oh!" />
          <meta property="og:image" content="https://pbs.twimg.com/media/GJ38D7mWwAAMXsa?format=jpg&name=medium" />
        </Helmet>
        <div className="row">
          <div className="col-md-12">
            <nav className="navbar navbar-expand-md bg-dark">
              {/* <div className="container"> */}
                <a className="navbar-brand" href="/"><img style={{width: '75px', borderRadius: '100%'}} src={primalLogo} /></a>
                <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbar-menu" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                  <span className="navbar-toggler-icon"></span>
                  <span className="navbar-toggler-icon"></span>
                </button>
                
              {/* </div> */}
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
          <div className="page-header" style={{ backgroundImage: `url(${window.innerWidth > 867 ? banner : mobileBanner })`, height: "100ch"}}>
              <div style={{ opacity: 1 }}  className="filter" />
              <div className="content-center">
                <div className="container">
                  <div className="motto">
                    <h1 className="title">Dattebayo Labs LLC</h1>
                    <h2 className="subtitle">A Game Development Company</h2>
                    <h3 className="description">Primal TCG Kickstarter Release on April 2023</h3>
                    <br />                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cd-section section-white" id="contact-us">
    	<div className="contactus-1 section-image" style={{ backgroundImage: `url(${contactbkg})`}}>
    		<div className="container">
    			<div className="row">
                <div className="col-md-10 ml-auto mr-auto">
                  <div className="card card-contact no-transition">
                    <h3 className="card-title text-center">Contact Us</h3>
                    <div className="row">
                      <div className="col-md-5 ml-auto">
                        <div className="card-body">
                          <div className="info info-horizontal">
                            <div className="icon icon-info">
                              <i className="nc-icon nc-pin-3" aria-hidden="true"></i>
                            </div>
                            <div className="description">
                              <h4 className="info-title">Find us at the office</h4>
                              <p> Dattebayo Labs LLC<br />
                             4296 SW 9th Ter,<br />
                                  Miami, FL 33134
                              </p>
                            </div>
                          </div>
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="nc-icon nc-badge" aria-hidden="true"></i>
                            </div>
                            <div className="description">
                              <h4 className="info-title">Give us a ring</h4>
                              <p> Dattebayo Labs LLC<br/>
                                  +1 786 663 0320<br/>
                                  Mon - Fri, 6:00 PM-10:00 PM EST
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 mr-auto">
                        <div id="contact-form">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group label-floating">
                                  <label className="control-label">First name</label>
                                  <input onChange={(firstName) => this.setState({ firstName: firstName.target.value })} type="text" name="name" className="form-control" placeholder="First Name" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group label-floating">
                                  <label className="control-label">Last name</label>
                                  <input onChange={(lastName) => this.setState({ lastName: lastName.target.value })} type="text" name="name" className="form-control" placeholder="Last Name" />
                                </div>
                              </div>
                            </div>
                            <div className="form-group label-floating">
                              <label className="control-label">Email address</label>
                              <input onChange={(email) => this.setState({ email: email.target.value })} type="email" name="email" className="form-control" placeholder="Email"/>
                            </div>
                            <div className="form-group label-floating">
                              <label className="control-label">Your message</label>
                              <textarea onChange={(message) => this.setState({ message: message.target.value })} name="message" className="form-control" id="message" rows="6" placeholder="Message"></textarea>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="checkbox">
                                  <input id="checkbox1" type="checkbox" />
                                  <label htmlFor="checkbox1">
                                                            I'm not a robot !
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <button type="submit" onClick={() => this.sendMessage()}className="btn btn-primary pull-right">Send Message</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    			</div>
    		</div>
    	</div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div id="fadeInAnim">
              <div id="betaTest" className="section section-content">
                <div className="container">
                  <div className="row">
                    <div style={{ textAlign: "center" }} className="col-sm-12">
                      <h1 style={{ color: "black" }} className="title">Works Produced</h1>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div id="fadeInAnim">
              <div className="section section-content section-gray">
                <div className="container">
                  <div className="row">
                    <div className="col-md-7">
                      <div className="image-container">
                        <img style={{width: '100%', borderRadius: "3%"}} src={C0044} />
                      </div>
                    </div>

                    <div className="col-md-4 ">
                      <div className="section-description">
                        <h3 className="title">Primal TCG Exclusive Kickstarter Release on April 2023</h3>
                        <h6 className="category">Join our mailing list for exclusive Primal TCG News</h6>
                        <h5 className="description">Want to get your hands on our exclusive Founder's Release? Join our Newsletter giving you updates on our progress and letting you know where you can purchase Primal TCG.</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row animatedCardsSection">
          <div className="col-md-12">
            <div className="section section-cards section-white">
              <div className="codrops-header">
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="section-description">
                      <h2 className="title">Naruto CCG App</h2>
                      <h3 className="subtitle">Released on 2019</h3>
                      <h6 className="category">Over 8,000 Users and 700 Registered Decks Worldwide</h6>
                      <h5 className="description">We have gone above and beyond to give the Naruto CCG community access to all the cards from the Naruto CCG and even included the recently fanmade Set 29, A Shinobi's Dream. Connect with other community members to create the deck you've been working on and let the community know which cards you are looking for and at what price you value them.</h5>
                      <br />
                      {/* <h3 className="title">Connect with community members</h3>
                      <h6 className="category">Chat with others to grow your collection</h6>
                      <h5 className="description">We have gone above and beyond to give the community access to all the cards from the Naruto CCG and even included the recently fanmade Set 29, A Shinobi's Dream.</h5> */}
                    </div>
                  </div>
                </div>
              </div>
              <section className="section-intro ">
                <div className={`isolayer isolayer--deco1 isolayer--shadow ${this.state.cardanimate}`}>
                  <ul className="grid">
                    <li className="grid__item first-card">
                      <a className="grid__link" href={"https://app.narutoccg.com"}>

                        <img className="grid__img layer" src={card1} alt="01" />
                      </a>
                    </li>
                    <li className="grid__item second-card">
                      <a className="grid__link" href={"https://app.narutoccg.com"}>
                        <img className="grid__img layer" src={card2} alt="02" />
                      </a>
                    </li>
                    <li className="grid__item third-card">
                      <a className="grid__link" href={"https://app.narutoccg.com"}>
                        <img className="grid__img layer" src={card3} alt="03" />
                      </a>
                    </li>
                    <li className="grid__item fourth-card">
                      <a className="grid__link" href={"https://app.narutoccg.com"}>
                        <img className="grid__img layer" src={card4} alt="04" />
                      </a>
                    </li>
                    <li className="grid__item fifth-card">
                      <a className="grid__link" href={"https://app.narutoccg.com"}>
                        <img className="grid__img layer" src={card5} alt="05" />
                      </a>
                    </li>
                    <li className="grid__item sixth-card">
                      <a className="grid__link" href={"https://app.narutoccg.com"}>
                        <img className="grid__img layer" src={card6} alt="06" />
                      </a>
                    </li>
                    <li className="grid__item seventh-card">
                      <a className="grid__link" href={"https://app.narutoccg.com"}>
                        <img className="grid__img layer" src={card7} alt="07" />
                      </a>
                    </li>
                    <li className="grid__item eight-card">
                      <a className="grid__link" href={"https://app.narutoccg.com"}>
                        <img className="grid__img layer" src={card8} alt="07" />
                      </a>
                    </li>
                    <li className="grid__item ninth-card">
                      <a className="grid__link" href={"https://app.narutoccg.com"}>
                        <img className="grid__img layer" src={card9} alt="07" />
                      </a>
                    </li>
                  </ul>
                </div>
              </section>
            </div>
          </div>
        </div>
        
             </div>
    );
  }
}

export default About;

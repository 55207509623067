import React, { Component } from 'react';
import logo from "../assets/primal/branding/YellowLogo.png";
import CurrentOP from "../assets/primal/front/announcements/2024Events.png";

import "./Banner.css";
export default class SubscribeBanner extends Component {
  render() {
    return(
    <div className="page-header" style={{ backgroundImage: `url(${window.innerWidth > 867 ? this.props.banner : this.props.mobileBanner })`, height: "100ch"}}>
    <div className="filter"></div>
    <div className="content-center">
        <div className="container">
            <div className="row">
                <div className="col-md-5">
                    <div className="iframe-container">
                    <iframe width="560" height="225" src="https://www.youtube.com/embed/KcCk4-VqKAY?si=D5qzpXG5BvojAy5c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>                    </div>
                </div>
                <div className="col-md-6  ml-auto">
                <div id="mc_embed_signup">
                  <form action="https://primaltcg.us2.list-manage.com/subscribe/post?u=f8a99f8235482ea55ec3b5663&amp;id=c2d9748f6c" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
                  {/* <img id="logoBanner" alt="Primal Trading Card Game Logo" src={logo} /> */}
                      <div id="mc_embed_signup_scroll">
                    <h5 className='text-center'>The World's Most Strategic Trading Card Game! <br /><br /> Subscribe To Get A Pre-Release Promo!</h5>
                  <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
                  <div className="mc-field-group">
                    <label for="mce-EMAIL">Email Address  <span className="asterisk">*</span>
                  </label>
                    <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" />
                  </div>
                  <div className="indicates-required">You will be emailed for your address to send you the promotional card</div>
                  <div className="indicates-required">Only valid for USA and Canada addresses</div>
                  <div hidden="true"><input type="hidden" name="tags" value="1102228,1102232" /></div>
                    <div id="mce-responses" className="clear foot">
                      <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
                      <div className="response" id="mce-success-response" style={{ display: "none" }}></div>
                    </div>   
                      <div style={{ position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_f8a99f8235482ea55ec3b5663_c2d9748f6c" tabindex="-1" value="" /></div>
                          <div className="optionalParent">
                              <div className="clear foot">
                                  <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" style={{ backgroundColor: "#ffba05", color: "black" }} className="dlbtn btn btn-neutral btn-round" />
                                  {/* <p className="brandingLogo"><a href="http://eepurl.com/hXviSj" title="Mailchimp - email marketing made easy and fun"><img id="brandingLogo" src={logo} /></a></p> */}
                              </div>
                          </div>
                      </div>
                  </form>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>);
  }
}
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Demo from '../../components/assets/primal/front/Demo.png';
import AmbassadorProgram from '../../components/assets/primal/front/announcements/AmbassadorProgram.png';
import OPSchedule from '../../components/assets/primal/front/OPSchedule.png';
import BoxTop from '../../components/assets/primal/front/boxTopper.JPG';
import PrereleaseSzn from '../../components/assets/primal/front/PrereleaseSzn.png';
import meSM from '../../components/assets/primal/front/meSM.png';
import SetImage from "../../components/assets/primal/front/APLogo.png"

export default class Prerelease extends Component {
  render() {
    return (
      <div className="PreRelease">
        <Helmet>
          <title>Pre-Release Suggestions</title>
          <meta name="description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:title" content="Primal Trading Card Game (TCG) | Awakened Primordials 1st Ed Release April 2024" />
          <meta property="og:description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:image" content="https://pbs.twimg.com/media/GJ38D7mWwAAMXsa?format=jpg&name=medium" />
        </Helmet>
        <Navbar />
        <div className="page-header" data-parallax="true" style={{backgroundImage: `url(${BoxTop})`}}>
		<div className="filter"></div>
		<div className="content-center">
			<div className="motto">
      <img style={{width: "30%"}} alt="Primal Trading Card Game First Set" src={SetImage} />
				<h1 className="title-uppercase text-center">Pre-Release Events</h1>
				<h3 className="text-center">April 15-19 at Participating Game Shops</h3>
				<br/>
				{/* <a href="https://forms.gle/vQsvm8yU78EGQbqd8" className="btn btn-warning btn-round btn-lg">
					<i className="fa fa-pencil-square" aria-hidden="true"></i> Find Event
				</a> */}
			</div>
		</div>
	</div>

    <div className="wrapper">
        <div className="main">
            <div className="section section-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 ml-auto mr-auto text-center title">
                            <h2>Running your Primal Pre-Release Event</h2>
                            <h3 className="title-uppercase"><small>Written by TCG Players for TCG Players</small></h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10 ml-auto mr-auto">
                            <div className="text-center">
                                <span className="label label-info main-tag">Retailers</span>
                                <a href="javascrip: void(0);"><h3 className="title">Game Shops That Pre-Ordered Set 1 Will Receive Their Products First</h3></a>
                                <h6 className="title-uppercase">March 29, 2024</h6>
                            </div>
                        </div>
                        <div className="col-md-8 ml-auto mr-auto">
                        <img style={{ width: "100%"}} src={PrereleaseSzn} />
                            <div className="article-content">
                                <h4>Pre-Release Events Suggestions</h4>
                                <p>
                                Since the following game shops are receiving their product first before the official release date of April 20, 2024. We suggest running a pre-release event to let your community of Primal TCG players get product before the release date.
                                </p>

                                <p>
                                The following are suggestions on how you could run a pre-release event at your shop. We understand that once you receive the product it is yours and you can run an event however you desire but we suggest the following guidelines.
                                </p>
                                <blockquote className="blockquote">
                                  <ul style={{textAlign: "left"}}>
                                    <li>Market the pre-release event at least a week before pre-release week to get a gauge on how many packs to prepare for your customers.</li>
                                    <li>Create an account on our Primal Play Network as an event organizer. Our team can help you set this up if you are having any issues.</li>
                                    <li>Create a pre-release event on the Primal Play Network.</li>
                                    <li>Crack open 1 Booster Box for every 4 participants</li>
                                    <li>Players pay $30 entry fee</li>
                                    <li>Distribute 6 packs per participating player</li>
                                    <li>Players create a 40 card deck & a 15 card sideboard (either no field card, proxy field cards, or all players must share unused field cards)</li>
                                    <li>Play a tournament (8 battle rewards instead of 10); BO1 or BO3 depending on your time frame</li>
                                    <li>Give out prizing (You can use box toppers or tournament packs)</li>
                                  </ul>
                                  <footer>- Suggestions for a Pre-Release Event <cite title="Source Title">Primal TCG Team</cite></footer>
                                </blockquote>
                                </div>
							<br/>
                            <div className="article-footer">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h5>Tags:</h5>
                                            <span className="label label-danger">Retailers</span>
                                            <span className="label label-danger">Pre-Release</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="container">
                                <div className="row">
                                    <div className="media">
                                        <a className="pull-left" href="#paper-kit">
                                            <div className="avatar big-avatar">
                                                <img className="media-object" alt="64x64" src={meSM}/>
                                            </div>
                                        </a>
                                        <div className="media-body">
                                            <h4 className="media-heading">Claudio Atilano</h4>
                                            <div className="pull-right">
                                                <a href="https://instagram.com/primal.tcg" className="btn btn-warning btn-round "> <i className="fa fa-reply"></i> Follow</a>
                                            </div>
                                            <p>Hello guys, nice to have you on Primal TCG! There will be a lot of great stuff coming soon. We will keep you posted for the latest news.</p>
                                            <p> Don't forget to pick up the latest set!</p>

                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="row">
    					<div className="related-articles">
    	                    <h3 className="title">Related articles</h3>
    	                    <legend></legend>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <a href="/ambassador"><img src={AmbassadorProgram} alt="Rounded Image" className="img-rounded img-responsive"/></a>
                                        <p className="blog-title">Ambassador Program</p>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="/optour"><img src={OPSchedule} alt="Rounded Image" className="img-rounded img-responsive"/></a>
                                        <p className="blog-title">Organized Play & Releases</p>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="https://forms.gle/dz3f4pLYna9CGswb6"><img src={Demo} alt="Rounded Image" className="img-rounded img-responsive"/></a>
                                        <p className="blog-title">Retailers! Get Free Demo Decks</p>
                                    </div>
                                </div>
                            </div>
                    	</div>
    				</div>
                </div>
            </div>
        </div>
    </div>
      <Footer />
      </div>
    );
  }
}
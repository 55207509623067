import React, { Component } from 'react';
import constants from "../Constants/constants";
import "./Listing.css";
/**
 * This component is a card that appears on lists and is mapped on to a row
 */
export default class MappedCard extends Component {
  toIndividualCardView() {
    if (this.props.artist) {
      // Component used in ArtistList, if image selected send to artist view
      this.props.history.push(`/artist/${this.props.artist}`)
    } else {
      localStorage.setItem("card", JSON.stringify(this.props.card));
      this.props.history.push(`/card/${this.props.card._id}`)
    }
  }
  render() {
    return (
      <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
        {this.props.artist ? <h3>{this.props.artist}</h3> : <div />}
        <img
          src={`${this.props.env === "Dev" ? "https://dev.primaltcg.com/" : "https://api.primaltcg.com/"}${this.props.card.image}`}
          className="mappedCard"
          onClick={() => {
            if (this.props.view !== "Dev") {
              this.toIndividualCardView()
            } else {
              this.props.handleClick(this.props.card);
            }
          }}
        />
      </div>
    );
  }
}
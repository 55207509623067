import React, { Component } from 'react';
import logo from "../assets/primal/branding/YellowLogo.png";
import SetImage from "../assets/primal/front/APLogo.png"
import Set2Image from "../assets/primal/front/BBLogo.png"
import "./Banner.css";
export default class Banner extends Component {
  render() {
    return (
      <div className="row">
          <div className="col-md-12 col-xs-12">
            <div className="page-header" 
              style={{ backgroundImage: `url(${window.innerWidth > 867 ? this.props.banner : this.props.mobileBanner })`, height: "100ch"}}>
               <div className="filter" /> 
              <div className="content-center">
                <div className="container">
                  <div className="motto">
                    {/* <img id="logoBanner" alt="Primal Trading Card Game Logo" src={logo} />
                    <h3 className="subtitle">First Ed Booster Set</h3> 
                    <img id="logoBanner" alt="Awakened Primordials Logo" src={SetImage} />
                    <h4 className="description">Release Date: April 20, 2024</h4>
                    <br />
                    <a href="/salesheet" style={{ backgroundColor: "black", color: "white" }} className="dlbtn btn btn-neutral btn-round">View Sales Sheet</a>
                    <a href="/search/results/custom=all" style={{ backgroundColor: "white", color: "black" }} className="dlbtn btn btn-neutral btn-round">View All Cards</a>

                    <div onClick={() => {window.open("https://shop.primaltcg.com")}}  style={{ backgroundColor: "green", color: "white", cursor:"pointer" }} className="dlbtn btn btn-neutral btn-round">Buy Primal TCG</div> */}
                    
                    <img id="logoBanner" alt="Battlers of Bethesda Logo" src={Set2Image} />
                    <h3 className="subtitle">Available Now</h3> 
                    <br />
                    <br />
                    <a href="https://shop.primaltcg.com" style={{ backgroundColor: "white", color: "black" }} className="dlbtn btn btn-neutral btn-round">Order Set 2</a>
                    <a href="#productLine" style={{ backgroundColor: "black", color: "white" }} className="dlbtn btn btn-neutral btn-round">Available Products</a>
                    {/* <a href="/search/results/custom=cayne-pirates" style={{ backgroundColor: "#27acc1", color: "white" }} className="dlbtn btn btn-neutral btn-round">Cayne Pirates</a>
                    <a href="/search/results/custom=draginked" style={{ backgroundColor: "purple", color: "white" }} className="dlbtn btn btn-neutral btn-round">Draginked</a>
                    <a href="/search/results/custom=micromons" style={{ backgroundColor: "#ab9000", color: "white" }} className="dlbtn btn btn-neutral btn-round">Micromons</a>
                    <a href="/search/results/custom=symbiotes" style={{ backgroundColor: "#cd2600", color: "white" }} className="dlbtn btn btn-neutral btn-round">Symbiote Dragons</a>
                    <a href="/search/results/custom=pleaguis" style={{ backgroundColor: "black", color: "white" }} className="dlbtn btn btn-neutral btn-round">Pleaguis</a> */}
                    {/* <a href="https://apps.apple.com/us/app/card-collector-naruto-edition/id1460633152" className="dlbtn btn btn-neutral btn-round"><i className="fa fa-apple" aria-hidden="true"/>iPhone</a>
                    <a href="/android" className="dlbtn btn btn-success btn-round"><i className="fa fa-android" aria-hidden="true"/>Download Android APK</a> */}
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
    );
  }
}
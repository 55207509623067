import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Papa from "papaparse";
import main from "../assets/primal/preorder/bethesda/Main.png";
import promo from "../assets/primal/preorder/bethesda/BBPreOrderPromo.png";
import breakdown from "../assets/primal/preorder/bethesda/BBPreOrderBreakdown.png";
import section from "../assets/primal/preorder/bethesda/Section.png"
import boosterbox from "../assets/primal/preorder/bethesda/BBBox.png"
import bbcase from "../assets/primal/preorder/bethesda/bbcase.png"
import bbmaster from "../assets/primal/preorder/bethesda/bbmaster.png"
import DemoDecks from "../assets/primal/preorder/DemoDecks.png"
import box from "../assets/primal/preorder/firstEd02.png"
import contactbkg from '../assets/naruto/front/contactbkg.jpg';
import decks from "../assets/primal/preorder/decks.png"
import _case from "../assets/primal/preorder/case.png"
import master from "../assets/primal/preorder/master.png"
import decksFirstEd from "../assets/primal/preorder/decksFirstEd.png"
import firstEdBooster from "../assets/primal/preorder/firstEdBooster.png"
import starterCase from "../assets/primal/preorder/starterCase.png"
import Navbar from "../Navbar/Navbar";
import { Tabs, Tab, AppBar, FormControl, TextField, Box, Typography, Button, CircularProgress } from "@material-ui/core";
import CheckOrder from './CheckOrder';
import Footer from "../Footer/Footer";

var submitUrl = "https://script.google.com/macros/s/AKfycbwYRlknINN3UrHUpfoyxVQsnNFioOaLpdxOK0CuNXdrGscoLLY8kMth56jHHJxSrmHH/exec";

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class IndividualPreorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEvent: null,
      data: null,
      businesses: [],
      individuals: [],
      boxesOrdered: null,
      decksOrdered: null,
      type: 0,
      name: "",
      businessName: "",
      email: "",
      phone: "",
      address: "",
      boxes: 0,
      starters: 0,
      box_cases: 0,
      master_cases: 0,
      starter_cases: 0,
      loading: true,
      open: false
    } 
    this.formRef = React.createRef(null);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
  }
  componentDidMount() {
    this.getData();
  }
  getData() {
    let that = this;
    Papa.parse('https://docs.google.com/spreadsheets/d/1Lc-s1wjkWpGmkZkuRR5IhLJaSPxLISEyMf0dVxG7YEM/pub?output=csv', {
          download: true,
          header: true,
          complete: function(results) {
            var data = results.data
            let businesses = [];
            let individuals = [];
            let boxesOrdered = 0;
            let decksOrdered = 0;
            data.forEach(row => { 
              if (row.type.includes("business")) {
                businesses.push(row);
              }
              if (row.type.includes("individual")) {
                individuals.push(row);
              }
              let boxes = parseInt(row.boxes);
              let boxCases = parseInt(row.box_cases);
              let masterCases = parseInt(row.master_cases);
              if (isNaN(boxes)) {
                boxes = 0;
              }
              if (isNaN(boxCases)) {
                boxCases = 0;
              }
              if (isNaN(masterCases)) {
                masterCases = 0;
              }
              boxes+= boxCases*10;
              boxes+= masterCases*30;
              boxesOrdered+= boxes;

              let starters = parseInt(row.starters);
              let starterCases = parseInt(row.starter_cases);
              if (isNaN(starters)) {
                starters = 0;
              }
              if (isNaN(starterCases)) {
                starterCases = 0;
              }
              console.log("STARTERS FOR ROW", {row: row, starters: starters});
              starters = starters * 2;
              starters += starterCases * 24;
              decksOrdered += starters;
            });
            that.setState({
              data: data,
              businesses: businesses,
              individuals: individuals,
              boxesOrdered: boxesOrdered,
              decksOrdered: decksOrdered,
              loading: false
            }, () => {
              console.log("DATA", data);
            })
          }
        });
  }
  toggleOpen() {
    this.setState({
      open: !this.state.open
    });
  }
    /**
   * Renders TextInput with FromControl
   *
   * @param {string} id
   * @param {string} label
   * @param {string} value
   * @param {any} width Can be int or string, controls the width of the form control
   * @returns {React$Element<FormControl>}
   * @memberof NewShipmentComponent
   */
    renderTextInput(
      id: string,
      label: any,
      value: string,
      width: any,
      type: any,
      inputProps: Object,
      disabled: boolean = false,
      error: boolean = false
    ): React$Element<FormControl> {
      return (
        <FormControl style={{ width: width }}>
          <TextField
            error={error}
            variant="outlined"
            id={id}
            type={type}
            label={label}
            value={value || ""}
            disabled={disabled || this.state.disabled}
            InputProps={inputProps}
            autoComplete="nope"
            onChange={(inputValue: Object) => {
              this.setState({
                [id]: inputValue.target.value
              })
            }}
          />
        </FormControl>
      );
    }
    handleSubmit(e) {
      e.preventDefault()
      const { name, email, phone, address, boxes, starters, box_cases, starter_cases, master_cases } = this.state; 
      
      if (
        email
      ) {
        let message = `
        PRIMAL TCG BATTLERS OF BETHESDA PRE-ORDER REQUESTED
        EMAIL: ${email}\n
        PHONE: ${phone}\n
        NAME: ${name}\n
        BUSINESS: ${this.state.businessName}\n
        ADDRESS: ${address}\n
        BOXES: ${boxes}\n
        STARTERS: ${starters}\n
        BOX CASE: ${box_cases}\n
        STARTER CASE: ${starter_cases}\n
        BOOSTER MASTER CASE: ${master_cases}\n
        `;
        let details = {
          'message': message,
          'name': this.state.businessName + ' ' + this.state.name,
          'fromEmail': this.state.email,
        };
        let formBody = [];
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        fetch('https://api.narutoccg.com/api/v1.1/market/sendmail', {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: formBody,
        }).then((response) => response.json())
          .then((responseData) => {
            this.props.history.push("/order-confirmation");
            alert("Your Order has been submitted!")
          })
          .catch(err => {
            console.log(err);
          });
      }
      else {
        alert('Please fill out the fields with appropriate values');
      }
    }
  setUserType(value) {
    this.setState({
      type: value
    });
  }
  userForm() {
    return (
    <div className='col-md-6 col-sm-6 col-xs-12 user-form'>
      <div style={{ padding: "5%"}} className="row">
      <AppBar position="static" color="default">
              <Tabs
                value={this.state.type}
                onChange={(e, value) => this.setUserType(value)}
                indicatorColor="primary"
                textColor="primary"
                centered={true}
                variant="fullWidth"
              >
                <Tab
                  label={"Individual"}
                  disabled={false}
                  id="individual"
                />
                <Tab
                  label={"Business"}
                  disabled={false}
                  id="business"
                />
              </Tabs>
            </AppBar>
            <TabPanel className="col-xs-12" value={this.state.type} index={0}>
        {this.renderIndividualForm()}
      </TabPanel>
      <TabPanel className="col-xs-12" value={this.state.type} index={1}>
      {this.renderBusinessForm()}
      </TabPanel>
      </div>
  </div>)
  }
  renderIndividualForm() {
    return (
      <div>
        <h2>Pre-Order Product</h2>
        <div style={{ marginBottom: "15px", marginTop: "5px"}} className='row'>
          <div className='pop-section col-xs-12 col-sm-5 col-md-5 col-lg-5 offset-md-1'>
            <img src={boosterbox} style={{width: "100%"}} />
            <h3 className='priceBoxes'>$110.00</h3>
          <ul>
          <li><p className='bold'>1 Booster Box</p></li>
          <li><p className='bold'>24 Booster Packs, 12 Cards per Pack, 2 Foil Cards per Pack</p></li>
          </ul>
          <Button
                variant="contained"
                color="primary"
                styles={{
                  cursor: "pointer",
                  width: "100%"
                }}
                fullWidth={true}
                onClick={(e) => {
                  window.open('https://shop.primaltcg.com/products/primal-tcg-battlers-of-bethesda-booster-box')
                }}
              >
                  <div style={{fontSize: "16px"}}>
                    Pre-Order
                  </div>
              </Button>
          </div>
          <div className='pop-section col-xs-12 col-sm-5 col-md-5 col-lg-5 offset-md-1'>
          <img src={bbcase} style={{width: "100%"}} />
          <h3 className='priceBoxes'>$800.00</h3>
          <ul>
          <li><p className='bold'>10 Booster Boxes</p></li>
          <li><p className='bold'>MSRP $110.00</p></li>
          <li><p className='bold'>240 Booster Packs, 12 Cards per Pack, 2 Foil Cards per Pack</p></li>
          <li><p className='bold'>Averages 3 Legendary Rares</p></li>
          </ul>
          <Button
                variant="contained"
                color="primary"
                styles={{
                  cursor: "pointer",
                  width: "100%"
                }}
                fullWidth={true}
                onClick={(e) => {
                  window.open('https://shop.primaltcg.com/products/primal-tcg-battlers-of-bethesda-booster-box-case-pre-order')
                }}
              >
                  <div style={{fontSize: "16px"}}>
                    Pre-Order
                  </div>
              </Button>
          </div>
        </div>
      </div>
    );
  }
  renderBusinessForm() {
    return (
      <div>
        <h2>Contact Information</h2>
        <p>Fill out all of the information to pre-order.</p>
        <div style={{marginTop: "5px"}} className='row'>
        <div className='col-sm-6 col-md-6 col-lg-6 inputRow'>
          {this.renderTextInput("name","Full Name",this.state.name, "100%")} 
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 inputRow'>
          {this.renderTextInput("businessName","Business Name",this.state.businessName, "100%")} 
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 inputRow'>
          {this.renderTextInput("email","Email",this.state.email, "100%")} 
          </div>
        </div>
        <div style={{ marginBottom: "15px"}} className='row'>
        <div className='col-sm-6 col-md-6 col-lg-6 inputRow'>
        <FormControl style={{ width: "100%" }}>
          <TextField
            variant="outlined"
            label={"Address"}
            value={this.state.address || ""}
            autoComplete="nope"
            multiline
            rows={4}
            onChange={(inputValue: Object) => {
              this.setState({
                address: inputValue.target.value
              })
            }}
          />
        </FormControl>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 inputRow'>
          {this.renderTextInput("phone","Phone Number",this.state.phone, "100%")} 
          </div>
        </div>
        <h2>Pre-Order Product</h2>
        <p><strong>Enter desired quantities per product.</strong></p>
        <h5><strong>Allocated Pre-Orders close August 25, 2024.</strong></h5>
        <h5><strong>You will receive a payment request via email by the end of the day.</strong></h5>
        <h5><strong>Your payment must be completed by August 31, 2024 if not your pre-order will be cancelled.</strong></h5>
        <br />
        <br />
        <div style={{ marginBottom: "15px", marginTop: "5px"}} className='row'>
        <div className='pop-section col-xs-12 col-sm-5 col-md-5 col-lg-5 offset-md-1'>
          <img src={bbcase} style={{width: "100%"}} />
          <h3 className='priceBoxes'>$600.00</h3>
          <ul>
          <li><p className='bold'>10 Booster Boxes</p></li>
          <li><p className='bold'>MSRP $110.00</p></li>
          <li><p className='bold'>240 Booster Packs, 12 Cards per Pack, 2 Foil Card per Pack</p></li>
          <li><p className='bold'>Averages 3 Legendary Rares</p></li>
          </ul>
          {this.renderTextInput("box_cases","Battlers of Bethesda Booster Cases",this.state.box_cases, "100%", "number")} 
          </div>
          <div className='pop-section col-xs-12 col-sm-5 col-md-5 col-lg-5 offset-md-1'>
          <img src={bbmaster} style={{width: "100%"}} />
          <h3 className='priceBoxes'>$1,600.00</h3>
          <ul>
          <li><p className='bold'>30 Booster Boxes</p></li>
          <li><p className='bold'>MSRP $110.00</p></li>
          <li><p className='bold'>720 Booster Packs, 12 Cards per Pack, 2 Foil Card per Pack</p></li>
          <li><p className='bold'>Averages 10 Legendary Rares</p></li>
          </ul>
          {this.renderTextInput("master_cases","Battlers of Bethesda Master Cases",this.state.master_cases, "100%", "number")} 
          </div>
        </div>
        <div style={{ marginBottom: "15px", marginTop: "5px"}} className='row'>
        
        
          <div className='col-md-12'>
          <Button
                variant="contained"
                color="primary"
                styles={{
                  cursor: "pointer",
                  width: "100%"
                }}
                fullWidth={true}
                onClick={(e) => {
                  this.handleSubmit(e)
                }}
              >
                  <div style={{fontSize: "26px"}}>
                    Submit Pre-Order
                  </div>
              </Button>
        </div>

        </div>
      </div>
    );
  }
  renderCountingRow() {
    return(
      <div style={{
        display: "flex",
        justifyContent: "space-around",
        marginTop: "20px",
        marginLeft: "0px",
        marginRight: "0px",
        paddingBottom: "25px"
      }} className="bkg-red full-w row">
          <div className='col-md-2 text-white text-centered'>
            <h2 style={{ fontWeight: "bold"}}>{this.state.businesses.length}</h2>
            <h4>Business Pre-Orders</h4>
          </div>
          <div className='col-md-2 text-white text-centered'>
          <h2 style={{ fontWeight: "bold"}}>{this.state.individuals.length}</h2>
            <h4>Individual Pre-Orders</h4>
          </div>
          <div className='col-md-2 text-white text-centered'>
          <h2 style={{ fontWeight: "bold"}}>{this.state.boxesOrdered}</h2>
            <h4>Booster Boxes Pre-Ordered</h4>
          </div>
          <div className='col-md-2 text-white text-centered'>
          <h2 style={{ fontWeight: "bold"}}>{this.state.decksOrdered}</h2>
            <h4>Starter Decks Pre-Ordered</h4>
          </div>
        </div>
    );
  }
  productDetails() {
    return (
      <div className='col-md-6 col-sm-6 col-xs-12'>
        <h1 style={{ marginLeft: "15px"}}>Product Details</h1>
        <img style={{ width: "100%" }} src={boosterbox} />
        <h2 style={{ marginLeft: "15px"}} className='bold'>Primal TCG Battlers of Bethesda Booster Box</h2>
        <h3 className='priceHead'>MSRP $110.00</h3>
        <ul>
          <li><h4 className='bold'>193 Card Base Set</h4></li>
          <li><h4 className='bold'>24 Booster Packs, 12 Cards per Pack, 2 Foil Card per Pack</h4></li>
          <li><h4 className='bold'>14 Different Full Art Super Rare Box Topper Exclusives!</h4></li>
          <li><h4 className='bold'>4 Different Legendary Rare Cards!</h4></li>
          <ul>
          <li><h4>1 out of 240 Packs (1 Average in Case)</h4></li>
            <li><h4>1 out of 240 Packs (1 Average in Case)</h4></li>
            <li><h4>1 out of 240 Packs (1 Average in Case)</h4></li>
            <li><h4>1 out of 720 Packs (1 Average in Master Case)</h4></li>
          </ul>
          <li><h4><strong className='bold'>Case Size:</strong> 10 Booster Boxes</h4></li>
          <li><h4><strong className='bold'>Master Case Size:</strong>  3 Cases, 30 Booster Boxes</h4></li>
          <li><h4><strong className='bold'>Draftable:</strong> Every Card is a Resource(Essence)</h4></li>
          <li><h4 className='bold'>Art Styles</h4></li>
          <ul>
            <li><h4>Anime Art</h4></li>
            <li><h4>Fantasy Art</h4></li>
          </ul>
        </ul>
        
      </div>
    )
  }
  render() { 
    return (
      <div>
        {this.state.loading ? (
        <div className="loader-overlay">
              <CircularProgress />
            </div>) : <span />}
                <Helmet>
                <title>Primal TCG Battlers of Bethesda Pre-orders</title>
          <meta name="description" content="Primal TCG Battlers of Bethesda Pre-Order. Order now for an exclusive pre-order promo! Allocated Pre-Orders Finish August 25 2024. Releases November 2024." />
          <meta property="og:title" content="Primal TCG Battlers of Bethesda Pre-orders" />
          <meta property="og:description" content="Primal TCG Battlers of Bethesda Pre-Order. Order now for an exclusive pre-order promo! Allocated Pre-Orders Finish August 25 2024. Releases November 2024." />
          <meta property="og:image" content="https://pbs.twimg.com/media/GTqkZEHWYAEgIR0?format=jpg&name=large" />
        </Helmet>
        <Navbar />
        <div className='row'>
        <CheckOrder
          open={this.state.open}
          toggleOpen={this.toggleOpen}
          data={this.state.data}
        />
          <div style={{ textAlign: "center"}} className='col-md-12'>
            <img src={main} style={{width: "100%"}} /> 
            <h1>Primal TCG Set 2, Battlers of Bethesda, Pre-Orders</h1>
          </div>
        </div>
        <div className='container'>
        <h4>We will be printing set 2 boxes based on our pre-orders we will allocate a certain amount towards prizing but this print run will be based on the demand of the Battlers of Bethesda booster box.  <br/><br/> Pre-orders will be receiving a Pre-order Exclusive promo. <br />Game shops that pre-order will be getting a pre-order promo per box ordered.
        
        <br />
        <br />
        <br />
        Expected Arrival: November 9, 2024
        </h4>
        
        </div>
        <div style={{ borderBottom: "4px solid #efefef"}} className='row'>
          <img src={section} style={{ width: "100%"}} />
        </div>
      <div style={{ borderBottom: "4px solid #efefef"}} className='row'>
      <div className='col-md-6'><img style={{ width: "100%" }} src={promo} /></div>
        <div className='col-md-6'><img style={{ width: "100%" }} src={breakdown} /></div>
        {this.userForm()}
        {this.productDetails()}
      </div>
      <div className="row bkg-red">
      <div style={{ textAlign: "center", marginTop: "15px", marginBottom: "15px"}} className='col-md-12'>
      <Button
                variant="contained"
                color="primary"
                styles={{
                  cursor: "pointer",
                  width: "50%",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
                onClick={(e) => {
                  window.open('https://shop.primaltcg.com');
                }}
              >
                  <div style={{fontSize: "26px"}}>
                    View Current Available Products
                  </div>
              </Button>
          </div>
      </div>
      <div className="cd-section section-white" id="contact-us">
    	<div className="contactus-1 section-image" style={{ backgroundImage: `url(${contactbkg})`}}>
    		<div className="container">
    			<div className="row">
                <div className="col-md-10 ml-auto mr-auto">
                  <div className="card card-contact no-transition">
                    <h3 className="card-title text-center">Contact Us</h3>
                    <div className="row">
                      <div className="col-md-5 ml-auto">
                        <div className="card-body">
                          <div className="info info-horizontal">
                            <div className="icon icon-info">
                              <i className="nc-icon nc-pin-3" aria-hidden="true"></i>
                            </div>
                            <div className="description">
                              <h4 className="info-title">Find us at the office</h4>
                              <p> Dattebayo Labs LLC<br />
                             4296 SW 9th Ter,<br />
                                  Miami, FL 33134
                              </p>
                            </div>
                          </div>
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="nc-icon nc-badge" aria-hidden="true"></i>
                            </div>
                            <div className="description">
                              <h4 className="info-title">Give us a ring</h4>
                              <p> Dattebayo Labs LLC<br/>
                                  +1 786 663 0320<br/>
                                  Mon - Fri, 6:00 PM-10:00 PM EST
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 mr-auto">
                        <div id="contact-form">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group label-floating">
                                  <label className="control-label">First name</label>
                                  <input onChange={(firstName) => this.setState({ firstName: firstName.target.value })} type="text" name="name" className="form-control" placeholder="First Name" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group label-floating">
                                  <label className="control-label">Last name</label>
                                  <input onChange={(lastName) => this.setState({ lastName: lastName.target.value })} type="text" name="name" className="form-control" placeholder="Last Name" />
                                </div>
                              </div>
                            </div>
                            <div className="form-group label-floating">
                              <label className="control-label">Email address</label>
                              <input onChange={(email) => this.setState({ email: email.target.value })} type="email" name="email" className="form-control" placeholder="Email"/>
                            </div>
                            <div className="form-group label-floating">
                              <label className="control-label">Your message</label>
                              <textarea onChange={(message) => this.setState({ message: message.target.value })} name="message" className="form-control" id="message" rows="6" placeholder="Message"></textarea>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="checkbox">
                                  <input id="checkbox1" type="checkbox" />
                                  <label htmlFor="checkbox1">
                                                            I'm not a robot !
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <button type="submit" onClick={() => this.sendMessage()}className="btn btn-primary pull-right">Send Message</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    			</div>
    		</div>
    	</div>
        </div>
        <Footer />
      </div>
    );
  }
}
 
export default IndividualPreorder;
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Demo from '../../components/assets/primal/front/Demo.png';
import AmbassadorProgram from '../../components/assets/primal/front/announcements/AmbassadorProgram.png';
import OPSchedule from '../../components/assets/primal/front/OPSchedule.png';
import AmbassadorBadge from '../../components/assets/primal/front/announcements/Ambassador.png';
import PrereleaseSzn from '../../components/assets/primal/front/PrereleaseSzn.png';
import meSM from '../../components/assets/primal/front/meSM.png';

export default class Ambassador extends Component {
  render() {
    return (
      <div className="Ambassador">
        <Helmet>
          <title>Ambassador Program</title>
          <meta name="description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:title" content="Primal Trading Card Game (TCG) | Awakened Primordials 1st Ed Release April 2024" />
          <meta property="og:description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:image" content="https://pbs.twimg.com/media/GJ38D7mWwAAMXsa?format=jpg&name=medium" />
        </Helmet>
        <Navbar />
        <div className="page-header" data-parallax="true" style={{backgroundImage: `url(${AmbassadorProgram})`}}>
		<div className="filter"></div>
		<div className="content-center">
			<div className="motto">
      <img style={{width: "30%"}} alt="Primal Trading Card Game Ambassador Logo" src={AmbassadorBadge} />
				<h1 className="title-uppercase text-center">Ambassador Program</h1>
				<h3 className="text-center">Help Bring Primal TCG to Your Local Game Shop</h3>
				<br/>
				<a href="https://forms.gle/vQsvm8yU78EGQbqd8" className="btn btn-warning btn-round btn-lg">
					<i className="fa fa-pencil-square" aria-hidden="true"></i> Apply Today
				</a>
			</div>
		</div>
	</div>

    <div className="wrapper">
        <div className="main">
            <div className="section section-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 ml-auto mr-auto text-center title">
                            <h2>Empowering the Community: The Primal TCG Ambassador Program</h2>
                            <h3 className="title-uppercase"><small>Written by TCG Players for TCG Players</small></h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10 ml-auto mr-auto">
                            <div className="text-center">
                                <span className="label label-warning main-tag">Community</span>
                                <a href="javascrip: void(0);"><h3 className="title">Become a Primal TCG Ambassador Today!</h3></a>
                                <h6 className="title-uppercase">March 28, 2024</h6>
                            </div>
                        </div>
                        <div className="col-md-8 ml-auto mr-auto">
                        <img style={{ width: "100%"}} src={AmbassadorProgram} />
                            <div className="article-content">
                                <h4>Community Growth</h4>
                                <p>
                                Primal Trading Card Game is thrilled to introduce our Ambassador Program, a pivotal initiative designed to magnify our presence and energize the community. 
                                </p>

                                <p>
                                At the heart of Primal TCG, we understand the power of community engagement, and our program is tailored to leverage this strength to its fullest. Through strategic player acquisition, enhancing word-of-mouth, and fostering content creation, we aim to bridge the gap between players and the game they love.
                                </p>
                                <blockquote className="blockquote">
                                  <ul style={{textAlign: "left"}}>
                                    <li>Ambassador arrive at their local game shop wearing Primal Merch (shirt or playmat) and plays with a friend.</li>
                                    <li>Some content is made, they take a photo of them playing a game or even record a game and post it on Facebook or Instagram tagging Primal TCG</li>
                                    <li>Ambassador gives out any demo product to whoever is at the event and plays Primal.</li>
                                  </ul>
                                  <footer>- Running a Successful Community Event <cite title="Source Title">Primal TCG Team</cite></footer>
                                </blockquote>
                                <p>
                                Ambassadors are the chosen champions of Primal TCG, receiving exclusive Ambassador kits every two months, filled with demo decks, promotional materials, and coveted swag.
                                </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <a href="javascrip: void(0);">
                                            <div className="card" data-radius="none" style={{backgroundImage: `url('../assets/img/sections/sebastien-gabrieles.jpg')`}}>
                                                </div> 
                                        </a>
                                    </div>
                                    <div className="col-md-6">
                                        <a href="javascrip: void(0);">
                                            <div className="card" data-radius="none" style={{backgroundImage: `url('../assets/img/sections/john-towner.jpg')`}}></div>
                                        </a>
                                    </div>
                                </div>
                                <p>

                                </p>
                                <h4>Are you an Ambassador?</h4>
                                <p>These individuals are not just players; they are the pillars of our community, guiding newcomers, and seasoned veterans alike through the intricacies of Primal TCG.</p>
                                <p>Monthly online gatherings allow Ambassadors to exchange insights, addressing game concerns and strategies to enhance player experience.</p>
                                <p>Our quest for new Ambassadors is always on. We're scouting for those who've shown dedication, whether through their presence in game shops or active engagement online. </p>
                                <p>Through a comprehensive application process, we're looking for individuals who share our vision and commitment. Our management strategy is clear: provide continuous support, track each Ambassador's impact through events and social media, and always encourage a constructive dialogue.</p>
                                <p>Primal TCG's Ambassador Program isn't just about spreading the word; it's about building a family that grows together, plays together, and thrives together. Join us in this exciting journey and become a herald of the Primal saga.</p>
                                

                                <h4><strong>Conclusions</strong></h4>
                                <p>Apply to become an Ambassador today! Ambassadors will earn:
                                </p>
                                <ul>
                                  <li>An Ambassador Kit every 2 months</li>
                                  <ul>
                                    <li>20 Demo Kits</li>
                                    <li>20 Magazine Guides</li>
                                    <li>1 Ambassador Promo (Earn more based on performance)</li>
                                    <li>Promotional Material</li>
                                    <li>Discount Coupons</li>
                                  </ul>
                                  <li>A new Playmat and Shirt every Set Release</li>
                                  <li>Community Spotlight</li>

                                </ul>
                            </div>
							<br/>
                            <div className="article-footer">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h5>Tags:</h5>
                                            <span className="label label-danger">Community</span>
                                            <span className="label label-danger">Ambassador</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="container">
                                <div className="row">
                                    <div className="media">
                                        <a className="pull-left" href="#paper-kit">
                                            <div className="avatar big-avatar">
                                                <img className="media-object" alt="64x64" src={meSM}/>
                                            </div>
                                        </a>
                                        <div className="media-body">
                                            <h4 className="media-heading">Claudio Atilano</h4>
                                            <div className="pull-right">
                                                <a href="https://instagram.com/primal.tcg" className="btn btn-warning btn-round "> <i className="fa fa-reply"></i> Follow</a>
                                            </div>
                                            <p>Hello guys, nice to have you on Primal TCG! There will be a lot of great stuff coming soon. We will keep you posted for the latest news.</p>
                                            <p> Don't forget to pick up the latest set!</p>

                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="row">
    					<div className="related-articles">
    	                    <h3 className="title">Related articles</h3>
    	                    <legend></legend>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <a href="/prerelease"><img src={PrereleaseSzn} alt="Rounded Image" className="img-rounded img-responsive"/></a>
                                        <p className="blog-title">Pre-Release Event Suggestions</p>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="/optour"><img src={OPSchedule} alt="Rounded Image" className="img-rounded img-responsive"/></a>
                                        <p className="blog-title">Organized Play & Releases</p>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="https://forms.gle/dz3f4pLYna9CGswb6"><img src={Demo} alt="Rounded Image" className="img-rounded img-responsive"/></a>
                                        <p className="blog-title">Retailers! Get Free Demo Decks</p>
                                    </div>
                                </div>
                            </div>
                    	</div>
    				</div>
                </div>
            </div>
        </div>
    </div>
      <Footer />
      </div>
    );
  }
}
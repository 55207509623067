import React, { Component } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField, Box, Typography, Button, CircularProgress } from "@material-ui/core";


export default class CheckOrder extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       open: props.open,
       email: "",
       data: props.data,
       order: null,
       error: false
    }
  }
  componentDidUpdate() {
    console.log("RENDER DIALOG COMPONENT UPDATE", this.props)
    if (this.props.open !== this.state.open) {
      this.setState({ open: this.props.open }, () => {
        console.log("UPDATED OPN STATE", this.state)
      });
    }
  }
  checkOrder() {
    const { email } = this.state;
    if (email) {
      let emailFound = false;
      let _order = null;
      let _email = email.toUpperCase();
      this.props.data.forEach(order => {
        if (order.email) {
          let orderEmail = order.email.toUpperCase();
          if (_email === orderEmail) {
            emailFound = true;
            _order = order;
            _order.boxesOrdered = parseInt(order.boxes) + parseInt(order.box_cases) * 10 + parseInt(order.master_cases) * 30;
            _order.startersOrdered = parseInt(order.starters)*2 + parseInt(order.starter_cases) * 24;
            _order.boxesCredit = parseInt(order.boxes_credit);
            _order.startersCredit = parseInt(order.starters_credit);
            _order.creditReference = order.credit_reference;
          }
        }
      });
      this.setState({
        error: !emailFound,
        order: _order
      })
    } else {
      this.setState({
        error: true
      })
    }
    
  }
  render() {
    const { order } = this.state;
    console.log("RENDER DIALOG", this.state)
    return (
      <>
        <Dialog
          open={this.state.open}
          onClose={this.props.toggleOpen}
        >
          {order ? (<DialogTitle>Pre-Order Registered!</DialogTitle>) : (<DialogTitle>Check if your pre-order was registered!</DialogTitle>)}
          {order ? (
          <DialogContent><h5>
            <strong>Name:</strong> {order.name} <br /><br />
            <strong>Booster Boxes Pre-Ordered: </strong> {order.boxesOrdered} <br /><br />
            <strong>Individual Starter Decks Pre-Ordered: </strong> {order.startersOrdered} <br /><br />
            {order.creditReference ? (<><strong>Booster Boxes Credited<i style={{ color: "blue"}}>*</i> : </strong> {order.boxesCredit} <br /><br />
            <strong>Individual Starter Decks Credited<i style={{ color: "blue"}}>*</i> : </strong> {order.startersCredit} <br /><br />
            <strong>Credit Reference: </strong> {order.creditReference} <br /><br /></>) : <div/>}
            
            </h5>
            {order.creditReference ? (<><p style={{ color: "blue" }}>*If you wish to receive the credited product you must purchase at least 1 Booster Box if you are an individual or 1 case (10 Boxes) if you are a business.</p><br /><br /> </>) : <div />}
            
            <h6 style={{ color: "blue" }}>If you believe there is something wrong with your order contact us at contact@primaltcg.com <br/><br/></h6>
            <FormControl style={{ width: "80%" }}>
          <TextField
            variant="outlined"
            error={this.state.error}
            label={"Email"}
            value={this.state.email || ""}
            onChange={(inputValue: Object) => {
              this.setState({
                email: inputValue.target.value,
                error: false
              })
            }}
          />
        </FormControl>
            </DialogContent>) : (<DialogContent><h5>
            If you are a <strong>Kickstarter Backer or purchased Omni Boxes or Starter Decks through our Primal TCG Shop or through our convention booth:</strong> <br/><br/> We are matching 1 to 1 for every Kickstarter Booster Box, Kickstarter Starter Deck and Omni Booster Box purchase you made directly from us. You just need to purchase at least one 1st Edition Box if you are an individual. <br/><br/>Enter your email to see your pre-order or how many boxes you have credited to you. If you are a business (LGS Tier Backers) you will have to purchase a case of 10 boxes to get the credited boxes.</h5>

            {this.state.error && (<h6 style={{ color: "red"}}>Looks like that email was not found. Please pre-order or make sure the email value is correct. <br/><br/> If you believe there is something wrong with your order contact us at contact@primaltcg.com <br/><br/></h6>)}
            <FormControl style={{ width: "80%" }}>
          <TextField
            variant="outlined"
            error={this.state.error}
            label={"Email"}
            value={this.state.email || ""}
            onChange={(inputValue: Object) => {
              this.setState({
                email: inputValue.target.value,
                error: false
              })
            }}
          />
        </FormControl>
            </DialogContent>)}
          
          <DialogActions>
          <Button
                variant="contained"
                color="primary"
                styles={{
                  cursor: "pointer",
                  width: "100%"
                }}
                fullWidth={true}
                onClick={(e) => {
                  this.checkOrder()
                }}
              >
                  <div style={{fontSize: "16px"}}>
                    Check Pre-Order
                  </div>
              </Button>
              <Button
                variant="contained"
                className="btn-danger"
                styles={{
                  cursor: "pointer",
                  width: "100%"
                }}
                fullWidth={true}
                onClick={(e) => {
                  this.props.toggleOpen()
                }}
              >
                  <div style={{fontSize: "16px"}}>
                    Cancel
                  </div>
              </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

import React, { Component } from 'react';
import { Tabs, Tab, AppBar, FormControl, TextField, Box, Typography, Button, CircularProgress } from "@material-ui/core";


// import upArrow from './assets/upArrow.png';

import {
  Link,
  withRouter,
} from 'react-router-dom';
import config from '../../config.json';
import './Landing.css';
import AuthService from '../AuthService';

import NinjaForm from '../NinjaForm';
import UpdateNinjaForm from '../UpdateNinjaForm';
import narutoLogo from '../assets/PrimalIcon.png';

// import withAuth from './components/WithAuth';
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
// mixpanel.init('6355b9138ef99a42321be6306756111e');
class Landing extends Component {
  constructor() {
    super();
    this.state = {
      posts: [],
      result: null,
      query: '',
      showItems: 30,
      columnStyle: 'col-md-6',
      isAuth: false,
      type: 0
    };
    this.Auth = new AuthService();
    this.handlePassword = this.handlePassword.bind(this);
  }

  componentWillMount() {

  }

  componentDidMount() {
    // mixpanel.track('Landing page');
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    // alert(isIos());
    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
    // alert(isInStandaloneMode()); works
    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      this.setState({ showInstallMessage: true });
    }
  }





  handlePassword() {
    let pass = document.getElementById('authToken').value;
    if (pass === 'Revitalize2OP') {
      this.setState({isAuth: true})
    }

  }
  setChangeType(value) {
    this.setState({
      type: value
    });
  }
  render() {
    return (
      <div className="Landing">
        <h1>Primal TCG Dev Card Portal</h1>
        
        <div className='col-md-12 col-sm-12 col-xs-12 formGroup'>
          <input onChange={this.handlePassword} id="authToken" className="form-control authForm" placeholder="Place Permission Token" />
          {this.state.isAuth == true ? 
          (<div style={{ padding: "5%"}} className="row">
          <AppBar position="static" color="default">
                  <Tabs
                    value={this.state.type}
                    onChange={(e, value) => this.setChangeType(value)}
                    indicatorColor="primary"
                    textColor="primary"
                    centered={true}
                    variant="fullWidth"
                  >
                    <Tab
                      label={"Create Card"}
                      disabled={false}
                      id="Create"
                    />
                    <Tab
                      label={"Update Card"}
                      disabled={false}
                      id="Update"
                    />
                  </Tabs>
                </AppBar>
                <TabPanel className="col-xs-12" value={this.state.type} index={0}>
                <NinjaForm />
          </TabPanel>
          <TabPanel className="col-xs-12" value={this.state.type} index={1}>
          <UpdateNinjaForm />
          </TabPanel>
          </div>)
          : <div />}
        </div>

        <div style={{ textAlign: 'center' }} className="col-xs-12 col-md-12">
          <div className="col-xs-6">
            <Link to='/'>© {new Date().getFullYear()} Dattebayo Labs LLC.</Link>
          </div>
          <div className="col-xs-6">
            <Link to='/terms'>Terms</Link>
          </div>

        </div>
      </div>
    );
  }
}

export default Landing;

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Navbar from '../Navbar/Navbar';
import Footer from "../Footer/Footer";

class Legal extends Component {
  constructor(props) {
    super();
    this.state = {

    };
  }

  render() {
    return (
      <div className="Legal">
        <Helmet>
          <title>Primal Trading Card Game App Terms and Conditions & Privacy Policy</title>
          <meta name="description" content="Terms and Conditions & Privacy Policy for Primal the Trading Card Game. A fantasy based game with legacy game mechanics compared to modern trading card games. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:title" content="Primal Trading Card Game (TCG) App Sign Up" />
          <meta property="og:description" content="Terms and Conditions & Privacy Policy for Primal the Trading Card Game. A fantasy based game with legacy game mechanics compared to modern trading card games. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:image" content="https://pbs.twimg.com/media/GJ38D7mWwAAMXsa?format=jpg&name=medium" />
        </Helmet>
        <Navbar />
        <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
        Privacy Policy
Application analytics
Dattebayo Labs apps may enable analytics (via Mixpanel) by default. Analytics may be disabled within any of our applications. Analytics are not shared and are used to determine which features are being used and what future development should focus on. All application analytics are anonymous.

Website analytics
Website analytics are provided via Mixpanel. Website analytics are used to determine visitor sources.

Updates
Some of our applications will update their APIs and connect to third party software to enhance customer experience

Email
All email addresses are stored within MailChimp. To send out a newsletter here and there

Questions and Feedback
Please email us (dattebayolabs@gmail.com) if you have any questions or concerns about our privacy policies.
        </div>
        </div>
        <Footer />
        </div>
    );
  }
}

export default Legal;

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Navbar from "../../Navbar/Navbar";
import MapListRow from '../../Listing/MapListRow';
import Footer from "../../Footer/Footer";
import "./Results.css"
import { Tabs, Tab, AppBar, FormControl, TextField, Box, Typography, Button, CircularProgress } from "@material-ui/core";


function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export default class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      loading: true,
      SecretRare: [],
      AllCards: [],
      LegendaryRare: [],
      SuperRare: [],
      Rare: [],
      Uncommon: [],
      Promo: [],
      Common: [],
      Starter: [],
      secretRareCount: "",
      legendaryRareCount: "",
      superRareCount:"",
      rareCount:"",
      uncommonCount:"",
      commonCount:"",
      promoCount:"",
      starterCount:"",
      filters: 0
    }
  }
  
  componentDidMount() {
    const urlSplit = this.props.match.url.split("/");
    let lastChar = urlSplit[urlSplit.length - 1];
    if (lastChar.includes("custom=")) {
      this.fetchPredefinedCards(lastChar.substring(7));
    } else if (lastChar.includes("deck=")) {
      this.fetchDeckByID(lastChar.substring(5));      // ObjectId("6306b90d23c7485436fe6147")
    } else {
      console.log("RECEIVED PROPS", this.props.location)
      if (this.props.location && this.props.location.state && this.props.location.state.cards && this.props.location.state.cards.length > 0) {
        this.formatSearchResults(this.props.location.state.cards);
      } else {
        this.setState({ loading: false })
      }
    }
  }
  get2dArray(arr) {
    let collection = [];
    let temp = [];
    let collectionEnd = arr.length;
    let remainder = collectionEnd % 3;
    arr.map(card => {
      temp.push(card);
      if (temp.length === 3) {
        collection.push(temp);
        temp = [];
      }
    });
    if (remainder === 2) {
      collection.push([arr[collectionEnd - 2], arr[collectionEnd - 1]]);
    }
    if (remainder === 1) {
      collection.push([arr[collectionEnd - 1]]);
    }
    return collection;
  }
  formatSearchResults(cards) {
    let superRare = [];
    let secretRare = [];
    let legendaryRare = [];
    let rare = [];
    let uncommon = [];
    let common = [];
    let promo = [];
    // let bng = [];
    // let parallel = [];
    let starter = [];
    let all = [];
    let devToken = localStorage.getItem("DEV");
    cards.map(card => {
      all.push(card);
      if (card.artist && card.artist.name !== "TBD" || devToken) {
        if (card.rarity.includes("Secret")) {
          secretRare.push(card);
        }
        if (card.rarity.includes("Legendary")) {
          legendaryRare.push(card);
        }
        if (card.rarity === 'Super Rare') {
          superRare.push(card);
        }
        // if (card.rarity === 'STSR') {
        //   superRare.push(card);
        // }
        if (card.rarity === 'Rare') {
          rare.push(card);
        }
        if (card.rarity === 'Uncommon') {
          uncommon.push(card);
        }
        if (card.rarity === 'Promo') {
          promo.push(card);
        }
        if (card.rarity === 'Common') {
          common.push(card);
        }
        // if (card.rarity === 'Parallel') {
        //   parallel.push(card);
        // }
        // if (card.rarity === 'B&G Super Rare') {
        //   bng.push(card);
        // }
        if (card.rarity === 'Starter') {
          starter.push(card);
        }
      }
    });
    let ALL = this.get2dArray(all);
    let SCR = this.get2dArray(secretRare);
    let LR = this.get2dArray(legendaryRare);
    let SR = this.get2dArray(superRare);
    let R = this.get2dArray(rare);
    let U = this.get2dArray(uncommon);
    let P = this.get2dArray(promo);
    let C = this.get2dArray(common);
    let Start = this.get2dArray(starter);
    this.setState(
      {
        cards: cards,
        AllCards: ALL,
        allCardsCount: all.length,
        SuperRare: SR,
        SecretRare: SCR,
        LegendaryRare: LR,
        Rare: R,
        Uncommon: U,
        Promo: P,
        Common: C,
        Starter: Start,
        secretRareCount: secretRare.length,
        legendaryRareCount: legendaryRare.length,
        superRareCount: superRare.length,
        rareCount: rare.length,
        uncommonCount: uncommon.length,
        commonCount: common.length,
        promoCount: promo.length,
        starterCount: starter.length,
        loading: false
      },
      () => {
        console.log("UPDATED STATE", this.state)
      },
    );
  }
  async fetchDeckByID(ID) {
    try {
      let result = await fetch(`https://api.primaltcg.com/api/v1/guest/deck/${ID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });  
      result = await result.json();
      console.log("API RESULT IS", result);
      this.formatSearchResults(result.deck);
    } catch (error) {
      alert(`There was a problem searching for Deck ID ${ID}`);
      this.props.history.push("/");
    }
  }
  async fetchPredefinedCards(type) {
    let ID = "";
    switch (type) {
      case "cayne-pirates": {
        ID = "61c12546aa40d30378f41a99";
        break;
      }
      case "draginked": {
        ID = "61c12608aa40d30378f41ad2";
        break;
      }
      case "micromons": {
        ID = "61c12281aa40d30378f41a05";
        break;
      }
      case "symbiotes": {
        ID = "61c12694aa40d30378f41af3";
        break;
      }
      case "pleaguis": {
        ID = "61c12467aa40d30378f41a4c";
        break;
      }
      default: {
        ID = "all";
        localStorage.setItem("DEV", true);
        break;
      }
    }
    if (ID && ID !== "all") {
      try {
        let result = await fetch(`https://api.primaltcg.com/api/v1/guest/deck/${ID}`, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });  
        result = await result.json();
        console.log("API RESULT IS", result);
        this.formatSearchResults(result.deck);
      } catch (error) {
        alert(`There was a problem searching for ${type}`);
        this.props.history.push("/");
      }
    } else {
      // https://api.primaltcg.com/api/v1/guest/post?search=&characteristic=&cardEffect=&rarity=Super%20Rare|Rare|Uncommon|Common|Promo&
      try {
        let result = await fetch("https://api.primaltcg.com/api/v1/guest/post?search=&characteristic=&cardEffect=&rarity=Super%20Rare|Rare|Uncommon|Common|Promo&", {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });  
        result = await result.json();
        console.log("API RESULT IS", result);
        this.formatSearchResults(result.posts);
      } catch (error) {
        console.log("ERROR", error);
        alert(`There was a problem searching for ${type}`);
        this.props.history.push("/");
      }
    }
  }
  setViewFilter(value) {
    this.setState({
      filters: value
    })
  }
  renderRarityFilter() {
    const { cards,SuperRare,LegendaryRare, Rare, Uncommon, Common, loading, Starter,SecretRare,Promo } = this.state;
    return (<>
      {cards.length === 0 && loading === false ? (<h2 style={{ color: "#9D0208" }}>No Cards Match That Search. Try Again</h2>) : (<div />)}
    {SecretRare.length > 0 ? (<h2 className="rarityTitle">Secret Rare ({this.state.secretRareCount})</h2>) : <div />}
    {SecretRare.length > 0 ? SecretRare.map((cardSet, index) => (
      <MapListRow
        key={index + "SCR"}
        cardRow={cardSet}
        history={this.props.history}
      />
      )) : <div />}
    {LegendaryRare.length > 0 ? (<h2 className="rarityTitle">Legendary Rare ({this.state.legendaryRareCount})</h2>) : <div />}
    {LegendaryRare.length > 0 ? LegendaryRare.map((cardSet, index) => (
      <MapListRow
        key={index + "LR"}
        cardRow={cardSet}
        history={this.props.history}
      />
      )) : <div />}
    {SuperRare.length > 0 ? (<h2 className="rarityTitle">Super Rare ({this.state.superRareCount})</h2>) : <div />}
    {SuperRare.length > 0 ? SuperRare.map((cardSet, index) => (
      <MapListRow
        key={index + "SR"}
        cardRow={cardSet}
        history={this.props.history}
      />
      )) : <div />}
    {Rare.length > 0 ? (<h2 className="rarityTitle">Rare ({this.state.rareCount})</h2>) : <div />}
    {Rare.length > 0 ? Rare.map((cardSet, index) => (
      <MapListRow
        key={index + "R"}
        cardRow={cardSet}
        history={this.props.history}
      />
      )) : <div />}
    {Uncommon.length > 0 ? (<h2 className="rarityTitle">Uncommon ({this.state.uncommonCount})</h2>) : <div />}
    {Uncommon.length > 0 ? Uncommon.map((cardSet, index) => (
      <MapListRow
        key={index + "U"}
        cardRow={cardSet}
        history={this.props.history}
      />
      )) : <div />}
    {Common.length > 0 ? (<h2 className="rarityTitle">Common ({this.state.commonCount})</h2>) : <div />}
    {Common.length > 0 ? Common.map((cardSet, index) => (
      <MapListRow
        key={index + "C"}
        cardRow={cardSet}
        history={this.props.history}
      />
      )) : <div />}
       {Promo.length > 0 ? (<h2 className="rarityTitle">Promo ({this.state.promoCount})</h2>) : <div />}
    {Promo.length > 0 ? Promo.map((cardSet, index) => (
      <MapListRow
        key={index + "PR"}
        cardRow={cardSet}
        history={this.props.history}
      />
      )) : <div />}
      {Starter.length > 0 ? (<h2 className="rarityTitle">Starter ({this.state.starterCount})</h2>) : <div />}
    {Starter.length > 0 ? Starter.map((cardSet, index) => (
      <MapListRow
        key={index + "STR"}
        cardRow={cardSet}
        history={this.props.history}
      />
      )) : <div />}
</>
)
  }
  renderAll() {
    const { AllCards, allCardsCount} = this.state;
    return(<>
      {AllCards.length > 0 ? (<h2 className="rarityTitle">AllCards ({this.state.allCardsCount})</h2>) : <div />}
    {AllCards.length > 0 ? AllCards.map((cardSet, index) => (
      <MapListRow
        key={index + "ALL"}
        cardRow={cardSet}
        history={this.props.history}
      />
      )) : <div />}
    </>)
  }
  render() {
    const { cards,SuperRare,LegendaryRare, Rare, Uncommon, Common, loading, Starter,SecretRare,Promo } = this.state;
    return (
      <div className="SearchResults">
        <Helmet>
          <title>Primal Trading Card Game Search Results</title>
          <meta name="description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:title" content="Primal Trading Card Game (TCG) | Awakened Primordials 1st Ed Release April 2024" />
          <meta property="og:description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:image" content="https://pbs.twimg.com/media/GJ38D7mWwAAMXsa?format=jpg&name=medium" />
        </Helmet>
        <Navbar />
        <div className={loading || cards.length === 0 ? "row bkg-red full-h" : "row bkg-red"}>
        <div className="col-xs-12 col-sm-8 col-md-8 col-xl-8 offset-sm-2 offset-md-2 offset-xl-2 floating-section">
          <h1>Search Results</h1>
          <AppBar position="static" color="default">
                  <Tabs
                    value={this.state.filters}
                    onChange={(e, value) => this.setViewFilter(value)}
                    indicatorColor="primary"
                    textColor="primary"
                    centered={true}
                    variant="fullWidth"
                  >
                    <Tab
                      label={"All"}
                      disabled={false}
                      id="All"
                    />
                    <Tab
                      label={"Rarity"}
                      disabled={false}
                      id="Rarity"
                    />
                  </Tabs>
                </AppBar>
                <TabPanel className="col-xs-12" value={this.state.filters} index={0}>
                {this.renderAll()}
          </TabPanel>
          <TabPanel className="col-xs-12" value={this.state.filters} index={1}>
          {this.renderRarityFilter()}
          </TabPanel>

          
          {loading ?  <CircularProgress /> : <div />}
         </div>
         </div>
               <Footer />
      </div>
    );
  }
}
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import OPSchedule from '../../components/assets/primal/salessheet/AP/pg5.png';

export default class OPTour extends Component {

  componentDidMount() {
    setTimeout(() => {
      window.open("https://docs.google.com/forms/d/e/1FAIpQLSfkUO7Ax7_s3SBY3lbeR8Itkmt6lwlNpX7aD3_b7A0NdmwgOg/viewform");
    }, 1000)
  }
  render() {
    return (
      <div className="Rulebook">
        <Helmet>
          <title>Primal Trading Card Game TP Survey</title>
          <meta name="description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:title" content="Primal Trading Card Game (TCG) | Awakened Primordials 1st Ed Release April 2024" />
          <meta property="og:description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:image" content="https://pbs.twimg.com/media/GJ38D7mWwAAMXsa?format=jpg&name=medium" />
        </Helmet>
        <Navbar />
        <div className="row bkg-red">
          <button style={{ position: "fixed", top: "250px", left: "150px",zIndex: "500", width: "400px",fontSize: "23px"
          }} onClick={() => { window.open("https://docs.google.com/forms/d/e/1FAIpQLSfkUO7Ax7_s3SBY3lbeR8Itkmt6lwlNpX7aD3_b7A0NdmwgOg/viewform");}}>Take TP Survey</button>
        <div className="col-xs-12 col-sm-12 col-md-12 col-xl-12">
              <img src={OPSchedule} alt="Primal Trading Card Game Rulebook Cover Page" style={{width: "100%"}} />
            </div>
          
        </div>
      <Footer />
      </div>
    );
  }
}
import React, { Component } from 'react';
import MappedCard from './MappedCard';

export default class MapListRow extends Component {
  render() {
    return (
      <div className="row">
        {this.props.cardRow.map((card, index) => (
          <MappedCard
            key={card._id + index}
            card={card}
            history={this.props.history}
            view={this.props.view}
            env={this.props.env}
            handleClick={(card) => this.props.handleClick(card)}
          />
        ))}
      </div>
    );
  }
}
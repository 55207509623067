import React, { Component } from 'react';
import {
  CircularProgress
} from "@material-ui/core";
import Helmet from 'react-helmet';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import MapListRow from '../Listing/MapListRow';
import constants from "../Constants/constants";

export default class Pack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pack: null,
      loading: true,
      Promo: [],
      Start: [],
      promoCount: ""
    }
  }
  componentDidMount() {
    const urlSplit = this.props.match.url.split("/");
    let packID = urlSplit[urlSplit.length - 1];
    let cachedData = localStorage.getItem("pack");
    if (cachedData) {
      let pack = JSON.parse(cachedData);
      if (pack._id === packID) {
        this.setState({
          pack: pack
        });
      } else {
        this.getPackData(packID);  
      }
    } else {
      this.getPackData(packID);
    }
    // if (lastChar.includes("custom=")) {
    //   this.fetchPredefinedCards(lastChar.substring(7));
    // }
  }
  async getPackData(packID) {
    try {
      let result = await fetch(`https://api.primaltcg.com/api/v1/guest/pack/${packID}`, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });  
        result = await result.json();
        this.formatSearchResults(result.cards);
      this.setState({
        pack: result
      });
    } catch (error) {
      alert("There was an error getting this pack");
      this.props.history("/search");
    }
  }
  get2dArray(arr) {
    let collection = [];
    let temp = [];
    let collectionEnd = arr.length;
    let remainder = collectionEnd % 3;
    arr.map(card => {
      temp.push(card);
      if (temp.length === 3) {
        collection.push(temp);
        temp = [];
      }
    });
    if (remainder === 2) {
      collection.push([arr[collectionEnd - 2], arr[collectionEnd - 1]]);
    }
    if (remainder === 1) {
      collection.push([arr[collectionEnd - 1]]);
    }
    return collection;
  }
  formatSearchResults(cards) {
    let superRare = [];
    let legendaryRare = [];
    let rare = [];
    let uncommon = [];
    let common = [];
    let promo = [];
    // let bng = [];
    // let parallel = [];
    let starter = [];
    let devToken = localStorage.getItem("DEV");
    cards.map(card => {
      if (card.artist.name !== "TBD" || devToken) {
        if (card.rarity.includes("Legendary")) {
          legendaryRare.push(card);
        }
        if (card.rarity === 'Super Rare') {
          superRare.push(card);
        }
        // if (card.rarity === 'STSR') {
        //   superRare.push(card);
        // }
        if (card.rarity === 'Rare') {
          rare.push(card);
        }
        if (card.rarity === 'Uncommon') {
          uncommon.push(card);
        }
        if (card.rarity === 'Promo') {
          promo.push(card);
        }
        if (card.rarity === 'Common') {
          common.push(card);
        }
        // if (card.rarity === 'Parallel') {
        //   parallel.push(card);
        // }
        // if (card.rarity === 'B&G Super Rare') {
        //   bng.push(card);
        // }
        if (card.rarity === 'Starter') {
          starter.push(card);
        }
      }
    });
    let LR = this.get2dArray(legendaryRare);
    let SR = this.get2dArray(superRare);
    let R = this.get2dArray(rare);
    let U = this.get2dArray(uncommon);
    let P = this.get2dArray(promo);
    let C = this.get2dArray(common);
    let Start = this.get2dArray(starter);
    this.setState(
      {
        cards: cards,
        SuperRare: SR,
        LegendaryRare: LR,
        Rare: R,
        Uncommon: U,
        Promo: P,
        Common: C,
        Start: Start,
        legendaryRareCount: legendaryRare.length,
        superRareCount: superRare.length,
        rareCount: rare.length,
        uncommonCount: uncommon.length,
        commonCount: common.length,
        promoCount: promo.length,
        starterCount: starter.length,
        loading: false
      },
      () => {
        console.log("UPDATED STATE", this.state)
      },
    );
  }
  renderPackData() {
    const { pack, Promo, SuperRare, LegendaryRare, Uncommon, Common, Rare, promoCount, Start, starterCount } = this.state;
    return (<div style={{ padding: "15px"}} className="row">
      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <img style={{ width: "100%"}} src={constants.DOMAIN + pack.image} />
        <div onClick={() => {window.open("https://shop.primaltcg.com")}}  style={{ backgroundColor: "green", color: "white", cursor:"pointer" }} className="dlbtn btn btn-neutral btn-round">Visit Shop</div>
        <div className="row">
          <div className="col-sm-12">
            <h3 style={{ fontWeight: "bold"}} >Description</h3>
            <h5 style={{fontWeight: "500"}}>{pack.description}</h5>
          </div>                  
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h3 style={{ fontWeight: "bold"}} >Pull Ratios</h3>
            <h5 style={{fontWeight: "500"}}>{pack.pullRatios}</h5>
          </div>                  
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <h1>{pack.name}</h1>
        <div className="row">
        {LegendaryRare.length > 0 ? (<h2 className="rarityTitle">Legendary Rare ({this.state.legendaryRareCount})</h2>) : <div />}
          {LegendaryRare.length > 0 ? LegendaryRare.map((cardSet, index) => (
            <MapListRow
              key={index + "LR"}
              cardRow={cardSet}
              history={this.props.history}
            />
            )) : <div />}
        {Promo.length > 0 ? (<h2 className="rarityTitle">Promo Cards ({this.state.promoCount})</h2>) : <div />}
          {Promo.length > 0 ? Promo.map((cardSet, index) => (
            <MapListRow
              key={index + "SR"}
              cardRow={cardSet}
              history={this.props.history}
            />
            )) : <div />}
          {SuperRare.length > 0 ? (<h2 className="rarityTitle">Super Rare ({this.state.superRareCount})</h2>) : <div />}
          {SuperRare.length > 0 ? SuperRare.map((cardSet, index) => (
            <MapListRow
              key={index + "SR"}
              cardRow={cardSet}
              history={this.props.history}
            />
            )) : <div />}
          {Rare.length > 0 ? (<h2 className="rarityTitle">Rare ({this.state.rareCount})</h2>) : <div />}
          {Rare.length > 0 ? Rare.map((cardSet, index) => (
            <MapListRow
              key={index + "R"}
              cardRow={cardSet}
              history={this.props.history}
            />
            )) : <div />}
          {Uncommon.length > 0 ? (<h2 className="rarityTitle">Uncommon ({this.state.uncommonCount})</h2>) : <div />}
          {Uncommon.length > 0 ? Uncommon.map((cardSet, index) => (
            <MapListRow
              key={index + "U"}
              cardRow={cardSet}
              history={this.props.history}
            />
            )) : <div />}
          {Common.length > 0 ? (<h2 className="rarityTitle">Common ({this.state.commonCount})</h2>) : <div />}
          {Common.length > 0 ? Common.map((cardSet, index) => (
            <MapListRow
              key={index + "C"}
              cardRow={cardSet}
              history={this.props.history}
            />
            )) : <div />}
            {Start.length > 0 ? (<h2 className="rarityTitle">Starter ({this.state.starterCount})</h2>) : <div />}
          {Start.length > 0 ? Start.map((cardSet, index) => (
            <MapListRow
              key={index + "ST"}
              cardRow={cardSet}
              history={this.props.history}
            />
            )) : <div />}
        </div>
      </div>
    </div>);
  }
  render() {
    const { loading } = this.state;
    return (
      <div className="Card">
        <Helmet>
          <title>{this.state.pack ? this.state.pack.name + " |" : ""} Primal Trading Card Game</title>
          <meta name="description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release on April 2024. Follow us to get early access to cards and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:title" content="Primal Trading Card Game (TCG) | Awakened Primordials 1st Ed Release on April 2024" />
          <meta property="og:description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:image" content="https://pbs.twimg.com/media/GJ38D7mWwAAMXsa?format=jpg&name=medium" />
        </Helmet>
        <Navbar />
        <div className="row bkg-red">
        <div className="col-xs-12 col-sm-8 col-md-8 col-xl-8 offset-sm-2 offset-md-2 offset-xl-2 floating-section">
        {loading ?  <CircularProgress /> : <div />}
          {this.state.pack ? this.renderPackData() : (<h1>Invalid Pack Data</h1>)}
          
        </div>
      </div>
      <Footer />
      </div>
    );
  }
}
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import pg0 from "../assets/primal/salessheet/AP/pg0.png"
import pg1 from "../assets/primal/salessheet/AP/pg1.png"
import pg2 from "../assets/primal/salessheet/AP/pg2.png"
import pg3 from "../assets/primal/salessheet/AP/pg3.png"
import pg4 from "../assets/primal/salessheet/AP/pg4.png"
import pg5 from "../assets/primal/salessheet/AP/pg5.png"
import pg6 from "../assets/primal/salessheet/AP/pg6.png"
import pg7 from "../assets/primal/salessheet/AP/pg7.png"
import pg8 from "../assets/primal/salessheet/AP/pg8.png"
import box from "../assets/primal/preorder/firstEd02.png"
import decks from "../assets/primal/preorder/decks.png"
import _case from "../assets/primal/preorder/case.png"
import master from "../assets/primal/preorder/master.png"
import BG from "../assets/primal/salessheet/BG.png"
import starterCase from "../assets/primal/preorder/starterCase.png"
import { Tabs, Tab, AppBar, FormControl, TextField, Box, Typography, Button, CircularProgress } from "@material-ui/core";


export default class Salesheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEvent: null,
      data: null,
      businesses: [],
      individuals: [],
      boxesOrdered: null,
      decksOrdered: null,
      type: 1,
      name: "",
      businessName: "",
      email: "",
      phone: "",
      address: "",
      boxes: 0,
      starters: 0,
      box_cases: 0,
      master_cases: 0,
      starter_cases: 0,
      loading: true,
      open: false
    } 
    
  }
     /**
   * Renders TextInput with FromControl
   *
   * @param {string} id
   * @param {string} label
   * @param {string} value
   * @param {any} width Can be int or string, controls the width of the form control
   * @returns {React$Element<FormControl>}
   * @memberof NewShipmentComponent
   */
     renderTextInput(
      id: string,
      label: any,
      value: string,
      width: any,
      type: any,
      inputProps: Object,
      disabled: boolean = false,
      error: boolean = false
    ): React$Element<FormControl> {
      return (
        <FormControl style={{ width: width }}>
          <TextField
            error={error}
            variant="outlined"
            id={id}
            type={type}
            label={label}
            value={value || ""}
            disabled={disabled || this.state.disabled}
            InputProps={inputProps}
            autoComplete="nope"
            onChange={(inputValue: Object) => {
              this.setState({
                [id]: inputValue.target.value
              })
            }}
          />
        </FormControl>
      );
    }
    handleSubmit(e) {
      e.preventDefault()
      const { type, name, email, phone, address, boxes, starters, box_cases, starter_cases, master_cases } = this.state; 
      
      if (
        email
      ) {
        let message = `
        PRIMAL TCG WHOLESALE ORDER REQUESTED
        EMAIL: ${email}\n
        PHONE: ${phone}\n
        NAME: ${name}\n
        BUSINESS: ${this.state.businessName}\n
        ADDRESS: ${address}\n
        BOXES: ${boxes}\n
        STARTERS: ${starters}\n
        BOX CASE: ${box_cases}\n
        STARTER CASE: ${starter_cases}\n
        BOOSTER MASTER CASE: ${master_cases}\n
        `;
        let details = {
          'message': message,
          'name': this.state.businessName + ' ' + this.state.name,
          'fromEmail': this.state.email,
        };
        let formBody = [];
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        fetch('https://api.narutoccg.com/api/v1.1/market/sendmail', {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: formBody,
        }).then((response) => response.json())
          .then((responseData) => {
            this.props.history.push("/order-confirmation");
            alert("Your Order has been submitted!")
          })
          .catch(err => {
            console.log(err);
          });
      }
      else {
        alert('Please fill out the fields with appropriate values');
      }
    }
  renderBusinessForm() {
    return (
      <div>
        <h2>Contact Information</h2>
        <p>Fill out all of the information to order.</p>
        <div style={{marginTop: "5px"}} className='row'>
          <div className='col-sm-6 col-md-6 col-lg-6 inputRow'>
          {this.renderTextInput("name","Full Name",this.state.name, "100%")} 
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 inputRow'>
          {this.renderTextInput("businessName","Business Name",this.state.businessName, "100%")} 
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 inputRow'>
          {this.renderTextInput("email","Email",this.state.email, "100%")} 
          </div>
        </div>
        <div style={{ marginBottom: "15px"}} className='row'>
        <div className='col-sm-6 col-md-6 col-lg-6 inputRow'>
        <FormControl style={{ width: "100%" }}>
          <TextField
            variant="outlined"
            label={"Address"}
            value={this.state.address || ""}
            autoComplete="nope"
            multiline
            rows={4}
            onChange={(inputValue: Object) => {
              this.setState({
                address: inputValue.target.value
              })
            }}
          />
        </FormControl>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 inputRow'>
          {this.renderTextInput("phone","Phone Number",this.state.phone, "100%")} 
          </div>
        </div>
        <h5>Brick and mortar game shops get an introductory discount and official tournament shops will also get a recurring discount on their orders.</h5>
        <h5>For additional Partner Perks contact us stating you wish to become a partner. Partners must order a minimum order quantity of 30 Booster Boxes and 36 Starter Decks per SKU.</h5>
        <h5>Brick and mortar shops will receive their first tournament kit with their first order.</h5>
        <div style={{ marginBottom: "15px", marginTop: "5px"}} className='row'>
        <div className='pop-section col-xs-12 col-sm-5 col-md-5 col-lg-5 offset-md-1'>
            <img src={box} style={{width: "100%"}} />
            <h3 className='priceBoxes'>$80.00</h3>
          <ul>
          <li><p className='bold'>MSRP $110.00</p></li>
          <li><p className='bold'>1 Booster Box</p></li>
          <li><p className='bold'>24 Booster Packs, 10 Cards per Pack, 1 Foil Card per Pack</p></li>
          </ul>
          {this.renderTextInput("boxes","1st Ed Booster Boxes ($80.00)",this.state.boxes, "100%", "number")} 
          </div>
          <div className='pop-section col-xs-12 col-sm-5 col-md-5 col-lg-5 offset-md-1'>
          <img src={decks} style={{width: "100%"}} />
          <h3 className='priceBoxes'>$20.00</h3>
          <ul>
          <li><p className='bold'>MSRP $15.00 (each)</p></li>
          <li><p className='bold'>2 Starter Decks</p></li>
          <li><p className='bold'>1 Exclusive Super Rare Per Deck</p></li>
          </ul>
          {this.renderTextInput("starters","Set of 2 Starter Decks ($20.00)",this.state.starters, "100%", "number")} 
          </div>
        <div className='pop-section col-xs-12 col-sm-5 col-md-5 col-lg-5 offset-md-1'>
          <img src={_case} style={{width: "100%"}} />
          <h3 className='priceBoxes'>$800.00</h3>
          <ul>
          <li><p className='bold'>10 Booster Boxes</p></li>
          <li><p className='bold'>MSRP $110.00</p></li>
          <li><p className='bold'>240 Booster Packs, 10 Cards per Pack, 1 Foil Card per Pack</p></li>
          <li><p className='bold'>3 Guaranteed Legendary Rares</p></li>
          </ul>
          {this.renderTextInput("box_cases","1st Ed Booster Cases (10 Box)",this.state.box_cases, "100%", "number")} 
          </div>
          <div className='pop-section col-xs-12 col-sm-5 col-md-5 col-lg-5 offset-md-1'>
          <img src={starterCase} style={{width: "100%"}} />
          <h3 className='priceBoxes'>$190.00</h3>
          <ul>
          <li><p className='bold'>24 Individual Starter Decks</p></li>
          <li><p className='bold'>MSRP $15.00</p></li>
          <li><p className='bold'>1 Super Rare Holo Per Deck</p></li>
          <li><p className='bold'>Cards are exclusive to Starter Decks</p></li>
          </ul>
            {this.renderTextInput("starter_cases","1st Ed Starter Decks Cases (24 Decks)",this.state.starter_cases, "100%", "number")} 
          </div>
        </div>
        <div style={{ marginBottom: "15px", marginTop: "5px"}} className='row'>
        <div className='pop-section col-xs-12 col-sm-8 col-md-8 col-lg-8 offset-md-2'>
          <img src={master} style={{width: "100%"}} />
          <h3 className='priceBoxes'>$2,100.00</h3>
          <ul>
          <li><p className='bold'>30 Booster Boxes</p></li>
          <li><p className='bold'>MSRP $110.00</p></li>
          <li><p className='bold'>720 Booster Packs, 10 Cards per Pack, 1 Foil Card per Pack</p></li>
          <li><p className='bold'>4 Guaranteed Legendary Rares</p></li>
          </ul>
          {this.renderTextInput("master_cases","1st Ed Booster Master Cases (30 Box)",this.state.master_cases, "100%", "number")} 
          </div>
        
          <div className='col-md-12'>
          <Button
                variant="contained"
                color="primary"
                styles={{
                  cursor: "pointer",
                  width: "100%"
                }}
                fullWidth={true}
                onClick={(e) => {
                  this.handleSubmit(e)
                }}
              >
                  <div style={{fontSize: "26px"}}>
                    Submit Order
                  </div>
              </Button>
        </div>

        </div>
      </div>
    );
  }
  render() {
    let pages = [pg1 ,pg2 ,pg3 ,pg4 ,pg5 ,pg6 ,pg7 ,pg8];
    return (
      <div className="Salesheet">
        <Helmet>
          <title>Primal Trading Card Game Salesheet</title>
          <meta name="description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:title" content="Primal Trading Card Game (TCG) | Awakened Primordials 1st Ed Release April 2024" />
          <meta property="og:description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:image" content="https://pbs.twimg.com/media/GJ38D7mWwAAMXsa?format=jpg&name=medium" />
        </Helmet>
        <Navbar />
      
    		
    	
        
        <div className="row bkg-red">
        <div className="col-xs-6 col-sm-6 col-md-6 col-xl-6">
              <img src={pg0} alt="Primal Trading Card Game Sales Sheet Cover Page" style={{width: "100%"}} />
            </div>
          {pages.map((page, index) => (
            <div key={`page${index}`} style={{ marginBottom: "1vh"}} className="col-xs-12 col-sm-6 col-md-6">
              <img src={page} alt={`Primal Trading Card Game Salesheet Page ${index + 1}`} style={{width: "100%"}} />
            </div>
          ))}
        </div>
        <div className="cd-section section-white" id="contact-us">
    	<div className="contactus-1 section-image" style={{ backgroundImage: `url(${BG})`}}>
    		<div className="container">
    			<div className="row">
                <div className="col-md-10 ml-auto mr-auto">
                  <div className="card card-contact no-transition">
                    <h3 className="card-title text-center">Wholesale Order Here</h3>
                    <h5 className="card-title text-center">Only for retailers. Pricing shown here is for online retailers. For consumer pricing visit our online store at <a href='https://shop.primaltcg.com'>shop.primaltcg.com</a></h5>
                    <div style={{ padding: "5vw" }} className="row">
                      
                      {this.renderBusinessForm()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    			</div>
      <Footer />
      </div>
    );
  }
}
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import {
  CircularProgress
} from "@material-ui/core";
import Navbar from "../Navbar/Navbar";
import MapListRow from '../Listing/MapListRow';
import MappedCard from '../Listing/MappedCard';
import Footer from "../Footer/Footer";


export default class ArtistsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      artists: []
    }
  }
  componentDidMount() {
    const urlSplit = this.props.match.url.split("/");
    // let lastChar = urlSplit[urlSplit.length - 1];
    this.fetchArtists();
  }
  async fetchArtists() {
    try {
      let result = await fetch('https://api.primaltcg.com/api/v1/guest/artists', {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });  
      result = await result.json();
      if (result && result.length > 0) {
        this.setState({ 
          artists: result,
          loading: false
        });
      } else {
        alert('There was a problem getting Primal TCG artists');
        this.props.history.push("/");
      }
    } catch (error) {
      alert('There was a problem getting Primal TCG artists');
      this.props.history.push("/");
    }
  }

  render() {
    const { artists, loading} = this.state;
    return (
      <div className="Artist">
        <Helmet>
          <title>Primal Trading Card Game Artists</title>
          <meta name="description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:title" content="Primal Trading Card Game (TCG) | Awakened Primordials 1st Ed Release April 2024" />
          <meta property="og:description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:image" content="https://pbs.twimg.com/media/GJ38D7mWwAAMXsa?format=jpg&name=medium" />
        </Helmet>
        <Navbar />
        <div className={loading || artists.length === 0 ? "row bkg-red full-h" : "row bkg-red"}>
        <div className="col-xs-12 col-sm-8 col-md-8 col-xl-8 offset-sm-2 offset-md-2 offset-xl-2 floating-section">
          <h1>Primal Trading Card Game Artists</h1>
          {loading ?  <CircularProgress /> : <div />}
          <div className="row">
          {artists.length > 0 ? artists.map((artist, index) => {
            if (artist.name.length > 2 && artist.name !== "TBD") {
              return (
                <MappedCard
                  key={artist._id + index}
                  card={artist.illustrations[0]}
                  artist={artist.name}
                  history={this.props.history}
                />
              )
            }
           }) : <div />}  
          </div>
        </div>
      </div>
      <Footer />
      </div>
    );
  }
}
import React, { Component } from 'react';
import logo from "../assets/primal/branding/YellowLogo.png";
import appImage from "../assets/primal/branding/appImage.png";
import "./Banner.css";
export default class ShopBanner extends Component {
  render() {
    return (
      <div className="row">
          <div className="col-md-12 col-xs-12">
            <div className="page-header" 
              style={{ backgroundImage: `url(${window.innerWidth > 867 ? this.props.banner : this.props.mobileBanner })`, height: "100ch"}}>
               <div className="filter" /> 
              <div className="content-center">
                <div className="container">
                  <div className="motto">
                    {/* <img id="logoBanner" alt="Primal Trading Card Game App" src={appImage} /> */}
                    <h3 className="subtitle">Deck Build and Search All Cards</h3> 
                    <h1 className="title">Primal TCG App</h1>
                    <h4 className="description">The Primal TCG App lets you search for all of the cards that will be released in our first set and you can even start your own collection from our Starter Deck cards and our Gold Foil Promos. The rulebook will always be with you while you have this app and you can even see how big the community is and how much it is growing. Download it today!</h4>
                    {/* <h3 className="description">Explore the Primordials</h3> */}
                    <br />
                    {/* <div onClick={() => {window.open("https://shop.primaltcg.com")}}  style={{ backgroundColor: "green", color: "white", cursor:"pointer" }} className="dlbtn btn btn-neutral btn-round">Buy Pre-Release Starter Decks</div> */}
                    <br />
                    <br />
                    {/* <a href="/search/results/custom=cayne-pirates" style={{ backgroundColor: "#27acc1", color: "white" }} className="dlbtn btn btn-neutral btn-round">Cayne Pirates</a>
                    <a href="/search/results/custom=draginked" style={{ backgroundColor: "purple", color: "white" }} className="dlbtn btn btn-neutral btn-round">Draginked</a>
                    <a href="/search/results/custom=micromons" style={{ backgroundColor: "#ab9000", color: "white" }} className="dlbtn btn btn-neutral btn-round">Micromons</a>
                    <a href="/search/results/custom=symbiotes" style={{ backgroundColor: "#cd2600", color: "white" }} className="dlbtn btn btn-neutral btn-round">Symbiote Dragons</a>
                    <a href="/search/results/custom=pleaguis" style={{ backgroundColor: "black", color: "white" }} className="dlbtn btn btn-neutral btn-round">Pleaguis</a> */}
                    <a href="https://apps.apple.com/us/app/primal-tcg-player/id1540500182" className="dlbtn btn btn-neutral btn-round"><i className="fa fa-apple" aria-hidden="true"/>iPhone</a>
                    <a href="https://play.google.com/store/apps/details?id=com.primaltcg" className="dlbtn btn btn-success btn-round"><i className="fa fa-android" aria-hidden="true"/>Android</a>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
    );
  }
}
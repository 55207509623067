/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import {
  Link,
  withRouter,
} from 'react-router-dom';
import Compressor from 'compressorjs';
import AuthService from './AuthService';
// import hiw_listIt from './assets/hiw_listIt.png';
// import hiw_shareIt from './assets/hiw_shareIt.png';
// import hiw_earnCash from './assets/hiw_earnCash.png';
import naruto from './assets/naruto/PR06.png';
import fire from './assets/primal/symbol/FireIcon.png';
import necroSymbol from './assets/primal/symbol/NecroIcon.png';
import water from './assets/primal/symbol/SeaIcon.png';
import plasma from './assets/primal/symbol/PlasmaIcon.png';
import terra from './assets/primal/symbol/TerraIcon.png';
import air from './assets/primal/symbol/AirIcon.png';
import strategy from './assets/primal/symbol/StrategyIcon.png';
import field from './assets/primal/symbol/FieldIcon.png';
// import tool from './assets/naruto/type/toolType.png';
import ability from './assets/primal/symbol/AbilityIcon.png';
import character from './assets/primal/symbol/CharacterIcon.png';
import one from './assets/naruto/entrance/one.png';
import two from './assets/naruto/entrance/two.png';
import three from './assets/naruto/entrance/three.png';
import four from './assets/naruto/entrance/four.png';
import five from './assets/naruto/entrance/five.png';
import six from './assets/naruto/entrance/six.png';
import seven from './assets/naruto/entrance/seven.png';
import eight from './assets/naruto/entrance/eight.png';
import nine from './assets/naruto/entrance/nine.png';
import zero from './assets/naruto/entrance/zero.png';
import colorless from './assets/naruto/symbol/colorless.png';
// import './HIW.css';
 
const Auth = new AuthService();

class NinjaForm extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      image: naruto,
      symbol: [],
      fireStyle: 0.3,
      waterStyle: 0.3,
      terraStyle: 0.3,
      airStyle: 0.3,
      plasmaStyle: 0.3,
      necroStyle: 0.3,
      cardType: 'Character',
      characterStyle: 1,
      strategyStyle: 0.3,
      abilityStyle: 0.3,
      fieldStyle: 0.3,
      toolStyle: 0.3,
      entranceCost: '',
      zeroStyle: 0.3,
      oneStyle: 0.3,
      twoStyle: 0.3,
      threeStyle: 0.3,
      fourStyle: 0.3,
      fiveStyle: 0.3,
      sixStyle: 0.3,
      sevenStyle: 0.3,
      eightStyle: 0.3,
      nineStyle: 0.3,
      rarity: '',
      price: '',
      shippingCost: '',
      loadingIcon: 'none',
      productPost: '2px solid black',
      socialPost: '',
      product: true,
      social: false,
      abilityType:'',
      env: 'Dev'
    };
    this.imagePreview = this.imagePreview.bind(this);
    this.setSymbol = this.setSymbol.bind(this);
    this.setType = this.setType.bind(this);
    this.setEntrance = this.setEntrance.bind(this);
    this.handleRarity = this.handleRarity.bind(this);
    this.handleEnv = this.handleEnv.bind(this);
    this.handleAbility = this.handleAbility.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetState = this.resetState.bind(this);
  }
  resetState() {
    const button = document.getElementById('submitPost');
    button.setAttribute('style', 'display: block;');

    this.setState({
      IMAGE: '',
      image: naruto,
      symbol: [],
      fireStyle: 0.3,
      waterStyle: 0.3,
      terraStyle: 0.3,
      airStyle: 0.3,
      plasmaStyle: 0.3,
      necroStyle: 0.3,
      cardType: 'Character',
      characterStyle: 1,
      strategyStyle: 0.3,
      abilityStyle: 0.3,
      fieldStyle: 0.3,
      toolStyle: 0.3,
      entranceCost: '',
      zeroStyle: 0.3,
      oneStyle: 0.3,
      twoStyle: 0.3,
      threeStyle: 0.3,
      fourStyle: 0.3,
      fiveStyle: 0.3,
      sixStyle: 0.3,
      sevenStyle: 0.3,
      eightStyle: 0.3,
      nineStyle: 0.3,
      rarity: '',
      abilityType: '',
    });
  }
  imagePreview(event) {
    const image = event.target.files[0];
    // console.log(image);
    const self = this;
    // eslint-disable-next-line no-new
    new Compressor(image, {
      quality: 0.6,
      success(result) {
      console.log(result);
        if (result instanceof File) {
          self.setState({ IMAGE: result }, () => {
            console.log(self.state.IMAGE);
            const reader = new FileReader();
            reader.onload = (e) => {
              self.setState({
                image: e.target.result,
              });
            };
            reader.readAsDataURL(self.state.IMAGE);
          });
        } else { // blob life
          const resultFile = new File([result], result.name, { type: result.type, lastModified: Date.now() });
          self.setState({ IMAGE: resultFile }, () => {
            console.log(self.state.IMAGE);
            const reader = new FileReader();
            reader.onload = (e) => {
              self.setState({
                image: e.target.result,
              });
            };
            reader.readAsDataURL(self.state.IMAGE);
          });
        }
      },
    });
  }
  setSymbol(e) {
    // console.log(e.target.id); gets the target id
    const symbol = e.target.id;
    const current = this.state.symbol;
    if (symbol === 'fireSymbol') {
      if (current.includes('Fire')) {
        // make opacity less than 1 because it isn't a choice
        const index = current.indexOf('Fire');
        if (index > -1) {
          current.splice(index, 1);
        }
        this.setState({ fireStyle: 0.3, symbol: current });
      } else {
        current.push('Fire');
        this.setState({ fireStyle: 1, symbol: current });
      }
    }
    if (symbol === 'waterSymbol') {
      if (current.includes('Water')) {
        // make opacity less than 1 because it isn't a choice
        const index = current.indexOf('Water');
        if (index > -1) {
          current.splice(index, 1);
        }
        const index2 = current.indexOf('Sea');
        if (index2 > -1) {
          current.splice(index2, 1);
        }
        this.setState({ waterStyle: 0.3, symbol: current });
      } else {
        current.push('Water');
        current.push('Sea');
        this.setState({ waterStyle: 1, symbol: current });
      }
    }
    if (symbol === 'terraSymbol') {
      if (current.includes('Terra')) {
        // make opacity less than 1 because it isn't a choice
        const index = current.indexOf('Terra');
        if (index > -1) {
          current.splice(index, 1);
        }
        this.setState({ terraStyle: 0.3, symbol: current });
      } else {
        current.push('Terra');
        this.setState({ terraStyle: 1, symbol: current });
      }
    }
    if (symbol === 'airSymbol') {
      if (current.includes('Air')) {
        // make opacity less than 1 because it isn't a choice
        const index = current.indexOf('Air');
        if (index > -1) {
          current.splice(index, 1);
        }
        this.setState({ airStyle: 0.3, symbol: current });
      } else {
        current.push('Air');
        this.setState({ airStyle: 1, symbol: current });
      }
    }
    if (symbol === 'plasmaSymbol') {
      if (current.includes('Plasma')) {
        // make opacity less than 1 because it isn't a choice
        const index = current.indexOf('Plasma');
        if (index > -1) {
          current.splice(index, 1);
        }
        this.setState({ plasmaStyle: 0.3, symbol: current });
      } else {
        current.push('Plasma');
        this.setState({ plasmaStyle: 1, symbol: current });
      }
    }
    if (symbol === 'necroSymbol') {
      if (current.includes('Necro')) {
        // make opacity less than 1 because it isn't a choice
        const index = current.indexOf('Necro');
        if (index > -1) {
          current.splice(index, 1);
        }
        this.setState({ necroStyle: 0.3, symbol: current });
      } else {
        current.push('Necro');
        this.setState({ necroStyle: 1, symbol: current });
      }
    }
  }
  setType(e) {
    const type = e.target.id;
    if (type === 'characterType') {
      this.setState({
        cardType: 'Character',
        characterStyle: 1,
        strategyStyle: 0.3,
        abilityStyle: 0.3,
        fieldStyle: 0.3,
        toolStyle: 0.3,
      });
    }
    if (type === 'strategyType') {
      this.setState({
        cardType: 'Strategy',
        characterStyle: 0.3,
        strategyStyle: 1,
        abilityStyle: 0.3,
        fieldStyle: 0.3,
        toolStyle: 0.3,
      });
    }
    if (type === 'abilityType') {
      this.setState({
        cardType: 'Ability',
        characterStyle: 0.3,
        strategyStyle: 0.3,
        abilityStyle: 1,
        fieldStyle: 0.3,
        toolStyle: 0.3,
      });
    }
    if (type === 'fieldType') {
      this.setState({
        cardType: 'Field',
        characterStyle: 0.3,
        strategyStyle: 0.3,
        abilityStyle: 0.3,
        fieldStyle: 1,
        toolStyle: 0.3,
      });
    }
    // if (type === 'toolType') {
    //   this.setState({
    //     cardType: 'Tool',
    //     characterStyle: 0.3,
    //     strategyStyle: 0.3,
    //     abilityStyle: 0.3,
    //     fieldStyle: 0.3,
    //     toolStyle: 1,
    //   });
    // }
  }
  setEntrance(e) {
    const entrance = e.target.id;
    if (entrance === 'zeroType') {
      this.setState({
        entranceCost: '0',
        zeroStyle: 1,
        oneStyle: 0.3,
        twoStyle: 0.3,
        threeStyle: 0.3,
        fourStyle: 0.3,
        fiveStyle: 0.3,
        sixStyle: 0.3,
        sevenStyle: 0.3,
        eightStyle: 0.3,
        nineStyle: 0.3,
      });
    }
    if (entrance === 'oneType') {
      this.setState({
        entranceCost: '1',
        zeroStyle: 0.3,
        oneStyle: 1,
        twoStyle: 0.3,
        threeStyle: 0.3,
        fourStyle: 0.3,
        fiveStyle: 0.3,
        sixStyle: 0.3,
        sevenStyle: 0.3,
        eightStyle: 0.3,
        nineStyle: 0.3,
      });
    }
    if (entrance === 'twoType') {
      this.setState({
        entranceCost: '2',
        zeroStyle: 0.3,
        oneStyle: 0.3,
        twoStyle: 1,
        threeStyle: 0.3,
        fourStyle: 0.3,
        fiveStyle: 0.3,
        sixStyle: 0.3,
        sevenStyle: 0.3,
        eightStyle: 0.3,
        nineStyle: 0.3,
      });
    }
    if (entrance === 'threeType') {
      this.setState({
        entranceCost: '3',
        zeroStyle: 0.3,
        oneStyle: 0.3,
        twoStyle: 0.3,
        threeStyle: 1,
        fourStyle: 0.3,
        fiveStyle: 0.3,
        sixStyle: 0.3,
        sevenStyle: 0.3,
        eightStyle: 0.3,
        nineStyle: 0.3,
      });
    }
    if (entrance === 'fourType') {
      this.setState({
        entranceCost: '4',
        zeroStyle: 0.3,
        oneStyle: 0.3,
        twoStyle: 0.3,
        threeStyle: 0.3,
        fourStyle: 1,
        fiveStyle: 0.3,
        sixStyle: 0.3,
        sevenStyle: 0.3,
        eightStyle: 0.3,
        nineStyle: 0.3,
      });
    }
    if (entrance === 'fiveType') {
      this.setState({
        entranceCost: '5',
        zeroStyle: 0.3,
        oneStyle: 0.3,
        twoStyle: 0.3,
        threeStyle: 0.3,
        fourStyle: 0.3,
        fiveStyle: 1,
        sixStyle: 0.3,
        sevenStyle: 0.3,
        eightStyle: 0.3,
        nineStyle: 0.3,
      });
    }
    if (entrance === 'sixType') {
      this.setState({
        entranceCost: '6',
        zeroStyle: 0.3,
        oneStyle: 0.3,
        twoStyle: 0.3,
        threeStyle: 0.3,
        fourStyle: 0.3,
        fiveStyle: 0.3,
        sixStyle: 1,
        sevenStyle: 0.3,
        eightStyle: 0.3,
        nineStyle: 0.3,
      });
    }
    if (entrance === 'sevenType') {
      this.setState({
        entranceCost: '7',
        zeroStyle: 0.3,
        oneStyle: 0.3,
        twoStyle: 0.3,
        threeStyle: 0.3,
        fourStyle: 0.3,
        fiveStyle: 0.3,
        sixStyle: 0.3,
        sevenStyle: 1,
        eightStyle: 0.3,
        nineStyle: 0.3,
      });
    }
    if (entrance === 'eightType') {
      this.setState({
        entranceCost: '8',
        zeroStyle: 0.3,
        oneStyle: 0.3,
        twoStyle: 0.3,
        threeStyle: 0.3,
        fourStyle: 0.3,
        fiveStyle: 0.3,
        sixStyle: 0.3,
        sevenStyle: 0.3,
        eightStyle: 1,
        nineStyle: 0.3,
      });
    }
    if (entrance === 'nineType') {
      this.setState({
        entranceCost: '9',
        zeroStyle: 0.3,
        oneStyle: 0.3,
        twoStyle: 0.3,
        threeStyle: 0.3,
        fourStyle: 0.3,
        fiveStyle: 0.3,
        sixStyle: 0.3,
        sevenStyle: 0.3,
        eightStyle: 0.3,
        nineStyle: 1,
      });
    }
  }
  handleEnv = (changeEvent) => {
    this.setState({
      env: changeEvent.target.value,
    });
  }
  handleRarity = (changeEvent) => {
    this.setState({
      rarity: changeEvent.target.value,
    });
  }
  handleAbility = (changeEvent) => {
    this.setState({
      abilityType: changeEvent.target.value,
    }, () => console.log(this.state));
  }
  handleSubmit() {
    let cardName = document.getElementById('cardName').value;

    let image = this.state.IMAGE;
    let symbol = this.state.symbol;

    let type = this.state.cardType;

    let entranceCost = this.state.entranceCost;

    let rarity = this.state.rarity;


    let cardSetDropDown = document.getElementById('cardSet');
    let cardSet = cardSetDropDown.options[cardSetDropDown.selectedIndex].value;


    let cardNumber = document.getElementById('cardNumber').value;

    let characterEffect = document.getElementById('description').value;
    characterEffect = characterEffect.replace(/\n\r?/g, '\r\n');

    const data = new FormData();
    if (type === "Field") {
      data.append('name', cardName);
      data.append('text', characterEffect);
      data.append('image', image, image.name);
      data.append('rarity', rarity);
      data.append('cardNumber', cardNumber);
      data.append('cardType', type);

      data.append('cardSet', cardSet);
      for (let i = 0; i < symbol.length; i++) {
        data.append('element[]', symbol[i]);
      }
    }
    if (type === 'Character') {
      let combatDropDown = document.getElementById('combatAttr');
      let combatAttribute = document.getElementById('characterAttribute').value;
      let characterCharacteristics = document.getElementById('characterCharacteristics').value;
      let charArr = characterCharacteristics.split(',');

      let healthyStat = document.getElementById('healthyStat').value;

      let injuredStat = document.getElementById('injuredStat').value;
      let handCost = document.getElementById('handCost').value;
      data.append('name', cardName);
      data.append('text', characterEffect);
      data.append('image', image, image.name);
      data.append('rarity', rarity);
      data.append('cardNumber', cardNumber);
      data.append('cardType', type);
      for (let i = 0; i < charArr.length; i++) {
        data.append('characteristic[]', charArr[i]);
      }
      data.append('handCost', handCost);
      data.append('turnCount', entranceCost);
      data.append('skill', combatAttribute);
      data.append('healthy', healthyStat);
      data.append('playable', true);
      data.append('errata', false);
      data.append('injured', injuredStat);
      data.append('cardSet', cardSet);
      for (let i = 0; i < symbol.length; i++) {
        data.append('element[]', symbol[i]);
      }
    }
    if (type === 'Strategy' || type === 'Client' || type === 'Tool') {
      let handCost = document.getElementById('handCost').value;

      data.append('name', cardName);
      data.append('text', characterEffect);
      data.append('image', image, image.name);
      data.append('rarity', rarity);
      data.append('cardNumber', cardNumber);
      if (type === 'Tool') {
        data.append('cardType', 'Character');
        const toolChar = ['REINFORCEMENT'];
        for (let i = 0; i < toolChar.length; i++) {
          data.append('characteristic[]', toolChar[i]);
        }
      } else {
        data.append('cardType', type);
      }
      data.append('handCost', handCost);
      data.append('entranceCost', entranceCost);
      data.append('cardSet', cardSet);
      for (let i = 0; i < symbol.length; i++) {
        data.append('symbol[]', symbol[i]);
      }
    }
    if (type === 'Ability') {

      let fireCost = document.getElementById('fireCost').value;
      let waterCost = document.getElementById('waterCost').value;
      let terraCost = document.getElementById('terraCost').value;
      let airCost = document.getElementById('airCost').value;
      let plasmaCost = document.getElementById('plasmaCost').value;
      let necroCost = document.getElementById('necroCost').value;
      let anyCost = document.getElementById('anyCost').value;
      const abilityCostArr = [];
      if (Number(fireCost) > 0) {
        for (let i = 0; i < fireCost; i++) {
          abilityCostArr.push('Fire');
        }
      }
      if (Number(waterCost) > 0) {
        for (let i = 0; i < waterCost; i++) {
          abilityCostArr.push('Water');
        }
      }
      if (Number(terraCost) > 0) {
        for (let i = 0; i < terraCost; i++) {
          abilityCostArr.push('Terra');
        }
      }
      if (Number(airCost) > 0) {
        for (let i = 0; i < airCost; i++) {
          abilityCostArr.push('Air');
        }
      }
      if (Number(plasmaCost) > 0) {
        for (let i = 0; i < plasmaCost; i++) {
          abilityCostArr.push('Plasma');
        }
      }
      if (Number(necroCost) > 0) {
        for (let i = 0; i < necroCost; i++) {
          abilityCostArr.push('Necro');
        }
      }
      if (anyCost) {
        abilityCostArr.push(anyCost);
      }
      data.append('name', cardName);
      data.append('text', characterEffect);
      data.append('image', image, image.name);
      data.append('rarity', rarity);
      data.append('cardNumber', cardNumber);
      data.append('cardSet', cardSet);
      for (let i = 0; i < symbol.length; i++) {
        data.append('symbol[]', symbol[i]);
      }
      for (let i = 0; i < abilityCostArr.length; i++) {
        data.append('abilityCost[]', abilityCostArr[i]);
      }
      if (this.state.abilityType === 'Forbidden') {
        data.append('entranceCost', entranceCost);
        data.append('cardType', 'Character');
        const toolChar = ['REINFORCEMENT'];
        for (let i = 0; i < toolChar.length; i++) {
          data.append('characteristic[]', toolChar[i]);
        }
      } else {
        data.append('cardType', type);
      }
    }

    if (cardName && characterEffect && this.state.IMAGE && symbol && type) {
      const button = document.getElementById('submitPost');
      button.setAttribute('style', 'display: none;');
      if (this.state.env === "Dev") {
        Auth.devFetch('https://dev.primaltcg.com/api/v1/posts', {
        mode: 'cors',
        method: 'POST',
        body: data,
      }).then(res => {
        this.resetState();
        alert('Thank you for submitting To DEV! Want to upload another?');

      }).catch(err => {
        console.log(err);
      });
      } else {
        Auth.prodFetch('https://api.primaltcg.com/api/v1/posts', {
          mode: 'cors',
          method: 'POST',
          body: data,
        }).then(res => {
          this.resetState();
          alert('Thank you for submitting TO LIVE! Want to upload another?');
  
        }).catch(err => {
          console.log(err);
        });
      }
    }
  }
  render() {
    return (
      <div className="characterForm row">
        <div className='col-md-6'>
        <h4>MAKE SURE IMAGE FILE SIZE IS LESS THAN 1MB</h4>
        <div className="formImageContainer col-md-12">
          <img src={this.state.image} />
        </div>
        <div className="col-md-12">
          <a id="submitCardBtn" className="btn">
            <label htmlFor="cardImg"><h4 id="characterFormHead">Upload Card Image</h4></label>
          </a>
          <input
            className="postfile"
            type="file"
            name="cardImg"
            id="cardImg"
            onChange={this.imagePreview}
          />
        </div>
        </div>
        <div className='col-md-6'>
        <div className="row">
        <div className="col-md-4 col-sm-4 col-xs-4">
          <h4 id="characterFormHead">Card Name:</h4>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-6">
          <input id="cardName" className="form-control cardName" placeholder="Type the Card's name" />
        </div>
        </div>
        <div className="formHead col-md-12 col-xs-12">
          <h4 id="characterFormHead">Card Symbol</h4>
        </div>

        <div className="symbolContainer row">
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="fireSymbol" style={{ opacity: this.state.fireStyle }} onClick={this.setSymbol} src={fire} />
            </a>
          </div>
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="waterSymbol" style={{ opacity: this.state.waterStyle }} onClick={this.setSymbol} src={water} />
            </a>
          </div>
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="terraSymbol" style={{ opacity: this.state.terraStyle }} onClick={this.setSymbol} src={terra} />
            </a>
          </div>
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="plasmaSymbol" style={{ opacity: this.state.plasmaStyle }} onClick={this.setSymbol} src={plasma} />
            </a>
          </div>
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="airSymbol" style={{ opacity: this.state.airStyle }} onClick={this.setSymbol} src={air} />
            </a>
          </div>
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="necroSymbol" style={{ opacity: this.state.necroStyle }} onClick={this.setSymbol} src={necroSymbol} />
            </a>
          </div>
        </div>
        <div className="symbolContainer row">
          <div className="typeHead col-xs-4">
            <h4 id="characterFormHead">Card Type:</h4>
          </div>
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="characterType" style={{ opacity: this.state.characterStyle }} onClick={this.setType} src={character} />
            </a>
          </div>
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="abilityType" style={{ opacity: this.state.abilityStyle }} onClick={this.setType} src={ability} />
            </a>
          </div>
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="strategyType" style={{ opacity: this.state.strategyStyle }} onClick={this.setType} src={strategy} />
            </a>
          </div>
          <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="fieldType" style={{ opacity: this.state.fieldStyle }} onClick={this.setType} src={field} />
            </a>
          </div>
          {/* <div className="col-xs-2 col-md-2">
            <a className="btn formBtn">
              <img id="toolType" style={{ opacity: this.state.toolStyle }} onClick={this.setType} src={tool} />
            </a>
          </div> */}
        </div>
        {
          (this.state.abilityType !== 'Ability' && this.state.abilityType !== '') || this.state.cardType !== 'Ability' && this.state.cardType !== 'Field'
            ? (<div>
              <div className="entranceHead col-xs-12 col-sm-12 col-md-12">
                <h4 id="characterFormHead">Entrance Cost</h4>
              </div>
              <div className="entranceContainer col-xs-12 col-sm-12 col-md-12">
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="zeroType" style={{ opacity: this.state.zeroStyle }} onClick={this.setEntrance} src={zero} />
                  </a>
                </div>
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="oneType" style={{ opacity: this.state.oneStyle }} onClick={this.setEntrance} src={one} />
                  </a>
                </div>
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="twoType" style={{ opacity: this.state.twoStyle }} onClick={this.setEntrance} src={two} />
                  </a>
                </div>
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="threeType" style={{ opacity: this.state.threeStyle }} onClick={this.setEntrance} src={three} />
                  </a>
                </div>
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="fourType" style={{ opacity: this.state.fourStyle }} onClick={this.setEntrance} src={four} />
                  </a>
                </div>
              </div>
              <div className="entranceContainer col-xs-12 col-sm-12 col-md-12">
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="fiveType" style={{ opacity: this.state.fiveStyle }} onClick={this.setEntrance} src={five} />
                  </a>
                </div>
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="sixType" style={{ opacity: this.state.sixStyle }} onClick={this.setEntrance} src={six} />
                  </a>
                </div>
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="sevenType" style={{ opacity: this.state.sevenStyle }} onClick={this.setEntrance} src={seven} />
                  </a>
                </div>
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="eightType" style={{ opacity: this.state.eightStyle }} onClick={this.setEntrance} src={eight} />
                  </a>
                </div>
                <div className="col-xs-5ths col-sm-5ths">
                  <a className="btn formBtn">
                    <img id="nineType" style={{ opacity: this.state.nineStyle }} onClick={this.setEntrance} src={nine} />
                  </a>
                </div>
              </div>

            </div>)
            : <div />
        }
        <div className="row">
        <div className="rarityContainer col-xs-12 col-sm-6 col-md-6">
          <h4 id="characterFormHead">Card Rarity</h4>
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Common" checked={this.state.rarity === 'Common'}
            onChange={this.handleRarity} /> <p>Common</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Uncommon"checked={this.state.rarity === 'Uncommon'}
            onChange={this.handleRarity} />  <p>Uncommon</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Rare" checked={this.state.rarity === 'Rare'}
            onChange={this.handleRarity} />  <p>Rare</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Super Rare" checked={this.state.rarity === 'Super Rare'}
            onChange={this.handleRarity} />  <p>Super Rare</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Promo" checked={this.state.rarity === 'Promo'}
            onChange={this.handleRarity} />  <p>Promo</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Parallel" checked={this.state.rarity === 'Parallel'}
            onChange={this.handleRarity} />  <p>Parallel</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="STSR" checked={this.state.rarity === 'STSR'}
            onChange={this.handleRarity} />  <p>Starter Super Rare</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Starter" checked={this.state.rarity === 'Starter'}
            onChange={this.handleRarity} />  <p>Starter</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Black & White Gold" checked={this.state.rarity === 'Black & White Gold'}
            onChange={this.handleRarity} />  <p>Black & White Gold</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Secret Rare" checked={this.state.rarity === 'Secret Rare'}
            onChange={this.handleRarity} />  <p>Secret Rare</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="rarity" value="Legendary Rare" checked={this.state.rarity === 'Legendary Rare'}
            onChange={this.handleRarity} />  <p>Legendary Rare</p></div><br />
        </div>
        <div className="otherContainer col-xs-12 col-sm-6 col-md-6">
          {
            this.state.cardType === 'Character'
              ? (<div className="row">
                <div className="col-md-6 col-xs-6">
                  <h4 className="combatHead">Attribute:</h4>
                </div>
                <div className="col-md-6 col-xs-6">
                <input id="characterAttribute" className="form-control cardName" placeholder="Type the Character's Attribute" />

                </div>
              </div>)
              : <div />
          }
          <div className="row">
            <div className="col-md-6 col-xs-6">
              <h4 className="cardSetHead">Card Set:</h4>
            </div>
            <div className="col-md-6 col-xs-6">
              <select id="cardSet">
                <option value="Awakened Primordials">Awakened Primordials</option>
                <option value="Battlers of Bethesda">Battlers of Bethesda</option>
                <option value="Sacred Shards">Sacred Shards</option>
                <option value="Set 4">Set 4</option>
                <option value="Promo">Promo</option>
              </select>
            </div>
          </div>
          {
            this.state.cardType === 'Character'
              ? (
                <div>
                <div className="row">
                  <div className="col-xs-6 col-md-6">
                    <h4 className="cardSetHead">Characteristics (SEPARATE WITH COMMAS):</h4>
                  </div>
                  <div className="col-xs-6 col-md-6">
                    <input id="characterCharacteristics" className="form-control cardName" placeholder="Male, Cayne Pirate, Weapon" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-4 col-md-4">
                    <h4 className="cardSetHead">Healthy:</h4>
                  </div>
                  <div className="col-xs-6 col-md-6">
                    <input id="healthyStat" className="form-control cardName" placeholder="0/0" />
                  </div>
                  <div className="col-xs-3 col-md-3">
                    <h4 className="cardSetHead">Injured:</h4>
                  </div>
                  <div className="col-xs-6 col-md-6">
                    <input id="injuredStat" className="form-control cardName" placeholder="0/0" />
                  </div>
                </div></div>
              )
              : <div />
          }
          <div className="row">
            <div className="col-xs-4 col-md-8">
              <h4 className="cardSetHead">Card Number:</h4>
              <div className="col-xs-6 col-md-6">
              <input id="cardNumber" className="form-control cardName" placeholder="1006" />
            </div>
              {this.state.cardType === 'Ability'
                ? (<div className="abilityContainer col-xs-12 col-sm-12 col-md-12">
                <h5 id="characterFormHead">Ability Type</h5>
                <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="abilityType" value="Ability" checked={this.state.abilityType === 'Ability'}
                  onChange={this.handleAbility} /> <p>Regular</p></div><br />
                <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="abilityType" value="Forbidden"checked={this.state.abilityType === 'Forbidden'}
                  onChange={this.handleAbility} />  <p>Forbidden</p></div><br />
              </div>)
                : (<div />)
              }
            </div>
            {
              this.state.cardType !== 'Ability' && this.state.cardType !== 'Field'
                ? (<div className="row">
                  <div className="col-xs-6 col-md-6">
                    <h4 className="cardSetHead">Hand Cost:</h4>
                  </div>
                  <div className="col-xs-3 col-md-3">
                    <input id="handCost" className="form-control cardName" placeholder="1" />
                  </div>
                </div>)
                : (<div />)
            }
            {
              this.state.cardType ==='Ability'
                ? (
                  <div className="col-xs-12">
                    <h4 id="characterFormHead">Ability Cost</h4>
                    <div className="abilityCostContainer col-xs-12">
                      <div className="col-xs-6 col-md-6">
                        <img className="abilitySymbol" src={fire} />
                      </div>
                      <div className="col-xs-3 col-md-3">
                        <input id="fireCost" className="form-control cardName" placeholder="0" />
                      </div>
                    </div>
                    <div className="abilityCostContainer col-xs-12">
                      <div className="col-xs-6 col-md-6">
                        <img className="abilitySymbol" src={water} />
                      </div>
                      <div className="col-xs-3 col-md-3">
                        <input id="waterCost" className="form-control cardName" placeholder="0" />
                      </div>
                    </div>
                    <div className="abilityCostContainer col-xs-12">
                      <div className="col-xs-6 col-md-6">
                        <img className="abilitySymbol" src={terra} />
                      </div>
                      <div className="col-xs-3 col-md-3">
                        <input id="terraCost" className="form-control cardName" placeholder="0" />
                      </div>
                    </div>
                    <div className="abilityCostContainer col-xs-12">
                      <div className="col-xs-6 col-md-6">
                        <img className="abilitySymbol" src={air} />
                      </div>
                      <div className="col-xs-3 col-md-3">
                        <input id="airCost" className="form-control cardName" placeholder="0" />
                      </div>
                    </div>
                    <div className="abilityCostContainer col-xs-12">
                      <div className="col-xs-6 col-md-6">
                        <img className="abilitySymbol" src={plasma} />
                      </div>
                      <div className="col-xs-3 col-md-3">
                        <input id="plasmaCost" className="form-control cardName" placeholder="0" />
                      </div>
                    </div>
                    <div className="abilityCostContainer col-xs-12">
                      <div className="col-xs-6 col-md-6">
                        <img className="abilitySymbol" src={necroSymbol} />
                      </div>
                      <div className="col-xs-3 col-md-3">
                        <input id="necroCost" className="form-control cardName" placeholder="0" />
                      </div>
                    </div>
                    <div className="abilityCostContainer col-xs-12">
                      <div className="col-xs-6 col-md-6">
                        <img className="abilitySymbol" src={colorless} />
                      </div>
                      <div className="col-xs-3 col-md-3">
                        <input id="anyCost" className="form-control cardName" placeholder="0" />
                      </div>
                    </div>

                  </div>
                )
                : <div />
            }
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-12">
          <div className="PostInputs">
            <label>Card Effect:</label>
            <textarea
              id="description"
              className="form-control" rows="6" placeholder="Card Effect"
            />
          </div>
        </div>
        <div className="row">
        <div className="rarityContainer col-xs-12 col-sm-6 col-md-6">
        <h4 id="characterFormHead">APP MODE</h4>
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="env" value="Dev" checked={this.state.env === 'Dev'}
            onChange={this.handleEnv} /> <p>Dev</p></div><br />
          <div className="radioForm col-xs-12 col-sm-12"><input type="radio" name="env" value="Live"checked={this.state.env === 'Live'}
            onChange={this.handleEnv} />  <p>Live</p></div><br />
        </div>
        </div>
        <div className="col-xs-12">
          <a className="btn" onClick={this.handleSubmit} id="submitPost">Post</a>
        </div>
        </div>
      </div>
    );
  }
}
export default NinjaForm;


import React, { Component } from 'react';
import Helmet from 'react-helmet';
import {
  CircularProgress,
  FormControl,
  Input,
  TextField,
  FormHelperText,
  Button,
  InputLabel
} from "@material-ui/core";
import Navbar from "../Navbar/Navbar";
import MapListRow from '../Listing/MapListRow';
import MappedCard from '../Listing/MappedCard';
import Footer from "../Footer/Footer";


export default class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      name: "",
      email: "",
      username: "",
      location: ""
    }
  }
  componentDidMount() {
    const urlSplit = this.props.match.url.split("/");
    // let lastChar = urlSplit[urlSplit.length - 1];
    this.fetchArtists();
  }
  async fetchArtists() {
    try {
      let result = await fetch('https://api.primaltcg.com/api/v1/guest/artists', {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });  
      result = await result.json();
      if (result && result.length > 0) {
        this.setState({ 
          artists: result,
          loading: false
        });
      } else {
        alert('There was a problem getting Primal TCG artists');
        this.props.history.push("/");
      }
    } catch (error) {
      alert('There was a problem getting Primal TCG artists');
      this.props.history.push("/");
    }
  }
  renderTextInput(
    id: string,
    label: any,
    value: string,
    type: string,
    width: any,
    inputProps: Object,
    disabled: boolean = false,
    error: boolean = false
  ): React$Element<FormControl> {
    return (
      <FormControl style={{ width: "50%" }}>
        <TextField
          error={error}
          variant="outlined"
          id={id}
          type={type}
          label={label}
          value={value || ""}
          disabled={disabled}
          InputProps={inputProps}
          autoComplete="nope"
          onChange={(inputValue: Object) => {
            let value = inputValue.target.value;
            if (type === "number") {
              value = Number(value);
            }
            this.updateState(id, value);
          }}
        />
        {id === "location" ? (<FormHelperText id="location-helper-text">Location is used to let other players in your proximity know where you are to know if people around you are playing Primal TCG. It is recommended to place your city.</FormHelperText>): <span />}
      </FormControl>
    );
  }
  updateState(id, value) {
    let email = "";
    if (id === "email") {
      email = String(value).toLowerCase();
      email = email.trim();
      value = email;
    }
    this.setState({ [id]: value });
  }
  async createAccount() {
    const { name, username, email, location } = this.state;
    if (name && username && email && location) {
      try {
        let details = {
          "name": name,
          "location": location,
          "userName": username,
          "email": String(email).toLowerCase(),
          "password": "Primal123"
        };
        let formBody = [];
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        console.log("FORM BODY", {form: formBody, details: details})
        let result = await fetch('https://api.primaltcg.com/api/v1/users/signup', {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Accept':       'application/json'
          },
          body: formBody,
        });
        result = await result.json();
        if (result) {
          this.setState({
            name: "",
            username: "",
            location: "",
            email: ""
          }, () => {
            alert(`Account Created! Login on the app with your email, ${email}`);
          });
        }
      } catch (error) {
        alert("There was an error creating your account. Make sure your email is correct.");
      }
    } else {
      alert("Complete the form.");
    }
  }
  render() {
    const { artists, loading} = this.state;
    /**
     * Name
     * email 
     * username
     * location
     */
    return (
      <div className="Artist">
        <Helmet>
          <title>Primal Trading Card Game App Sign Up</title>
          <meta name="description" content="Mobile App Sign Up for Primal the Trading Card Game. A fantasy based game with legacy game mechanics compared to modern trading card games. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:title" content="Primal Trading Card Game (TCG) App Sign Up" />
          <meta property="og:description" content="Mobile App Sign Up for Primal the Trading Card Game. A fantasy based game with legacy game mechanics compared to modern trading card games. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:image" content="https://pbs.twimg.com/media/GJ38D7mWwAAMXsa?format=jpg&name=medium" />
        </Helmet>
        <Navbar />
        <div className={loading ? "row bkg-red full-h" : "row bkg-red"}>
        <div className="col-xs-12 col-sm-8 col-md-8 col-xl-8 offset-sm-2 offset-md-2 offset-xl-2 floating-section">
          <h1>Create App Account</h1>
          <h4>If you tried to sign up through the app and it didn't work please try to create an account here and then login on the app with the email for your account.</h4>
          {loading ?  <CircularProgress /> : <div />}

          <div className="row mb-2vh centered-content mt-2vh">
            <div className="col-xs-12 col-md-12 col-xl-12">
              {this.renderTextInput(
                "name",
                "Name",
                this.state.name,
                "text"
              )}
            </div>
          </div>
          <div className="row mb-2vh centered-content">
            <div className="col-xs-12 col-md-12 col-xl-12">
              {this.renderTextInput(
                "email",
                "Email",
                this.state.email,
                "text"
              )}
            </div>
          </div>
          <div className="row mb-2vh centered-content">
            <div className="col-xs-12 col-md-12 col-xl-12">
              {this.renderTextInput(
                "username",
                "Username",
                this.state.username,
                "text"
              )}
            </div>
          </div>
          <div className="row mb-2vh centered-content">
            <div className="col-xs-12 col-md-12 col-xl-12">
              {this.renderTextInput(
                "location",
                "Location (City)",
                this.state.location,
                "text"
              )}
            </div>
          </div>
          <div style={{ display:"flex", justifyContent: "center" }} className="row mb-2vh">
          <Button
            style={{ width: "50%" }}
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              this.createAccount();
            }}
          >
            Create Account
          </Button>
          </div>
        </div>
      </div>
      <Footer />
      </div>
    );
  }
}
import React, { Component } from "react";
import footer from "../assets/primal/front/PrimalFooter.png";

export default class Banner extends Component {
  render() {
    return(
      <footer style={{ backgroundColor: "#9D0208" }} className="footer footer-big">
    
              <div className="container">
                <div className="row">
                  <div className="col-md-4 col-sm-4 ml-auto mr-auto">
                    <div className="logo text-center">
                      {/* <h3>Dattebayo Labs, LLC</h3> */}
                      <img alt="Primal Trading Card Game Logo" src={footer} style={{ width: "100%"}} />
                    </div>
                  </div>
                  <div className="col-md-6 offset-md-2 col-sm-8">
                    <div className="links">
    
                      <hr />
                      <div className="copyright">
                        <div className="pull-left">
                            © {new Date().getFullYear()}, made with <i className="fa fa-heart heart"></i> by Dattebayo Labs LLC in Miami,FL
                        </div>
                        <div className="pull-right">
                          <ul>
                            <li>
                              <a href="#">
                                        Terms
                              </a>
                            </li>
                                |
                            <li>
                              <a href="#">
                                        Privacy
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    
              </div>
            </footer>
    )
  }
}
